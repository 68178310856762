import React from 'react';

const ClipIconProgreso = () => {
    return ( 
        <svg width="36" height="27" viewBox="0 0 36 27">
            <defs>
                <clipPath id="clip-icon-progreso">
                    <rect width="36" height="27"/>
                </clipPath>
            </defs>
            <g id="icon-progreso" clipPath="url(#clip-icon-progreso)">
                <rect width="36" height="27" fill="#fff"/>
                <path id="ic_timeline_24px" d="M33.545,8.959a2.967,2.967,0,0,1-2.959,2.959,2.514,2.514,0,0,1-.754-.1l-5.266,5.252a2.613,2.613,0,0,1,.1.769,2.959,2.959,0,0,1-5.917,0,2.613,2.613,0,0,1,.1-.769l-3.772-3.772a2.909,2.909,0,0,1-1.538,0L6.814,20.039a2.514,2.514,0,0,1,.1.754,2.959,2.959,0,1,1-2.959-2.959,2.514,2.514,0,0,1,.754.1l6.746-6.731a2.613,2.613,0,0,1-.1-.769,2.959,2.959,0,0,1,5.917,0,2.613,2.613,0,0,1-.1.769l3.772,3.772a2.909,2.909,0,0,1,1.538,0l5.252-5.266a2.514,2.514,0,0,1-.1-.754,2.959,2.959,0,0,1,5.917,0Z" transform="translate(1 -1)"/>
            </g>
        </svg>
    );
}
 
export default ClipIconProgreso;