//Imagen grande
import jaguar from '../../../../assets/images/historias-para-dormir/jaguar.svg';
//Imagen miniaturas
import recurso_jaguar from '../../../../assets/images/historias-para-dormir/historia-jaguar.svg';
import recurso_caballero from '../../../../assets/images/historias-para-dormir/historia-caballero.svg';
import recurso_payaso from '../../../../assets/images/historias-para-dormir/historia-payaso.svg';

const dummyHistorias = [
  {
     titulo : 'Las manchas del Jaguar',
     resumen: 'Cuenta una antigua leyenda qué hace miles de años, cuando todavía no existía el ser humano, hubo un jaguar al que sucedió algo muy especial.',
     imagen: recurso_jaguar,
     bigImg: jaguar,
     estaCompletada : true,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/KuOoIhthItk',
     videoId: 'KuOoIhthItk',
     urlname: 'Las-manchas-del-Jaguar'
  },
  {
     titulo : 'El Caballero del castillo',
     resumen: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris urna eros, porttitor in diam sollicitudin, sollicitudin fermentum erat.',
     imagen: recurso_caballero,
     bigImg: recurso_caballero,
     estaCompletada : true,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/vit1BR7RDQM',
     videoId: 'vit1BR7RDQM',
     urlname: 'caballero'
  },
  {
     titulo : 'El guasón',
     resumen: 'Vestibulum egestas lorem at arcu interdum cursus curabitur non sapien nequ unc nisi nisl, pellentesque sed aliquet quis, ultricies at ipsum.',
     imagen: recurso_payaso,
     bigImg: recurso_payaso,
     estaCompletada : false,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/YpU7YVqeEbQ',
     videoId: 'YpU7YVqeEbQ',
     urlname: 'payaso'
  },
  {
     titulo : 'Payaso',
     resumen: 'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc ut elementum purus. In interdum hendrerit.',
     imagen: recurso_payaso,
     bigImg: recurso_payaso,
     estaCompletada : false,
     estaBloqueada : true,
     urlVideo: 'https://www.youtube.com/embed/YpU7YVqeEbQ',
     videoId: 'YpU7YVqeEbQ',
     urlname: 'payaso'
  }
]

export default dummyHistorias;