import React, { Component } from "react";
import { Link } from 'react-router-dom';
// Components
import Button from "../../components/button/Button";
import { InfoRecurso } from "../../components/actividadRecurso/InfoRecurso";

// Images
import IconCheckDone from "../../assets/images/check-done.svg";
// Icons
import { FaPen } from "react-icons/fa";
// Services
import ResourceServices from "../../../services/resources";
import AssignmentServices from "../../../services/assignment";
import authentication from "../../../services/authentication";
import patientsAPI from "../../../services/patients";
import patientServices from "../../../services/patients";

import "./styles.css";

import { format } from "date-fns";

export default class AsignarActividades extends Component {
   constructor(props) {
      super(props);
      this.state = {
         recursoActivo: null,
         loading: true,
         show: false,
         filtered: [],
         searchText: "",
         recursos: [],
         optionsCategory: [],
      };

      this.timebuscar = null;
      this.categoriasRepetidas = 0;
   }

   componentDidMount() {
      // FAQUE LOADING OLD SCOOL

      // const script = document.createElement("script");
      // script.src = 'http://localhost:3000/assets/asignarActividades.js';
      // script.async = true;
      // document.body.appendChild(script);

      //FAQUE LOADING con JQUEY

      // setTimeout(function () {
      //    $('#loading').delay(0).fadeOut('slow');
      //    $(".loading-content").css("visibility", "visible");
      //    $(".loading-content").css("display", "none");
      //    $(".loading-content").delay(800).fadeIn(600);
      // }, 2000);

      //FAQUE LOADING BIEN
      // setTimeout( () => {
      //    this.setState( { loading: false } );
      // }, 2000);

      //LOADING REAL

      // patientServices.getPatient()
      //   .then( async(data)=>{
      //     console.log('res', data)
      //   });

      ResourceServices.getResources()
         .then(async (res) => {
            // console.log('res', res.data);
            let recursoActivo = null;
            if (res.data[0] && res.data[0]._id) {
               recursoActivo = res.data[0]._id;
            }

            let arrayCategory = [];
            let dataCategory = res.data;
            dataCategory.map(({ category }, i) => {
               category.map(({ nameCategory }, i2) => {
                  arrayCategory.indexOf(nameCategory) === -1
                     ? arrayCategory.push(nameCategory)
                     : this.categoriasRepetidas++;
               });
            });

            await this.setState({
               loading: false,
               recursos: res.data,
               recursoActivo: recursoActivo,
               optionsCategory: ["Todos", ...arrayCategory],
            });
         })
         .catch((err) => {
            this.setState({ recursos: [], arrayCategory: ["Todos"] });
         });
   }

   verRecurso = (recursoId) => (e) => {
      e.preventDefault();
      this.setState({ recursoActivo: recursoId });
   };

   getContent = () => {
      const recurso = this.state.recursos.find(
         (r) => r._id == this.state.recursoActivo
      );
      //  console.log(this.state.recursoActivo);
      // console.log(recurso);
      if (recurso) {
         return (
            <InfoRecurso
               {...recurso}
               key={recurso._id}
               button={this.state.show}
               show={this.state.show}
               patientId={this.props.idPaciente}
               medicUser={this.props.idMedico}
            />
         );
      } else {
         return null;
      }
   };

   onClickButton = (data) => {
      // console.log(data);
      const recurso = this.state.recursos.find(
         (r) => r._id == this.state.recursoActivo
      );

      console.log(recurso);
      //TODO: obtener id del paciente correcto
      const patientId = this.props.idPaciente;

      var notificationData = {
         medicUser: this.props.idMedico, //TODO: obtener id del medico logueado
         resource: recurso._id,
         notificationCategory: recurso.category,
         message: data.message,
         // "urlname": "/video/uihg1i23",
         startOfPeriod: format(data.startDate, "yyyy-MM-dd"),
         endOfPeriod: format(data.endDate, "yyyy-MM-dd"),
         timesInTheDay: data.timesInTheDay,
      };

      //console.log('notificationData :', notificationData);
      patientsAPI.addResources(patientId, notificationData);
   };

   handlerBuscarRecurso = (ev) => {
      // ev.preventDefault();
      const inputVal = ev.target.value;
      clearTimeout(this.timebuscar);
      this.timebuscar = setTimeout(() => this.buscarRecurso(inputVal), 500);
   };

   // Busca Recurso Search
   buscarRecurso = (inputValue) => {
      if (!inputValue || !inputValue.trim()) {
         return this.setState({ filtered: [], searchText: "" });
      }

      const filtered = this.state.recursos.filter((r) => {
         // console.log(r)
         try {
            let regx = new RegExp(inputValue, "im");
            // console.log(regx)
            if (regx.test(r.name)) {
               return true;
            }
            if (regx.test(r.longDescription)) {
               return true;
            }
            if (regx.test(r.shortDescription)) {
               return true;
            }
         } catch (error) {
            return false;
         }
      });
      this.setState({ filtered: filtered, searchText: inputValue });
   };

   handelCategoryFilter = (event) => {
      event.preventDefault();

      let categoria = event.target.value;
      if (categoria === "Todos") {
         return this.setState({ filtered: [], searchText: "" });
      }

      let strCategoria = [];
      const filtered = this.state.recursos.filter(({ category }, i) => {
         let cantidadCategorias = category.length - 1;
         // recorrer toddos los recursos que tienen mas de una categoria
         if (cantidadCategorias > 1) {
            for (const cantidadCategorias of category) {
               strCategoria.push(cantidadCategorias.nameCategory);
            }
         } else {
            strCategoria.push(category[0].nameCategory);
         }

         // retorno todas las categorias y la comparo con la seleccionada
         return strCategoria[i] === categoria;
      });

      this.setState({ filtered: filtered, searchText: categoria });
   };

   toggleShow = () =>
      this.setState((currentState) => ({ show: !currentState.show }));

   render() {
      const filterRecursos = this.state.searchText
         ? this.state.filtered
         : this.state.recursos;

      return (
         <div className="row mt-4">
            <div className="col-md-12">
               <div className="centro-recursos little-shadow mb-4">
                  <div className="centro-recursos-header pt-2 pb-2">
                     <div className="col-12">
                        <div className="cardesign-title">
                           <h3>Centro de recursos</h3>
                        </div>
                     </div>
                     <div className="col-12">
                        <div className="d-flex topfilters">
                           <input
                              type="text"
                              name="buscarRecursos"
                              onChange={this.handlerBuscarRecurso}
                              className="search-btn btn-radius btn-height w-50 mr-2"
                              placeholder="Buscar recursos..."
                           />

                           <p className="small pt-2 pl-1 pr-2">Tipo:</p>
                           <select onChange={this.handelCategoryFilter}>
                              {this.state.optionsCategory.map((cat, idx) => {
                                 return (
                                    <option key={idx} value={cat}>
                                       {cat}
                                    </option>
                                 );
                              })}
                           </select>
                        </div>
                     </div>
                  </div>

                  <div className="row pb-3 ">
                     <div className="col-md-12 recursos_main-content">
                        <aside className="recurso_sidebar little-shadow-sm pl-0 pr-1">
                           <button
                              onClick={this.toggleShow}
                              name="recurso_per"
                              disabled={this.state.show === true}
                              className={
                                 this.state.show === true
                                    ? "bg-tele mb-1 active rounded ml-1 text-center little-shadow-sm animated fadeIn"
                                    : "btn-secondary mb-1 rounded ml-1 text-center little-shadow-sm animated fadeout"
                              }
                           >
                              <FaPen /> {"  "}
                              Recurso Personalizado
                           </button>

                           <button 
                              onClick={this.toggleShow }
                              name="recursos"
                              disabled={this.state.show === false}
                              className={
                                          this.state.show === false
                                    ? "mt-1 mb-1 ml-1 rounded titulo-recursos text-ceter text-light  bg-tele pl-3 rounded-right little-shadow-sm"
                                    : "btn-secondary activate mt-1 mb-1 ml-1 rounded titulo-recursos text-ceter text-mutted bg-tele pl-3 rounded-right little-shadow-sm"
                                       }
                                            
                                            >
                              Recursos
                           </button>
                           <div className="recursos">
                              {
                                 filterRecursos.length === 0 ? (
                                 <p className="alert alert-warning">
                                    No hay Resultados
                                 </p>
                              ) : (
                                 filterRecursos.map((recurso) => (
                                    <div
                                       className={`recurso_entrada pointer little-shadow `}
                                       key={recurso._id}
                                    >
                                       <div className="recurso-body">
                                          <Button
                                             key={recurso._id}
                                             name={recurso.name}
                                             /*icon={
                                                recurso.active
                                                   ? IconCheckDone
                                                   : null
                                             }*/
                                             onClick={this.verRecurso(
                                                recurso._id
                                             )}
                                             active={
                                                this.state.recursoActivo === recurso._id
                                             }
                                             show={this.state.show}
                                          />
                                       </div>
                                    </div>
                                 ))
                              )}
                           </div>
                        </aside>

                        <main className="w-100">
                           {filterRecursos.length === 0 ? (
                              <div className="d-flex align-content-center">
                                 <div className="text-black-50 d-flex align-items-center">
                                    {" "}
                                    No hay resultados
                                 </div>
                              </div>
                           ) : (
                              <div className="item-detail">
                                 <div
                                    id="loading"
                                    className={
                                       this.state.loading ? "fadeIn" : "fadeOut"
                                    }
                                 >
                                    <div className="col-md-12 text-center">
                                       <div className="loading-spinner">
                                          <div />
                                          <div />
                                          <div />
                                          <div />
                                       </div>
                                    </div>
                                 </div>

                                 <div
                                    className="loading-content"
                                    className={
                                       this.state.loading ? "fadeOut" : "fadeIn"
                                    }
                                 >
                                    {this.getContent()}
                                 </div>
                              </div>
                           )}
                        </main>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
