import React from "react";
import imgIntroduccion from "../../assets/images/introduccion/introduccionMovil3.svg";

const IntroSorpresas = ({ setStep }) => {
   const handleContinue = () => {
      setStep(4);
   };

   return (
      <div className="content-container text-center animated fadeIn slow">
         <div className="middle-center-content">
            <div className="pb-3">
               <img
                  src={imgIntroduccion}
                  alt="Esta es la app que te acompañará en tu día a día para mejorar tu bienestar"
                  className="img-fluid height40 animated pulse infinite slow"
               />
            </div>

            <div className="heading text-center">
               <h1 className="paged-title">Acompañamiento en tu día a día</h1>
               <p>
                  Estamos preparando contenido especial para ayudarte a mejorar
                  tu bienestar.
               </p>
            </div>
            <input
               type="submit"
               className="animated pulse infinite slow"
               value="CONTINUAR"
               onClick={handleContinue}
            />
         </div>
      </div>
   );
};

export default IntroSorpresas;
