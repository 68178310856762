// Opciones de Selectores
export const optionsType = [
    { value: "", label: "Seleccione", icon: "" },
    { value: "audio", label: "Audio", icon: "" },
    { value: "video", label: "Video", icon: "" },
    { value: "lectura", label: "Lectura", icon: "" },
    { value: "enlace-externo", label: "Enlace Externo", icon: "" },
];

export const optionsPeriodicity = [
    { value: "", label: "Seleccione" },
    { value: "diaria", label: "Diaria" },
    { value: "semanal", label: "Semanal" },
];