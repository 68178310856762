import React, { useState } from "react";
import IntroBienvenido from "./IntroBienvenido";
import IntroVideoAtencion from "./IntroVideoAtencion";
import IntroSorpresas from "./IntroSorpresas";
import IntroComencemos from "./IntroComencemos";
import imgLogoRedgesam from "../../assets/images/redgesam-logo.png";

function Registro() {
   const [step, _setStep] = useState(1);

   const setStep = (step) => {
      if (step === 6) {
      } else {
         _setStep(step);
      }
   };

   return (
      <>
         <div className="mobileAppIntroduction pt-3">
            <div className="container">
               <div className="row">
                  <div className="col-12 text-center">
                     <img
                        src={imgLogoRedgesam}
                        width="34%"
                        className="img-fluid"
                        alt="Redgesam Logo"
                     />
                  </div>
               </div>
            </div>
            {step === 1 ? <IntroBienvenido setStep={setStep} /> : null}

            {step === 2 ? <IntroVideoAtencion setStep={setStep} /> : null}

            {step === 3 ? <IntroSorpresas setStep={setStep} /> : null}

            {step === 4 ? <IntroComencemos /> : null}
         </div>
      </>
   );
}

export default Registro;
