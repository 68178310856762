import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import imgEsperandoAtencion from "../../assets/images/introduccion/esperandoAtencion.svg";
import imgLogoRedgesam from "../../assets/images/redgesam-logo.png";
import imgIconWifi from "../../assets/images/introduccion/iconWifi.svg";
import imgIconBateria from "../../assets/images/introduccion/iconBateria.svg";
import imgIconAudio from "../../assets/images/introduccion/iconAudio.svg";
import imgIconAudifono from "../../assets/images/introduccion/iconAudifono.svg";
import imgIconDispositivos from "../../assets/images/introduccion/iconDispositivos.svg";
import imgIconAlerta from "../../assets/images/introduccion/iconAlerta.svg";
import imgIconNoGrabacion from "../../assets/images/introduccion/iconNoGrabacion.svg";
import imgIconPrivacidad from "../../assets/images/introduccion/iconPrivacidad.svg";
import imgIconSoporte from "../../assets/images/introduccion/iconSoporte.svg";
import imgIconAyuda from "../../assets/images/introduccion/iconAyuda.svg";

import videoLoading from "../../../teleconsulta/assets/video/loading.mp4";

var timeOut;

const SalaEspera = ({ handleSalirEspera }) => {
   const [waiting, setWaiting] = useState(true);

   const handleCancelarEspera = (e) => {
      e.preventDefault();
      clearTimeout(timeOut);
      handleSalirEspera();
   };

   return (
      <div className="hoy-component fullwidth middday-bg animated fadeIn slow">
         <div className="header-redgesam col-12">
            <div className="row pb-2">
               <div className="col-12 logo-corporativo text-center">
                  <img
                     src={imgLogoRedgesam}
                     width="60%"
                     className="img-fluid"
                     alt="Redgesam Logo"
                  />
               </div>
            </div>
         </div>
         <div className="home-waiting text-center">
            <h1>...esperando al profesional</h1>
            <div className="">
               {/* <div className="sk-circle">
                  <div className="sk-circle1 sk-child"></div>
                  <div className="sk-circle2 sk-child"></div>
                  <div className="sk-circle3 sk-child"></div>
                  <div className="sk-circle4 sk-child"></div>
                  <div className="sk-circle5 sk-child"></div>
                  <div className="sk-circle6 sk-child"></div>
                  <div className="sk-circle7 sk-child"></div>
                  <div className="sk-circle8 sk-child"></div>
                  <div className="sk-circle9 sk-child"></div>
                  <div className="sk-circle10 sk-child"></div>
                  <div className="sk-circle11 sk-child"></div>
                  <div className="sk-circle12 sk-child"></div>
               </div> */}
               <video
                  className="pt-2"
                  width="80"
                  height="80"
                  autoPlay
                  loop
                  muted
                  playsInline
               >
                  <source src={videoLoading} type="video/mp4" />
                  <source src={videoLoading} type="video/ogg" />
               </video>
            </div>
            <span className="mb-0">
               Si tienes una cita, espera aquí al profesional. Cuando él este
               listo te aparecerá un mensaje aquí en pantalla para ingresar.
            </span>
            <span className="info-warning">
               Ten paciencia el profesional puede demorarse unos minutos debido
               a la gran demanda de teleconsultas.{" "}
               <strong>Es necesario que tengas esta página abierta.</strong>
            </span>
            <div className="button-enter p-3">
               <button className="btn-submit" onClick={handleCancelarEspera}>
                  Salir de la sala
               </button>
            </div>
         </div>

         <div className="homeRecomendations pl-3 pr-3 pb-3">
            <div className="row">
               <div className="col-12 mb-2 text-center">
                  <p className="mb-4">Recomendaciones</p>
               </div>
               <div className="col-12 mb-0 homeRecomendationSlider">
                  <Carousel
                     showIndicators={false}
                     showArrows={false}
                     showStatus={false}
                     showThumbs={false}
                     autoPlay={true}
                     interval={7000}
                     infiniteLoop
                     emulateTouch
                  >
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconWifi} alt="Wifi" />
                        </span>
                        <span>
                           Conéctate a una <strong>red WIFI</strong> con esto
                           mejorarás la calidad de la conexión.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconBateria} alt="Batería" />
                        </span>
                        <span>
                           Tu dispositivo debe tener un mínimo de un{" "}
                           <strong>30% de batería</strong>.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconAudio} alt="Audio" />
                        </span>
                        <span>
                           Ubícate en un lugar <strong>tranquilo</strong> para
                           evitar ruidos externos.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconAudifono} alt="Audifono" />
                        </span>
                        <span>
                           Te recomendamos que utilices audífonos y micrófono.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img
                              src={imgIconDispositivos}
                              alt="Dispositivos actualizados"
                           />
                        </span>
                        <span>
                           Por motivos de seguridad manten tu dispositivo
                           actualizado.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconAlerta} alt="Aviso de privacidad" />
                        </span>
                        <span>
                           Cuidado con quien te envía una invitación por otros
                           medios. Utiliza esta aplicación como medio formal.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img
                              src={imgIconNoGrabacion}
                              alt="No grabamos las teleconsultas"
                           />
                        </span>
                        <span>
                           Para tu tranquilidad las Teleconsultas son privadas y
                           no son grabadas.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img
                              src={imgIconPrivacidad}
                              alt="Aviso de privacidad"
                           />
                        </span>
                        <span>
                           Nunca te solicitaremos tu usuario o contraseña.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconSoporte} alt="Soporte" />
                        </span>
                        <span>
                           Identifica el canal de comunicación oficial con el
                           área de soporte para la resolución de problemas.
                        </span>
                     </div>
                     <div className="homeRecomendationItem">
                        <span className="recomendationIcon">
                           <img src={imgIconAyuda} alt="Ayuda" />
                        </span>
                        <span>
                           Si tienes problemas para acceder no te de desesperes,
                           avísanos para ayudarte.
                        </span>
                     </div>
                  </Carousel>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SalaEspera;
