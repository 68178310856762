import React from "react";
import { useRouteMatch, Link } from "react-router-dom";

import ClipIconHoy from "../../components/icons/ClipIconHoy";
import ClipIconProgreso from "../../components/icons/ClipIconProgreso";
import ClipIconRecursos from "../../components/icons/ClipIconRecursos";
//import ClipIconAlertas from "../../components/icons/ClipIconAlertas";

function ItemNavCuenta({ label, to, Icon, activeOnlyWhenExact, forceActive }) {
   let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact,
   });

   if (forceActive) {
      match = forceActive === to;
   }

   return (
      <div className="navitem col">
         <Link to={to}>
            <button className={match ? "active" : ""}>
               <Icon />
               <span>{label}</span>
            </button>
         </Link>
      </div>
   );
}

const NavBar = ({ forceActive }) => {
   return (
      <div className="mainnav row">
         <ItemNavCuenta
            label="ACTIVIDADES"
            Icon={ClipIconHoy}
            to="/movil/cuenta/actividades"
            activeOnlyWhenExact={true}
            forceActive={forceActive}
         />

         <ItemNavCuenta
            label="TELECONSULTA"
            Icon={ClipIconProgreso}
            to="/movil/cuenta"
            activeOnlyWhenExact={true}
            forceActive={forceActive}
         />

         <ItemNavCuenta
            label="RECURSOS"
            Icon={ClipIconRecursos}
            to="/movil/cuenta/recursos"
            forceActive={forceActive}
         />
      </div>
   );
};

export default NavBar;
