import React, { useState, useEffect } from "react";
import audioOn from "../assets/images/micOn.svg";
import audioOff from "../assets/images/micOff.svg";

const AudioControl = ({ handleAudio, prevRemoteCount }) => {
   const [enable, setEnable] = useState(true);

   useEffect(() => {
      setEnable(true);
   }, [prevRemoteCount]);

   const handleSwitch = () => {
      setEnable(!enable);
      handleAudio();
   };

   if (enable) {
      return (
         <button onClick={handleSwitch}>
            <img src={audioOn} width="40" />
         </button>
      );
   } else {
      return (
         <button onClick={handleSwitch}>
            <img src={audioOff} width="40" />
         </button>
      );
   }
};

export default AudioControl;
