import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import EncuestaEnd from "./encuesta/EncuestaEnd";
import EncuestaStart from "./encuesta/EncuestaStart";
import EncuestaFuncional from "./encuesta/EncuestaFuncional";
import PollServices from "./../../services/polls";
import Loading from "./../../components/Loading";

const Encuesta = ({ patientId, handleAvaiblePoll }) => {
   const [step, setStep] = useState(0);
   const [answered, setAnswered] = useState(false);
   const [pollData, setPollData] = useState();
   const [pollLoaded, setPollLoaded] = useState(false);

   useEffect(() => {
      if (answered) {
         handleAvaiblePoll(false);
      }
   }, [answered]);

   useEffect(() => {
      if (!patientId) {
         return;
      }

      let dataUser = {
         patientId: patientId,
      };

      PollServices.getPoll(dataUser)
         .then((res) => {
            if (res.data == null) {
               //null = paciente no tiene que responder
               setAnswered(true);
            } else {
               setPollData(res.data);
               setStep(2);
            }
         })
         .catch((error) => {
            setAnswered(true);
         });
   }, [pollLoaded, patientId]);

   const handleStep = (step) => {
      setStep(step);
   };

   useEffect(() => {}, [step]);

   return (
      <>
         {step === 0 ? <Loading /> : null}

         {step === 1 ? (
            <EncuestaStart handleStep={handleStep} setAnswered={setAnswered} />
         ) : null}
         {step === 2 ? (
            <EncuestaFuncional
               patientId={patientId}
               pollData={pollData}
               handleStep={handleStep}
               setAnswered={setAnswered}
            />
         ) : null}
         {step === 3 ? (
            <EncuestaEnd handleStep={handleStep} setAnswered={setAnswered} />
         ) : null}
      </>
   );
};

Encuesta.propTypes = {
   nameMedic: PropTypes.string,
   rutMedic: PropTypes.string,
   namePatient: PropTypes.string,
   rutPatient: PropTypes.string,
   callDuration: PropTypes.string,
   onSubmit: PropTypes.func.isRequired,
};

Encuesta.defaultProps = {
   nameMedic: "",
   rutMedic: "",
   namePatient: "",
   rutPatient: "",
   callDuration: "",
   propRating: 0,
   onSubmit: () => {},
};

export default Encuesta;
