import React, { useEffect, useState } from "react";
//import {Link} from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";

//import home from '../../assets/images/avatars/avatar1/home-midday.svg';
//import home_bg from '../../assets/images/iconos/home-bg.svg';
import imgEsperandoAtencion from "../../assets/images/introduccion/esperandoAtencion.svg";
import imgLogoRedgesam from "../../assets/images/redgesam-logo.png";
import imgIconCerrarSesion from "../../assets/images/iconos/iconCerrarSesion.svg";
import imgIconCuenta from "../../assets/images/iconos/iconMiCuenta.svg";
import imgIconNext from "../../assets/images/iconos/icon-nextButton.svg";
import imgIconNextWhite from "./../../assets/images/iconos/icono-white-next.svg";
// import dummyRecordatorios from "../cuenta/dummyRecordatorios";
//import ProgresoHoy from '../../components/ProgresoHoy';
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import patientApi from "../../../services/patients";
import authentication from "../../../services/authentication";
import api, { setNewToken } from "../../../services/api";
import SalaEspera from "./SalaEspera";
//import SubsButton from '../../components/SubscriptionNotifications';

const HomeTemporal = ({ patient, handleEnEspera, handleLogout }) => {
   return (
      <div className="hoy-component fullwidth middday-bg animated fadeIn slow">
         <div className="header-redgesam col-12">
            <div className="row pb-2">
               <div className="col-6 logo-corporativo">
                  <img
                     src={imgLogoRedgesam}
                     width="60%"
                     className="img-fluid"
                     alt="Redgesam Logo"
                  />
               </div>
               <div className="col-6 text-right pacienteCuenta">
                  <div className="btn-group">
                     <button
                        className="btn btn-secondary dropdown-toggle dropdown-micuenta"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <img src={imgIconCuenta} alt="Mi Cuenta" width="20" />{" "}
                        Mi cuenta
                     </button>
                     <div className="dropdown-menu">
                        <h6 className="dropdown-header">
                           Información del usuario
                        </h6>
                        <span className="dropdown-item">
                           {patient.firstname} {patient.lastname}
                        </span>
                        <span className="dropdown-item">{patient.email}</span>
                        <div className="dropdown-divider"></div>
                        <a
                           onClick={handleLogout}
                           className="dropdown-item cerrarSesion"
                           href="#"
                        >
                           <img
                              src={imgIconCerrarSesion}
                              width="18"
                              alt="Cerrar Sesión"
                           />{" "}
                           Cerrar Sesión
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="enter-wait-room">
            <div className="home-waiting">
               <div className="pt-2 pb-5">
                  <h1>Bienvenido(a)</h1>
                  <h1 className="primary-color">
                     <strong>
                        {patient.firstname} {patient.lastname}
                     </strong>
                  </h1>
               </div>
               <div className="button-enter pb-3">
                  <span>¿Tienes una teleconsulta el día de hoy?</span>
                  <button className="btn-submit" onClick={handleEnEspera}>
                     Ingresa a la <strong>sala de espera</strong>{" "}
                     <img
                        className="pl-2"
                        width="22"
                        src={imgIconNextWhite}
                        alt="Ingresar"
                     />
                  </button>
               </div>
            </div>
         </div>

         <div className="homeRecomendations pl-3 pr-3 pb-3">
            <div className="row">
               <div className="col-12 text-center">
                  <p>Te puede interesar además revisar:</p>
               </div>
               <div className="col-12 mb-3">
                  <a
                     className="homeAdditionalButton"
                     href="/movil/cuenta/guia-videoatencion"
                  >
                     <span className="titleButton">
                        Guía para recibir<strong>Teleconsulta</strong>
                     </span>
                     <span className="goButton">
                        ver más{" "}
                        <img src={imgIconNext} alt="Ver más" width="18" />
                     </span>
                  </a>
               </div>
            </div>
         </div>

         {/* 

       Benjamín:

       El HTML de aquí deberá estar en la misma línea que el menú que abre el popup pero solo deberá ser visible al momento en que uno está en el "HOY"

       <div className="col-12 p-0">
           <span style={{position: 'absolute', right: 0}} className="fecha-actual">{now.toLocaleDateString('es-CL', options)}</span>
       </div>

     */}

         {/* 
         <SubsButton ubicacion={'Inicio'} />
       */}

         {/* 
       <div className="col-12 pb-5">
         <ProgresoHoy
           totalActividades={notifications.length}
           totalCumplidas={notifications.reduce((total, noti) => (noti.tareaRealizada ? ++total : total), 0)}
         />
       </div>
     
       <div className="col-12 pb-5">
         {notifications.map((recordatorio, indice) => {
           return (
             <ItemListaActividad
               key={indice}
               recordatorio={recordatorio}
               categoria={recordatorio.categoria}
               tareaRealizada={recordatorio.tareaRealizada}
               urlname={recordatorio.urlname}
               patientId={patientId}
               onChangeNotification={onChangeNotification}
             />
           );
         })}

         {notifications.length === 0 && (
           <div className="square-message square-message-default animated fadeIn fast delay-1s">
             <span>
               No tienes actividades pendientes.<br />Puedes aprovechar de explorar{' '}
               <strong>recursos</strong>.
             </span>
           </div>
         )}
       </div>
       <div className="col-12 pb-4" />
     */}
      </div>
   );
};

export default HomeTemporal;
