import React, { useState, useEffect } from 'react';
import moment from "moment-timezone";
import CreacionCuentaCompleta from '../../../mobil/pages/registro/CreacionCuentaCompleta';
// Services
import AssignmentServices from "../../../services/assignment";
export const Seguimiento = ({
    arrayDias,
    diaInicial,
    diaFinal,
    assignServices,
    chartContainer,
    chartRadarContainer,
    recurso,
    idPaciente
}) => {

    const [dayState, setDayState] = useState([]);
    const [dayArrState, setArrDayState] = useState(arrayDias);
    const [dayIni, setDayIni] = useState(diaInicial);
    const [dayFin, setDayFin] = useState(diaFinal);
    const [totalRealizadas, setTotalReazlizadas] = useState(null);
    const [totalPorRealizar, setTotalPOreazlizar] = useState(null);
    const [total, setTotales] = useState(null);
    const [recursoState, setRecursoState] = useState(recurso);

 
    useEffect(() => {

        let arrayCategory = [];
        let contCat = [];
        let cont = 0;
        let categoriasRepetidas = 0;

        recursoState.map(({ category }) => {
            category.map(({ nameCategory }, i2) => {

                if (arrayCategory.indexOf(nameCategory) === -1) {
                    cont++;
                    arrayCategory.push(nameCategory);
                    contCat.push(cont);
                } else {

                    cont++;
                    categoriasRepetidas++;
                }
            });
        })
        console.log(arrayCategory);
        console.log(contCat);


        // })
        // Rangos de Fechas para labels
        // Obtener labels de Fechas
        let arrayDaysAux = [];
        let arrayDaysLabel = [];
        let daysLabels = [];
        let dayIniFormated = moment(dayIni).format("DD-MM-YYYY");

        const currentMonthDates = new Array(moment().daysInMonth())
            .fill()
            .map((x, i) =>
                moment(dayIniFormated).startOf().add(i, 'days')
            );
        // inserto fechas
          console.log(currentMonthDates);
        arrayDaysLabel.push(...currentMonthDates);

        arrayDaysLabel.forEach(({ _d }, i) => {
            // Solo almaceno 15 dias
            if (i <= 14) {
               arrayDaysAux.push(moment(_d).format("DD-MM-YYYY"));
               daysLabels.push(moment(_d).format("MMM D"))
            }
        });

        let arrayCompletadas = [];
        let arrayTotales = [];
        let acumRealizadas= -1;


        arrayDaysAux.forEach((day) => {
            
            let totalCompletadas = 0;
            let totales = 0;
            

            arrayDias.forEach((actividad) => {
               let fechaActividad = moment(actividad.dateTime).format("DD-MM-YYYY");
               
               if (moment(day).isSame(fechaActividad, "day")) {

                  totales++;
                  if(actividad.completed){
                     totalCompletadas++;
                     acumRealizadas += totalCompletadas;       
                  }
                               
                
               }
              
            });
       
            arrayCompletadas.push(totalCompletadas);
            arrayTotales.push(totales);
          
        });

        // Guardo información de totales de actividades
        setTotalReazlizadas(acumRealizadas);   
        let total = arrayTotales.reduce((a, b) => a + b, 0);
        setTotales(total);
        setTotalPOreazlizar(total - acumRealizadas);

   

        const updateActivityChart = () => {
            let Chart = window.Chart;
            let chartData = {
                labels: daysLabels,
                datasets: [
                    {
                        type: "line",
                        label: "Actividades realizadas",
                        borderColor: "#543DD0",
                        backgroundColor: "#543DD0",
                        borderWidth: 2,
                        fill: false,
                        data: [...arrayCompletadas],
                    },

                    {
                        type: "bar",
                        labels: ["1", "2", "3", "4", "5", "6", "7"],
                        label: "Totales",
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        data: [...arrayTotales],
                    },
                ],
            };


            let ctx = chartContainer.getContext("2d");
            window.myMixedChart = new Chart(ctx, {
                type: "bar",
                data: chartData,
                options: {
                    responsive: true,
                    tooltips: {
                        mode: "index",
                        intersect: true,
                    },
                },
            });

        }
        const updateCategoriaChart = () => {
            var Chart = window.Chart;
            var radarActivityData = {
                type: "bar",
                data: {
                    labels: [...arrayCategory],
                    datasets: [
                        {
                            backgroundColor: "rgba(84, 61, 208,0.2)",
                            borderColor: "rgba(84, 61, 208,1)",
                            data: [...contCat],
                        },
                    ],
                },
                options: {
                    legend: {
                        display: false,
                        responsive: true,
                        tooltips: {
                            mode: "index",
                            intersect: true,
                        },
                    },
                },
            };
            let activityGraph = chartRadarContainer.getContext("2d");
            window.myLine = new Chart(activityGraph, radarActivityData);
        }
        updateCategoriaChart();
        updateActivityChart();
    }, [assignServices, dayIni, dayFin, totalRealizadas, total])



    return (
        <>
            <div
                className="row mt-4 animated fadeIn"
            >
                <div className="col-md-12 col-12">
                    <div className="card little-shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-dark">
                                Resumen de actividad
                            </h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-area">
                                <canvas
                                    ref={(e) => (chartContainer = e)}
                                    id="activity-graph"
                                ></canvas>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div
                className="row mt-1 animated fadeIn"
            >
                <div className="col-md-8 col-12 ">
                    <div className="card little-shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-dark">
                                Categorías
                                    </h6>
                        </div>
                        <div className="card-body">
                            <div className="chart-area">
                                <div className="row pt-4">
                                    <div className="col-md-12">
                                        <canvas ref={e => chartRadarContainer = e} id="radarActivity"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-4 col-12 ">
                    <div className="card little-shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-dark">
                                Actividades
                            </h6>
                        </div>
                        <div className="card-body text-left">
                                <h2 className="p-2">Realizadas: <span className="text-success pl-2">{totalRealizadas}</span></h2>     
                                <h2 className="p-2">Por realizar: <span className="text-danger pl-2">{totalPorRealizar}</span></h2>
                                <hr />
                                <h2 className="p-1">Total de actividades: <span className="text-tele font-weight-bold pl-2">{total}</span></h2>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
