/* global Modernizr */
import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from "react-router-dom";

// import Registro from './registro/Registro';
// import Introduccion from './Introduccion';
import Cuenta from "./cuenta/Cuenta";
import Login from "./Login";
import TeleconsultaMovil from "./TeleconsultaMovil";
import ResumenAtencion from "./ResumenAtencion";
import IndexResumenAtencion from "./Teleconsulta/IndexResumenAtencion";
import DetectUserGetMediaFeature from "../components/DetectGetUserMediaFeature";

import API, {
   setNewToken,
   isApiLoged,
   getLogedPatient,
   setLogedPatient,
} from "../../services/api";
import authentication from "../../services/authentication";

import RouterRecursos from "./recursos/RouterRecursos";

import "../assets/css/bootstrap.min.css";
import "../assets/css/animate.css";
import "../assets/css/style.css";
import "../assets/css/develop.css";
import OlvidoContrasena from "./registro/OlvidoContrasena";

const isLocalhost = Boolean(
   window.location.hostname === "localhost" ||
      window.location.hostname.slice(0, 7) === "192.168"
);

function DevelopMenuLink({ label, to, activeOnlyWhenExact }) {
   let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact,
   });
   return (
      <div className={match ? "link-selected" : ""}>
         <Link to={to}>{label}</Link>
      </div>
   );
}

const dumyGetUserState = () => {
   return {
      higieneDelSueno: {
         objetivos: null,
      },
      resources: [],
   };
};

function MovilIndex({ location }) {
   const match = useRouteMatch();

   // console.log('match', match);
   const [_user, setUser] = useState(dumyGetUserState);
   const user = {
      data: _user,
      set: setUser,
   };
   const [isLoged, setIsLoged] = useState(false);
   const [tryAutologin, setTryAutologin] = useState(false);

   const patient = getLogedPatient();

   useEffect(() => {
      if (!isApiLoged) {
         authentication
            .refreshToken()
            .then((response) => {
               if (response.data.accessToken) {
                  setNewToken(response.data.accessToken);
                  setIsLoged(true);
                  setLogedPatient(response.data.patient);
               }
               setTryAutologin(true);
            })
            .catch((error) => {
               console.log("autologin error :", error);
               setTryAutologin(true);
            });
      } else {
         setIsLoged(true);
      }
   }, [isApiLoged]);

   return (
      <div className="fullh">
         <DetectUserGetMediaFeature />
         <Router>
            {isLocalhost ? (
               <div className="develop-menu" style={{ fontSize: "13px" }}>
                  <DevelopMenuLink to={`${match.path}`} label="Login" />
                  <DevelopMenuLink
                     to={`${match.path}/teleconsulta`}
                     label="Teleconsulta"
                  />
                  <DevelopMenuLink to={`${match.path}/intro`} label="Intro" />
                  <DevelopMenuLink
                     to={`${match.path}/registro`}
                     label="Registro"
                  />
                  <DevelopMenuLink to={`${match.path}/cuenta`} label="Cuenta" />
                  {/* <DevelopMenuLink to={`${match.path}/cuenta/recursos`} label="Recursos" /> */}
                  <DevelopMenuLink
                     to={`${match.path}/cuenta/recursos/asignados`}
                     label="R Asigna"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/cuenta/recursos`}
                     label="Recursos"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/cuenta/recursos/higiene-del-sueno`}
                     label="higiene"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/recursos/higiene-del-sueno/historias/`}
                     label="Historias"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/recursos/higiene-del-sueno/psicoeducacion`}
                     label="Psicoeduc"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/recursos/higiene-del-sueno/psicoeducacion/objetivos`}
                     label="Psico obje"
                  />
                  <DevelopMenuLink
                     to={`${match.path}/teleconsulta/resumen/5ef4c12bbd5685001792ad2c`}
                     label="Encuesta"
                  />
               </div>
            ) : null}

            <Switch>
               <Route exact path={`${match.path}/teleconsulta`}>
                  <TeleconsultaMovil
                     isLoged={isLoged}
                     tryAutologin={tryAutologin}
                  />
               </Route>

               <Route
                  exact
                  path={`${match.path}/teleconsulta/resumen/:vattentionid`}
               >
                  <IndexResumenAtencion
                     isLoged={isLoged}
                     tryAutologin={tryAutologin}
                     userType="patient"
                     patient={patient}
                  />
               </Route>

               <>
                  <Switch>
                     {/* <Route exact path={`${match.path}/intro`}>
                        <Introduccion isLoged={isLoged} />
                      </Route>

                      <Route exact path={`${match.path}/registro`}>
                        <Registro isLoged={isLoged} />
                      </Route> */}

                     <Route path={`${match.path}/cuenta`}>
                        <Cuenta
                           isLoged={isLoged}
                           user={user}
                           patient={patient}
                           location={location}
                           tryAutologin={tryAutologin}
                        />
                     </Route>

                     <Route exact path={`${match.path}/resumen-atencion`}>
                        <ResumenAtencion isLoged={isLoged} />
                     </Route>

                     <Route exact path={`${match.path}/olvido-contrasena`}>
                        <OlvidoContrasena isLoged={isLoged} />
                     </Route>

                     <Route path={`${match.path}/login`}>
                        <Login
                           isLoged={isLoged}
                           setIsLoged={setIsLoged}
                           tryAutologin={tryAutologin}
                        />
                     </Route>
                     <Route path={match.path}>
                        <Login
                           isLoged={isLoged}
                           setIsLoged={setIsLoged}
                           tryAutologin={tryAutologin}
                        />
                     </Route>
                  </Switch>
               </>
            </Switch>
         </Router>
      </div>
   );
}

export default MovilIndex;
