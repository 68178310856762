import React from "react";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import img1 from "../assets/images/introduccion/1.svg";
import img2 from "../assets/images/introduccion/2.svg";
import img3 from "../assets/images/introduccion/3.svg";
import img4 from "../assets/images/introduccion/4.svg";

const style = {
   height: "100vh",
};

/*
  Esta vista ha quedado deprecada.
*/
const Introduccion = () => {
   const history = useHistory();

   const handleContinue = () => {
      history.push("/movil/registro");
   };

   return (
      <div style={style}>
         <div className="header-short header-bar">
            <div className="center-logo text-center"></div>
         </div>
         <div className="body-90">
            <Carousel
               showArrows={false}
               showStatus={false}
               showThumbs={false}
               infiniteLoop
               __dynamicHeight
               emulateTouch
            >
               <div>
                  <img src={img1} />
                  <h2 className="legend">
                     Tú bienestar integral
                     <br />
                     <strong>con apoyo profesional</strong>
                  </h2>
               </div>
               <div>
                  <img src={img2} />
                  <h2 className="legend">
                     Recursos auditivos para
                     <br />
                     <strong>conciliar el sueño y meditar</strong>
                  </h2>
               </div>
               <div>
                  <img src={img3} />
                  <h2 className="legend">
                     Lleva el control de tus
                     <br />
                     <strong>medicamentos</strong>
                  </h2>
               </div>
               <div>
                  <img height="60%" src={img4} />
                  <h2 className="legend">
                     Registrate para acceder
                     <br />a todas las <strong>funciones</strong>
                  </h2>
                  <div className="row mt-3">
                     <div className="col-12 text-center">
                        <input
                           type="submit"
                           value="CONTINUAR"
                           onClick={handleContinue}
                        />
                     </div>
                  </div>
               </div>
            </Carousel>
         </div>
      </div>
   );
};

export default Introduccion;
