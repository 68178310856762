import React, { useState, useEffect } from "react";
import FichaPaciente from "./FichaPaciente";
import API, {
   setNewToken,
   isApiLoged,
   getLogedPatient,
   setLogedPatient,
} from "../../services/api";
import "../../mobil/assets/css/animate.css";
import queryString from "query-string";
import Loading from "./../../components/Loading";
import authenticationApi from "../../services/authentication";
import vattentionApi from "../../services/vattentions";

export const WebIndex = ({ location }) => {
   const [isLoading, setIsLoading] = useState(true);
   const [hasError, setHasErrors] = useState(false);
   const [temporalData, setTemporalData] = useState({
      apikey: null,
      nombrePaciente: null,
      rutPaciente: null,
      rutMedico: null,
      pacienteId: null,
      medicoId: null,
   });

   useEffect(() => {
      let defaultSession = {
         apikey: null,
         nombrePaciente: null,
         rutPaciente: null,
         rutMedico: null,
         pacienteId: null,
         medicoId: null,
      };

      const verifyApikey = async () => {
         let parsed = queryString.parse(window.location.search);
         console.log(parsed);
         var tokenData;
         try {
            tokenData = await authenticationApi.getTokenExtendedDetails(
               parsed.APIKey
            );
            console.log(tokenData);

            let sessionData = {
               apikey: parsed.APIKey,
               nombrePaciente: tokenData.data.nombrePaciente,
               rutPaciente: tokenData.data.rutPaciente,
               rutMedico: tokenData.data.rutMedico,
               pacienteId: tokenData.data.patientId,
               medicoId: tokenData.data.medicId,
            };
            setTemporalData(sessionData);
            localStorage.setItem("temporalData", JSON.stringify(sessionData));
         } catch (error) {
            console.log(error);
            setHasErrors(true);
         }

         updateIsLoading(false);
      };

      verifyApikey();
   }, []);

   const updateIsLoading = (value) => {
      setIsLoading(value);
   };

   return (
      <>
         {isLoading && <Loading />}

         {!isLoading && !hasError && (
            <div className="m-0 p-0">
               <FichaPaciente sessionData={temporalData} />
            </div>
         )}

         {hasError && (
            <div>
               El enlace ha expirado. Cierra está ventana e inicia una nueva
               sesión de teleconsulta.
            </div>
         )}
      </>
   );
};
