// import axios from 'axios';
import API, { isApiLoged } from "./api";
import URLS from "../URLS";

const patientServices = {
   // getPatients : function(){

   //   return API.axios({
   //     method: 'get',
   //     url: URLS.patients
   //   });

   // },

   getPatient: function (patientId) {
      return API.axios({
         method: "get",
         url: URLS.patients + "/" + patientId,
      });
   },

   post: function (patientData) {
      return API.axios({
         method: "post",
         url: URLS.patients,
         data: patientData,
      });
   },

   //crea notificaciones
   addResources: function (patientId, notificationData) {
      return API.axios({
         method: "post",
         url: URLS.patients + "/" + patientId + "/resources",
         data: {
            reminder: notificationData,
         },
      });
   },

   getResources: function (patientId, params) {
      return API.axios({
         method: "get",
         url: URLS.patients + "/activity/dates/" + patientId,
         params: params,
      });
   },

   setNotificationCompleted: function (reminderId, data) {
      return API.axios({
         method: "put",
         url: URLS.patients + "/activity/" + reminderId,
         data: data,
      });
   },
};

export default patientServices;
