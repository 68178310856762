// import axios from 'axios';
import API, { isApiLoged } from "./api";
import URLS from "../URLS";

const pollServices = {
   getAll: function () {
      return API.axios({
         method: "get",
         url: URLS.baseURL + URLS.polls,
      });
   },

   post: function (data) {
      return API.axios({
         method: "post",
         url: URLS.baseURL + URLS.polls + "/" + data.id,
         data: data,
      });
   },

   getPoll: function (data) {
      return API.axios({
         method: "get",
         url: URLS.baseURL + URLS.polls + "/verifyAnswerPatient",
         params: data,
      });
   },
};

export default pollServices;
