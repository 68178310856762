import React, { Component, useState } from "react";
import PropTypes from "prop-types";

//import AudioTest from "./AudioTest";
//import VideoTest from "./VideoTest";

import AudioVideoTest from "./AudioVideoTest";

import PrevioIngresoTest from "./PrevioIngresoTest";

// import { useCamera, useMicrophone, useMediaStream } from "./hooks";
// function SelecMicDevice( props ){
//   const microphoneList = useMicrophone();
//   console.log('microphoneList :', microphoneList);
//   return (
//     <select
//       id="microphoneId"
//       value={props.microphoneId}
//       onChange={(e,f) => console.log({e,f})}
//     >
//       {microphoneList.map(item => (
//         <option key={item.deviceId} value={item.deviceId}>
//           {item.label}
//         </option>
//       ))}
//     </select>
//   )
// }

class TestMicVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputPeak: 0,
      color: "bg-danger",
      micReady: false,
      testingMic: false,
      testingVideo: false,
      microphoneId: null,
      stepNumber: 1,
      permissions: {
        microphone: null,
        camera: null,
      },
      streams: [],
      videoContainer: null,
    };
    this.smootinterval = null;
  }

  handleStep(e) {
    this.setState({ stepNumber: e });
  };

  updateAudioTest = (stream) => {
    this.updateStreams(stream);
  }

  updateVideoTest = (stream) => {
    this.updateStreams(stream);
    // console.log(this.state.streams);
  }

  updateStreams = (stream) => {
    let streams = [...this.state.streams];
    streams.push(stream);
    this.setState({ streams });
    // console.log(this.state.streams); 
  }

  componentDidMount() {

    /* 
    const standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test( userAgent ),
        ios = /iphone|ipod|ipad/.test( userAgent );

    if( ios ) {
        if ( !standalone && safari ) {
            //browser
            this.onClickReady();
        } else if ( standalone && !safari ) {
            //standalone
        } else if ( !standalone && !safari ) {
            //uiwebview
        };
    } else {
        //not iOS
    }; */



    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;

    try {
      navigator.permissions
        .query({ name: "microphone" })
        .then((micPermissionStatus) => {
          // console.log('micPermissionStatus :', micPermissionStatus);
          if (micPermissionStatus.state === "granted") {
          }
          if (micPermissionStatus.state === "denied") {
            //console.log("EL USUARIO DENEGO EL USO DEL MICROFONO");
          }
        })
        .catch(() => console.log("not suppported")); //en firefox

      navigator.permissions
        .query({ name: "camera" })
        .then((camPermissionStatus) => {
          // console.log('camPermissionStatus :', camPermissionStatus);

          if (camPermissionStatus.state === "granted") {
            this.setState(
              {
                stepNumber: 2, //cambiar de 3 a 2 (para usar AudioVideoTest)
              },
              this.sendStateOut
            );
          }

          if (camPermissionStatus.state === "denied") {
            //console.log("EL USUARIO DENEGO EL USO DE LA CAMARA");
          }
        })
        .catch(() => console.log("not suppported")); //en firefox
    } catch (error) {
      //en firefox entra aqui
      console.error(error);
    }
  }

  onClickReady() {
    this.props.onClickReady();
  }

  sendStateOut() {
    /*console.log("this.state :", {
       permissions: this.state.permissions,
       micReady: this.state.micReady,
    });*/

    this.props.updateIsMediaActivate({
      permissions: this.state.permissions,
      micReady: this.state.micReady,
    });
  }

  render() {


    if (this.state.stepNumber == 1) {
      return <AudioVideoTest handleStep={this.handleStep.bind(this)}
        updateAudioTest={this.updateAudioTest.bind(this)}
        updateVideoTest={this.updateVideoTest.bind(this)} />;
    }

    /*
    if (this.state.stepNumber == 1) {
      return <AudioTest handleStep={this.handleStep.bind(this)} updateAudioTest={this.updateAudioTest.bind(this)} />;
    }


    if (this.state.stepNumber == 2) {
      return <VideoTest
        handleStep={this.handleStep.bind(this)}
        updateVideoTest={this.updateVideoTest.bind(this)}
      />;

    }
    */

    if (this.state.stepNumber == 2) { //cambiar de 3 a 2 (para usar AudioVideoTest)
      return <PrevioIngresoTest
        onClickReady={this.onClickReady.bind(this)}
        streams={this.state.streams}
        videoContainer={this.state.videoContainer} />;
    }
  }

  componentWillUnmount() {
    // console.log("DESMONTANDOME");

    clearInterval(this.smootinterval);
    try {
      this.state.streams.forEach((stream) => {
        stream.getTracks().forEach(function (track) {
          // console.log("track :", track);
          track.stop();
        });
      });
    } catch (error) {
      console.log('error componentWillUnmount track.stop() :>> ', error);
    }

  }
}

TestMicVideo.propTypes = {
  onClickReady: PropTypes.func,
  updateIsMediaActivate: PropTypes.func,
  nextButtonText: PropTypes.string,
};

TestMicVideo.defaultProps = {
  onClickReady: () => { },
  updateIsMediaActivate: () => { },
  nextButtonText: "Ingresar a la atención",
};

export default TestMicVideo;
