import React from 'react';

const ClipIconRecursos = () => {
    return ( 
        <svg width="27" height="27" viewBox="0 0 27 27">
            <defs>
                <clipPath id="clip-icon-recursos">
                <rect width="27" height="27"/>
                </clipPath>
            </defs>
            <g id="icon-recursos" clipPath="url(#clip-icon-recursos)">
                <rect width="27" height="27" fill="#fff"/>
                <path id="ic_extension_24px" d="M20.034,10.748H18.572v-3.9a1.955,1.955,0,0,0-1.95-1.95h-3.9V3.437a2.437,2.437,0,1,0-4.874,0V4.9H3.95a1.947,1.947,0,0,0-1.94,1.95v3.7H3.462a2.632,2.632,0,0,1,0,5.264H2v3.7a1.955,1.955,0,0,0,1.95,1.95h3.7V20.009a2.632,2.632,0,0,1,5.264,0v1.462h3.7a1.955,1.955,0,0,0,1.95-1.95v-3.9h1.462a2.437,2.437,0,1,0,0-4.874Z" transform="translate(1 2)"/>
            </g>
        </svg>
    );
}
 
export default ClipIconRecursos;