import React, { useState, useEffect } from "react";
// import { useHistory, useRouteMatch } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";

import ResumenAtencion from "../ResumenAtencion";
import Encuesta from "../../components/Encuesta";
import vattentionApi from "../../../services/vattentions";
import queryString from "query-string";
import Loading from "./../../../components/Loading";

const TeleconsultaMovil = ({ isLoged, tryAutologin, userType, patient }) => {
   let { vattentionid } = useParams();
   const history = useHistory();

   const [attention, setAttention] = useState({
      patientId: {},
      score: {},
      tokenRedgesamData: {},
   });

   let availablePoll = process.env.REACT_APP_POLL_ACTIVE;
   const [avaiblePoll, setAvaiblePoll] = useState(availablePoll);
   const handleAvaiblePoll = () => {
      setAvaiblePoll(false);
   };

   let st =
      userType == "medic" ? attention.score.medic : attention.score.patient;

   useEffect(() => {
      if (!isLoged && userType == "patient") {
         console.log(userType);
         return;
      }
      const parseResponse = (response) => {
         // console.log('response.data :', response.data);
         if (response.data && response.data.tokenRedgesamData) {
            if (!response.data.score) {
               response.data.score = {};
            }

            setAttention(response.data);
         } else {
            console.error("get Vattention error");
            console.error(response);
         }
      };

      if (isLoged) {
         vattentionApi
            .getVAttention(vattentionid)
            .then(parseResponse)
            .catch((err) => {
               console.error(err);
            });
      } else {
         const parsed = queryString.parse(window.location.search);

         vattentionApi
            .getVAttentionMedic(vattentionid, parsed.APIKey)
            .then(parseResponse)
            .catch((err) => {
               console.error(err);
            });
      }
   }, [isLoged]);

   const handleSubmit = async (starRating) => {
      if (userType == "medic") {
         const parsed = queryString.parse(window.location.search);
         await vattentionApi.updateVAttention(
            vattentionid,
            { "score.medic": parseInt(starRating) },
            parsed.APIKey
         );
         window.close();
      } else {
         vattentionApi.updateVAttention(vattentionid, {
            "score.patient": parseInt(starRating),
         });
      }

      const parsed = queryString.parse(window.location.search);

      if (parsed.redirect) {
         history.push(parsed.redirect);
      } else {
         history.push("/movil/cuenta");
      }
   };

   const getDurationString = (duration) => {
      if (duration) {
         let allminutes = Math.floor(duration / 60);
         var sd = {
            hour: Math.floor(duration / 3600),
            min: Math.floor((duration % 3600) / 60),
            sec: Math.floor(duration - allminutes * 60),
         };
         if (1 >= sd.min) {
            return "1 minuto";
         } else {
            return allminutes + "" + " minutos";
         }
      } else {
         return "";
      }
   };

   if (!isLoged && userType == "patient") {
      return <Loading />;
   } else {
      return (
         <>
            {avaiblePoll == "TRUE" && userType == "patient" ? (
               <Encuesta
                  patientId={patient._id}
                  nameMedic={attention.tokenRedgesamData.doctorName}
                  especialidad={attention.tokenRedgesamData.specialty}
                  rutMedic={attention.tokenRedgesamData.doctorRut}
                  namePatient={attention.tokenRedgesamData.patientName}
                  rutPatient={attention.tokenRedgesamData.patientRut}
                  propRating={st}
                  callDuration={getDurationString(attention.duration)}
                  onSubmit={handleSubmit}
                  handleAvaiblePoll={handleAvaiblePoll}
               />
            ) : (
               <ResumenAtencion
                  nameMedic={attention.tokenRedgesamData.doctorName}
                  especialidad={attention.tokenRedgesamData.specialty}
                  rutMedic={attention.tokenRedgesamData.doctorRut}
                  namePatient={attention.tokenRedgesamData.patientName}
                  rutPatient={attention.tokenRedgesamData.patientRut}
                  propRating={st}
                  callDuration={getDurationString(attention.duration)}
                  onSubmit={handleSubmit}
               />
            )}
         </>
      );
   }
};

export default TeleconsultaMovil;
