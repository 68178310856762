import React, { useEffect, useState } from "react";

const AudioActividad = ({ url, urlDescripcion }) => {
   const [audioSrc, setAudioSrc] = useState("");

   useEffect(() => {
      console.log(url);

      if (url.includes("open.spotify")) {
         let startStr = url.lastIndexOf("/") + 1;
         let endStr = url.indexOf("?");
         let track = url.substring(startStr, endStr);
         console.log(track);
         setAudioSrc("https://open.spotify.com/embed/track/" + track);
      } else {
      }
   }, []);

   return (
      <div className="actividadFondo">
         <iframe
            src={audioSrc}
            width="100%"
            height="380"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
         ></iframe>
      </div>
   );
};

export default AudioActividad;
