import React, { useState } from 'react';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import NavProgress from '../../components/NavProgress';
import TerminosYcondiciones from './TerminosYcondiciones';
import CreacionCuenta from './CreacionCuenta';
import CentroDeAtencion from './CentroDeAtencion';
import CodigoAutorizacion from './CodigoAutorizacion';
import SeleccionAvatar from './SeleccionAvatar';

function Registro() {
  const history = useHistory();
  const [step, _setStep] = useState(1);

  const setStep = (step) => {
    if( step === 6 ){
      history.push("/movil/cuenta");
    } else if( step === 3 ){
      history.push("/movil/login");
    }else{
      _setStep(step);
    }
  }

  const handleBackButton = (ev) => {
    ev.preventDefault();
    if (step > 1) {
      _setStep(step-1);
    }else{
      history.push("/movil");
    }
  }

	return (
		<>
      
        <NavProgress stepnumber={step} onBackButton={handleBackButton} />

        { step === 1 ?
          <TerminosYcondiciones setStep={setStep} />
        : null }

        { step === 2 ?
          <CreacionCuenta setStep={setStep} />
          : null }

        { step === 3 ?
          <CentroDeAtencion setStep={setStep} />
          : null }

        { step === 4 ?
          <CodigoAutorizacion setStep={setStep} />
          : null }

        { step === 5 ?
          <SeleccionAvatar setStep={setStep} />
        : null }

        { step === 6 ?
          <div> fin flujo </div>
        : null }

		</>
	);
}

export default Registro;
