import React, { useState, useEffect } from "react";
import img_next from "./assets/images/icono-white-next.svg";

class PrevioIngresoTest extends React.Component{
  constructor() {
    super();
    this.videoRef = React.createRef();
    this.stream = null;
  }

  componentDidMount() {
    const video = this.videoRef.current;
    const constraints = { video: true }

    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        
        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
        
        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }
        
        return getUserMedia(constraints);
        
      }
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then( (stream) => { 
        video.srcObject = stream;
        this.stream = stream;
      })
  }

  componentWillUnmount() {

    if(this.stream){
      this.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

  }


  render(){
    return(
      <div className="testmic testmic-fullh movile-app">
        <div className="testvideo">
          <div className="testmic-options">
            <div className="row">
              <div className="col">
                <button
                  onClick={this.props.onClickReady}
                  className="btn btn-success animated pulse infinite slow">
                    Ingresar a la atención <img width="22" src={img_next} alt="Ingresar" />
                </button>
              </div>
            </div>
          </div>
          <div className="bg-testvideo"></div>
          <video ref={this.videoRef} id="video" autoPlay></video>
          <small>Si estás listo presiona <strong>ingresar</strong></small>
        </div>
      </div>
    )
  }
}

export default PrevioIngresoTest;