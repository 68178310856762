import React, { useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import authentication from "../../services/authentication";
import { setNewToken, setLogedPatient } from "../../services/api";
import queryString from "query-string";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";

import imgLogoRedgesam from "../assets/images/redgesam-logo.png";
import home_photo from "../assets/images/introduccion/1.svg";

const customModalStyles = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
   },
};

const Login = (props) => {
   const history = useHistory();
   const [inputValues, setInputValues] = useState({
      rut: "",
   });
   const [entrando, setEntrando] = useState(false);
   const [isRutValid, setIsRutValid] = useState(true);
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
   const [alertModalText, setAlertModalText] = useState("");

   const handleInputChange = (event) => {
      setInputValues({
         ...inputValues,
         [event.target.name]: event.target.value,
      });
   };

   const handleRutChange = (event) => {
      let inputValue = event.target.value.toLowerCase();
      let formatValue = inputValue;
      const isValid = validateRut(inputValue);

      if (isValid) {
         formatValue = formatRut(inputValue, RutFormat.DASH);
      }
      setIsRutValid(isValid);

      setInputValues({
         ...inputValues,
         [event.target.name]: formatValue,
      });
   };

   const onSubmitRut = () => {
      let formatValue = inputValues.rut;
      const isValid = validateRut(inputValues.rut);

      if (isValid) {
         formatValue = formatRut(inputValues.rut, RutFormat.DASH);
      }
      setIsRutValid(isValid);
      setInputValues({
         ...inputValues,
         ["rut"]: formatValue,
      });
   };

   const onSubmit = (event) => {
      if (event) {
         event.preventDefault();
      }

      onSubmitRut();
      const parsed = queryString.parse(window.location.search);

      setEntrando(true);

      authentication
         .login({
            username: inputValues.rut,
            password: inputValues.password,
         })
         .then((response) => {
            if (response.data.accessToken) {
               setNewToken(response.data.accessToken);

               setLogedPatient(response.data.patient);

               if (parsed.redirect === "teleconsultaPaciente") {
                  history.push("/movil/teleconsulta");
               } else {
                  history.push("/movil/cuenta");
               }

               props.setIsLoged(true);
               return;
            }

            setAlertModalText("Usuario o contraseña incorrectos.");
            setAlertModalIsOpen(true);
            setEntrando(false);
         })
         .catch((error) => {
            setAlertModalText("Usuario o contraseña incorrectos.");
            setAlertModalIsOpen(true);
            setEntrando(false);
         });
   };

   // console.log('inputValues', inputValues);

   if (props.isLoged) {
      history.push("/movil/cuenta");
      return null;
   }

   return (
      <div className="content-container animated fadeIn slow">
         <div className="middle-center-content pt-3">
            <div className="row pb-2">
               <div className="col-12 text-center">
                  <img
                     src={imgLogoRedgesam}
                     width="34%"
                     className="img-fluid"
                     alt="Redgesam Logo"
                  />
               </div>
            </div>
            <div className="login-photo text-center">
               <img src={home_photo} alt="Iniciar Sesión" height="160px" />
            </div>
            <div className="heading mt-4">
               <h1 className="paged-title">Iniciar Sesión</h1>
            </div>
            <form onSubmit={onSubmit}>
               <div className="bodycontent">
                  <div className="row">
                     <div className="col-12 mb-3">
                        <label htmlFor="rut">Rut</label>
                        <div className="input-group">
                           <input
                              type="text"
                              className={isRutValid ? "" : "is-invalid"}
                              id="rut"
                              name="rut"
                              required
                              onChange={handleInputChange}
                              onBlur={handleRutChange}
                              placeholder="11120411-k"
                              value={inputValues.rut}
                           />
                           <div className="invalid-feedback">Rut invalido.</div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-12 mb-1">
                        <label>Contraseña</label>
                        <input
                           name="password"
                           onChange={handleInputChange}
                           required
                           type="password"
                        />
                     </div>
                  </div>
                  <div className="row mb-4">
                     <div className="col-12 text-left create-account">
                        <a
                           href="/movil/olvido-contrasena"
                           className="forgot-password"
                        >
                           ¿Olvidó su contraseña?
                        </a>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-6 text-left create-account">
                        <a href="/movil/registro"> Crear una cuenta </a>
                     </div>
                     <div className="col-6 text-right">
                        <input
                           type="submit"
                           value={entrando ? "CARGANDO" : "INGRESAR"}
                           disabled={entrando}
                        />
                     </div>
                  </div>
               </div>
            </form>
         </div>
         <Modal
            isOpen={alertModalIsOpen}
            style={customModalStyles}
            onRequestClose={() => setAlertModalIsOpen(false)}
         >
            <div className="alertModal">
               <h1>{alertModalText}</h1>
               <div className="text-center">
                  <button
                     type="button"
                     className="btn btn-submit btn-lg"
                     onClick={() => setAlertModalIsOpen(false)}
                  >
                     Aceptar
                  </button>
               </div>
            </div>
         </Modal>
      </div>
   );
};

export default Login;
