import React from 'react';

const ClipIconHoy = () => {
    return ( 
        <svg width="27" height="27" viewBox="0 0 27 27">
            <defs>
                <clipPath id="clip-icon-hoy">
                <rect width="27" height="27"/>
                </clipPath>
            </defs>
            <g id="icon-hoy" clipPath="url(#clip-icon-hoy)">
                <rect width="27" height="27" fill="#fff"/>
                <path id="ico" d="M17.75,3.909h2.86A2.389,2.389,0,0,1,23,6.3V20.61A2.389,2.389,0,0,1,20.61,23H4.39A2.389,2.389,0,0,1,2,20.61V6.3a2.389,2.389,0,0,1,2.39-2.39H7.25V2.955a.955.955,0,0,1,1.909,0v.955h6.682V2.955a.955.955,0,0,1,1.909,0Zm0,1.909v.955a.955.955,0,0,1-1.909,0V5.818H9.159v.955a.955.955,0,1,1-1.909,0V5.818H4.39a.48.48,0,0,0-.481.481V20.61a.48.48,0,0,0,.481.481H20.61a.48.48,0,0,0,.481-.481V6.3a.48.48,0,0,0-.481-.481ZM12.5,13.455a1.432,1.432,0,1,1,1.432-1.432A1.432,1.432,0,0,1,12.5,13.455Zm5.25,0a1.432,1.432,0,1,1,1.432-1.432A1.432,1.432,0,0,1,17.75,13.455ZM7.25,18.7a1.432,1.432,0,1,1,1.432-1.432A1.432,1.432,0,0,1,7.25,18.7Zm5.25,0a1.432,1.432,0,1,1,1.432-1.432A1.432,1.432,0,0,1,12.5,18.7Z" transform="translate(1 1)"/>
            </g>
        </svg>
    );
}
 
export default ClipIconHoy;