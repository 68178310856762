import React from 'react';
import imgLogoRedgesam from './../../assets/images/redgesam-logo.png';

const HeaderLogo = () => {
  return(
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <img src={imgLogoRedgesam} width="34%" className="img-fluid" alt="Redgesam Logo" />
        </div>
      </div>
    </div>
  )
}

export default HeaderLogo;