import React from "react";
import { Link } from "react-router-dom";
import ItemListaActividad from "../../../components/ItemListaActividad";
import ProgresoHoy from "../../../components/ProgresoHoy";
import imgEmptyList from "../../../assets/images/iconos/taskEmpty.svg";
import moment from "moment-timezone";
import "moment/locale/es";

var hoy = moment().tz("America/Santiago");
const ActividadesDiarias = ({
   notifications,
   patientId,
   onChangeNotification,
   onChangeReminder,
}) => {
   return (
      <>
         <div className="home-top-nav container-fluid animated fadeIn fast">
            <div className="header-nav-spacing mb-2">
               <div className="row row-today">
                  <div className="col-12 page-name border-bottom pb-2">
                     <h1>{hoy.format("LL")}</h1>
                     <small className="">Mis actividades</small>
                  </div>
               </div>
            </div>
            <div className="actividades-diarias">
               {notifications.length > 0 && (
                  <div className="col-12 mb-3 progreso-diario">
                     <ProgresoHoy
                        totalActividades={notifications.length}
                        totalCumplidas={notifications.reduce(
                           (total, noti) => (noti.completed ? ++total : total),
                           0
                        )}
                     />
                  </div>
               )}

               <div className="col-12 vh-100">
                  {notifications.map((recordatorio, indice) => {
                     return (
                        <ItemListaActividad
                           key={indice}
                           recordatorio={recordatorio}
                           patientId={patientId}
                           onChangeNotification={onChangeNotification}
                           //TODO acordar inputs con Pedro
                           nombreActividad={recordatorio.data.activityName}
                           horaAsignada={recordatorio.dateTime}
                           completada={recordatorio.completed}
                           categoria={recordatorio.categoria}
                           onChangeReminder={onChangeReminder}
                           dayId={recordatorio.dayId}
                        />
                     );
                  })}

                  {notifications.length === 0 && (
                     <div className="pl-4 pr-4 pt-0 pb-5 text-center sin-actividades">
                        <img
                           src={imgEmptyList}
                           alt="No hay actividades"
                           className="pb-2 full-width height40"
                        />
                        <h3>No hay actividades asignadas el día de hoy</h3>
                        <p>
                           Es un buen momento para aprovechar de explorar los
                           recursos
                        </p>
                        <div className="row">
                           <div className="col-12 text-center">
                              <Link to="/movil/cuenta/recursos">
                                 <button className="btn btn-submit">
                                    VISITAR RECURSOS
                                 </button>
                              </Link>
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default ActividadesDiarias;
