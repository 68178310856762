import React, { useState } from 'react';
import Star from '../assets/images/iconos/icon-star.svg';

const yellowStarStyles = {
  filter: `invert(73%) sepia(40%) saturate(918%) hue-rotate(13deg) brightness(112%) contrast(93%)`,
  width: `40px`,
  height: `40px`,
};

const blackStarStyles = {
  filter: `invert(0%) sepia(100%) saturate(7500%) hue-rotate(59deg) brightness(117%) contrast(116%)`,
  width: `40px`,
  height: `40px`,
}

const StarRating = (props) => {
  // const [rating, setRating] = useState(props.value);
  const [hover, setHover] = useState(null);


  const rating = props.value;

  return (
    <div className='row'>
      <div className="col-12 text-center">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;
          return (
            <span key={i}>
              {/* <input
                className="star-rating"
                type="radio" name="rating"
                style={{ display: "none" }}
                value={ratingValue}
                onClick={() => (
                  setRating(ratingValue),
                  props.onSetRating(ratingValue))
                } /> */}
              <img
                className="star"
                src={Star}
                alt="Estrella"
                onClick={() => (
                  // setRating(ratingValue),
                  props.onSetRating(ratingValue))
                }
                style={ratingValue <= (hover || rating) ? yellowStarStyles : blackStarStyles}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)} />
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default StarRating;