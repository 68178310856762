import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import iconCompleta from "../assets/images/iconos/icon-historia-completada.svg";
import patientApi from "../../services/patients";

/* 
Estructura Dummy

   {
      reminderId: "5eeb820ac08b7800172abcc2",
      medicUser: "5eeb820ac08b7800172abcc2",
      notificationCategory: "video",
      data: {
         nombreActividad: "mensaje del médico",
         instrucciones: "Instrucción del médico objetivo",
         objetivo: "Objetivo del ejercicio",
         externalLink: "https://www.google.com",
         linkDescription: "Descripción del link",
      },
      dayId: "5f3ef5237a31d9172902fe4b",
      dateTime: "2020-08-23T16:30:00.000+00:00",
      completed: false,
   }, 

*/

const ItemListaActividad = ({
   recordatorio,
   patientId,
   onChangeNotification,
   nombreActividad,
   horaAsignada,
   completada,
   categoria,
   onChangeReminder,
   dayId,
}) => {
   const [_tareaRealizada, set_tareaRealizada] = useState(completada);

   const [isClicked, setIsClicked] = useState(false);
   const handleClick = () => {
      setIsClicked(!isClicked);
   };

   const handleClickHref = (e) => {
      e.preventDefault();
      return;
   };

   const handleClickSi = (e) => {
      patientApi.setNotificationCompleted(recordatorio._id, {
         dayId: "",
         completed: true,
      });

      set_tareaRealizada(true);
      onChangeNotification({
         _id: recordatorio._id,
         completed: true,
      });
   };

   const handleClickNo = (e) => {
      let hoy = moment().tz("America/Santiago");
      let fecha = moment
         .utc(recordatorio.recordatorio.days[0].dateTime)
         .tz("America/Santiago");
      
      if (moment(fecha).isSame(hoy, "day")) {
         console.log("true");
      } else {
         console.log("falso");
      }

      getCurrentDayId();
      patientApi.setNotificationCompleted(recordatorio._id, {
         dayId: "",
         completed: false,
      });

      set_tareaRealizada(false);
      onChangeNotification({
         _id: recordatorio._id,
         completed: false,
      });
   };

   const getCurrentDayId = () => {
      let hoy = new Date();
      // console.log(hoy);
      // console.log(recordatorio);
      let filtrado = recordatorio.recordatorio.days.filter(
         (day) => day.dateTime == hoy
      );
      console.log(filtrado);
   };

   const handleSelectActividad = (e) => {
      e.preventDefault();
      onChangeReminder(recordatorio.dayId);
   };

   return (
      <div className="row reminder-row " onClick={handleClick}>
         <div className="col-2 text-center" style={{ lineHeight: 1 }}>
            {_tareaRealizada ? (
               <img src={iconCompleta} width="30" />
            ) : (
               // <img src={iconIncompleta} width="30" />
               null
            )}
         </div>
         <div className="col-10 pl-0">
            <div className="reminder-item-list">
               <a onClick={handleSelectActividad}>
                  <span className="reminder-name">{nombreActividad}</span>
                  <span className="reminder-date">
                     {horaAsignada.format("HH:mm A")}
                  </span>
               </a>
            </div>
         </div>
      </div>
   );
};

ItemListaActividad.propTypes = {
   recordatorio: PropTypes.object,
   tareaRealizada: PropTypes.bool,
};

ItemListaActividad.defaultProps = {
   recordatorio: {},
   tareaRealizada: false,
};

export default ItemListaActividad;
