 import React from 'react';

 function TextFieldBootstrap( props ) {
  return (

    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" id="">{props.label}</span>
      </div>
      <input type="text" className="form-control" { ...props } />
    </div>
    
  );
}

export default TextFieldBootstrap;