import React, { useState } from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import {Carousel} from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.css';

import avatar1 from '../../assets/images/avatars/avatar1/avatar-seleccion.svg';
import avatar2 from '../../assets/images/avatars/avatar2/avatar-seleccion.svg';
import avatar3 from '../../assets/images/avatars/avatar3/avatar-seleccion.svg';
import avatar4 from '../../assets/images/avatars/avatar4/avatar-seleccion.svg';
import avatar5 from '../../assets/images/avatars/avatar5/avatar-seleccion.svg';
import avatar6 from '../../assets/images/avatars/avatar6/avatar-seleccion.svg';


const SeleccionAvatar = ({setStep}) => {

  const handleSelectAvatar = () => {
    setStep(6) //en Registro.jsx, cuando el step es 6 redirecciona a /movil/cuenta
  }

  return ( 
    <div className="content-container elegir-avatar">
      <div className="middle-center-content pb-0">
        <div className="heading">
          <h1 className="paged-title mb-1">Selección de avatar</h1>
          <p>Escoge el personaje que te acompañara durante esta aventura.</p>
        </div>
      </div>
      <div className="avatar">
        <Carousel 
          showArrows={false} 
          showStatus={false} 
          showThumbs={false} 

          infiniteLoop 
          __dynamicHeight 
          emulateTouch>
          
          <div>
            <img src={avatar1}/>
          </div>
          <div>
            <img src={avatar2}/>
          </div>
          <div>
            <img src={avatar3}/>
          </div>
          <div>
            <img height="60%" src={avatar4}/>
          </div>
          <div>
            <img src={avatar5}/>
          </div>
          <div>
            <img src={avatar6}/>
          </div>
        </Carousel>
      </div>
      
      <div className="actioncontent">
        <div className="row justify-content-center">
          <div className="col-6 text-right">
            <input className="btn btn-submit btn-full-width" onClick={handleSelectAvatar} type="button" value="SELECCIONAR"/>
          </div>
        </div>
      </div>

    </div>
   );
}
 
export default SeleccionAvatar;