import React, { useState } from "react";
import imgIntroduccion from "../../assets/images/introduccion/introduccionMovil.svg";

const IntroBienvenido = ({ setStep }) => {
   const handleContinue = () => {
      setStep(2);
   };

   return (
      <div className="content-container text-center animated fadeIn slow">
         <div className="middle-center-content">
            <div className="pb-3">
               <img
                  src={imgIntroduccion}
                  alt="Esta es la app que te acompañará en tu día a día para mejorar tu bienestar"
                  className="img-fluid height40 animated pulse infinite slow"
               />
            </div>
            <div className="heading text-center">
               <h1 className="paged-title">¡Bienvenido!</h1>
               <p>
                  Esta es la app que te acompañará en tu día a día para{" "}
                  <em>mejorar tu bienestar</em>.
               </p>
            </div>
            <input
               className="animated pulse infinite slow"
               type="submit"
               value="CONTINUAR"
               onClick={handleContinue}
            />
         </div>
      </div>
   );
};

export default IntroBienvenido;
