import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import home_photo from '../assets/images/introduccion/1.svg';
import imgLogoRedgesam from '../assets/images/redgesam-logo.png';
import imgIconoUsuario from '../assets/images/iconos/icono-usuario.svg';
import StarRating from '../components/StarRating';

const ResumenAtencion = ({ nameMedic, rutMedic, namePatient, rutPatient, callDuration, especialidad, onSubmit, propRating}) => {
  
  const [rating, setRating] = useState(propRating);

  useEffect( ()=>{
    setRating(propRating);
  },[propRating] )

  const onSubmitFunction = (event) => {
    if (event) {
      event.preventDefault();
    }
    onSubmit(rating);
  }

  const handleSetRating = (r) => {
    setRating(r)
  }

  return (
    <div className="hoy-component fullwidth middday-bg animated fadeIn slow">
			<div className="header-redgesam">
        <div className="row pb-2 pt-2">
          <div className="col-12 text-center">
            <img src={imgLogoRedgesam} width="34%" className="img-fluid" alt="Redgesam Logo" />
          </div>
        </div>
      </div>
      <div className="resumeAtention">
        <form onSubmit={onSubmitFunction}>
          <div className="content-container">
            <div className="reviewRating text-center">
              <h1><b>Califique</b> su experiencia</h1>
              <p>Tu opinión es importante para mejorar nuestra calidad de servicio</p>
              <StarRating onSetRating={handleSetRating} value={rating} />
            </div>

            <div className="middle-center-content mt-1 pt-0">
              
              <div className="heading mt-4 text-center">
                <h1 className="paged-title">Resumen de su atención</h1>
              </div>

              <div className="box-border mb-3">
                <div className="box-heading">
                  <img src={imgIconoUsuario} alt="Médico"/> Médico que atendío
                </div>
                <div className="box-body">
                  <table>
                    <tbody>
                      <tr>
                        <td width="35%">RUT Médico</td>
                        <td><span className="font-weight-bold">{rutMedic}</span></td>
                      </tr>
                      <tr>
                        <td>Nombre</td>
                        <td><span className="font-weight-bold">{nameMedic}</span></td>
                      </tr>
                      <tr>
                        <td>Especialidad</td>
                        <td><span className="font-weight-bold">{especialidad}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="box-border mb-3">
                <div className="box-heading">
                  <img src={imgIconoUsuario} alt="Paciente"/> Paciente
                </div>
                <div className="box-body">
                  <table>
                    <tbody>
                      <tr>
                        <td width="35%">RUT paciente</td>
                        <td><span className="font-weight-bold">{rutPatient}</span></td>
                      </tr>
                      <tr>
                        <td>Nombre</td>
                        <td><span className="font-weight-bold">{namePatient}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="duracionAtencion mb-3">
                <div className="banner-bg">
                  <span>Duración de la Teleconsulta: <strong>{callDuration}</strong></span>
                </div>
              </div>

              <div className="col-12 text-center">
                <input type="submit" value="CONTINUAR" />
              </div>
            </div>
          </div>
        </form>
      </div>
		</div>
  )
}

ResumenAtencion.propTypes = {
  nameMedic: PropTypes.string,
  rutMedic: PropTypes.string,
  namePatient: PropTypes.string,
  rutPatient: PropTypes.string,
  callDuration: PropTypes.string,
  propRating: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

ResumenAtencion.defaultProps = {
  nameMedic: "",
  rutMedic: "",
  namePatient: "",
  rutPatient: "",
  callDuration: "",
  propRating: 0,
  onSubmit: () => {},
};

export default ResumenAtencion;
