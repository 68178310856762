import React, { useState, useEffect, useMemo, useCallback } from "react";
import patientApi from "../../../../services/patients";
import moment from "moment-timezone";
import ActividadesDiarias from "./ActividadesDiarias";
import Actividad from "./Actividad";
import Loading from "./../../../../components/Loading";


const fechaActual = moment().hour(0).minute(0).seconds(0);
const fechaFin = fechaActual.clone().hour(23).minute(59).seconds(59);
const ActividadesRouter = ({ patient }) => {
   // console.log(patient);
   const [patientId, setPatientId] = useState("");
   const [notifications, setNotifications] = useState([]);
   const [selectedReminder, setSelectedReminder] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [startDate, setStartDate] = useState(fechaActual.toDate());
   const [endDate, setEndDate] = useState(fechaFin.toDate());

   useMemo(() => {
      //TODO: try catch del fetch
      const fetchNotifications = async () => {
         let startOf = startDate;
         let endOf = endDate;
         console.log(endOf);
         console.log(startOf);

         const params = {
            startOfPeriod: startOf,
            endOfPeriod: endOf,
         };

         // console.log(patient);

         let notificationsForToday = [];
         const patientNotifications = await patientApi.getResources(
            patient._id,
            params
         ).then(async ({ data }) => {
            // console.log(data);
            if (Array.isArray(data)) {
               await data.map(({ _id, medicUser, days, data }) => {
                  // console.log(days);
                  let reminderObj = {
                     reminderId: _id,
                     medicUser: medicUser,
                     notificationCategory: data.typeLink,
                     data: data,
                     dayId: days[0]._id,
                     dateTime: moment.utc(days[0].dateTime),
                     completed: days[0].completed,
                  };

                  notificationsForToday.push(reminderObj);
               });
            }

         }).catch((err) => {
            notificationsForToday.push([])
            console.log('error : ', err);
         });

         // Orden de horas en forma acendente de actividades diarias
         notificationsForToday.sort((a, b) => a.dateTime._a[3] - b.dateTime._a[3]);
         setNotifications(notificationsForToday);

         //Antes de hacer el render verifica si ya se cargó el id del usuario
         if (patient._id) {
            setIsLoading(false);
         }
         setSelectedReminder(null);
      };

      fetchNotifications();
   }, [patient._id, fechaActual, fechaFin]);

   const onChangeNotification = (data) => {
      var newNotific = [...notifications];
      for (let i = 0; i < newNotific.length; i++) {
         const notif = newNotific[i];
         if (notif._id == data._id) {
            notif.tareaRealizada = data.completed;
            break;
         }
      }
      setNotifications(newNotific);
   };

   const onChangeReminder = (dayId) => {
      let reminderSelected = notifications.filter(
         (reminder) => reminder.dayId === dayId
      );
      setSelectedReminder(reminderSelected[0]);
   };

   const delay = (ms) => new Promise((res) => setTimeout(res, ms));

   const handleClickSi = async (recordatorioId, dayId) => {
      console.log("recordatorioID: > " + recordatorioId + " dayID: > " + dayId);
      patientApi.setNotificationCompleted(recordatorioId, {
         dayId: dayId,
         completed: true,
      });

      let reminderCopy = notifications;
      let reminderUpdated = reminderCopy.find(
         (reminder) => reminder.dayId === dayId
      );
      reminderUpdated.completed = true;
      setNotifications(reminderCopy);

      await delay(1500);
      setSelectedReminder(null);
   };

   if (isLoading) {
      return <Loading />;
   } else {
      return (
         <>
            {selectedReminder ? (
               <Actividad
                  categoria={selectedReminder.notificationCategory}
                  nombre={selectedReminder.data.activityName}
                  objetivo={selectedReminder.data.objective}
                  instrucciones={selectedReminder.data.pacientInstructions}
                  url={selectedReminder.data.externalLink}
                  urlDescripcion={selectedReminder.data.externalLinkDescription}
                  setSelectedReminder={setSelectedReminder}
                  horaAsignada={selectedReminder.dateTime}
                  esCompletada={selectedReminder.completed}
                  handleClickSi={handleClickSi}
                  recordatorioId={selectedReminder.reminderId}
                  dayId={selectedReminder.dayId}
               />
            ) : (
                  <ActividadesDiarias
                     notifications={notifications}
                     patientId={patientId}
                     onChangeNotification={onChangeNotification}
                     onChangeReminder={onChangeReminder}
                  />
               )}
         </>
      );
   }
};

export default ActividadesRouter;
