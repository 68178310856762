import React, { useEffect, useState } from "react";
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from "react-router-dom";

import Higiene from "./higiene/Higiene";
import Historias from "./higiene/historias-para-dormir/HistoriasParaDormir";
import VerHistoria from "./higiene/historias-para-dormir/VerHistoria";
import RecursoVista from "./RecursoVista";
import Explorar from "./Explorar";
import NavBar from "../cuenta/NavBar";
import Loading from "../../../components/Loading";

import MusicaRelajacion from "./higiene/musica-de-relajacion/MusicaDeRelajacion";
import VerMusicaDeRelajacion from "./higiene/musica-de-relajacion/VerMusicaDeRelajacion";
import CategoriaRecursos from "./CategoriaRecursos";

import ResourcesServices from "./../../../services/resources";

const RouterRecursos = (pros) => {
   const match = useRouteMatch(); // match.path = /movil/recursos

   const [recursos, setRecursos] = useState();
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      console.log(match);
      ResourcesServices.getResources()
         .then((res) => {
            console.log(res);
            setRecursos(res.data);
            setLoading(false);
         })
         .catch((error) => {
            console.log(error);
         });
   }, []);

   if (loading) {
      return <Loading />;
   } else {
      return (
         <>
            <Switch>
               {/* <Route
                  path={`${match.path}/higiene-del-sueno/historias/:nombreHistoria`}
               >
                  <VerHistoria />
               </Route>
               <Route path={`${match.path}/higiene-del-sueno/historias`}>
                  <Historias />
               </Route>

               <Route
                  path={`${match.path}/higiene-del-sueno/musica-de-relajacion/:nombreHistoria`}
               >
                  <VerMusicaDeRelajacion />
               </Route>
               <Route
                  path={`${match.path}/higiene-del-sueno/musica-de-relajacion`}
               >
                  <MusicaRelajacion />
               </Route> */}

               {/* <Route path={`${match.path}/higiene-del-sueno`}>
                  <Higiene recursosHigiene={recursos} />
               </Route> */}

               <Route path={`${match.path}/higiene-del-sueno`}>
                  <CategoriaRecursos
                     recursos={recursos}
                     categoriaName={"Higiene del Sueño"}
                  />
               </Route>

               <Route path={`${match.path}/relajacion`}>
                  <CategoriaRecursos
                     recursos={recursos}
                     categoriaName={"Relajación"}
                  />
               </Route>

               <Route path={`${match.path}/:id`}>
                  <RecursoVista recursos={recursos} />
               </Route>

               <Route path={`${match.path}`}>
                  <Explorar />
               </Route>
            </Switch>
         </>
      );
   }
};

export default RouterRecursos;
