import React, { useState } from "react";
import reload from "../assets/images/reload.svg";

const ReloadControl = ({ handleClickRefresh }) => {
   const handleClick = () => {
      handleClickRefresh();
   };

   return (
      <button className="mr-2" onClick={handleClick}>
         <img src={reload} width="40" />
      </button>
   );
};

export default ReloadControl;
