import React, { useState } from 'react';

const CentroDeAtencion = ({setStep}) => {

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    setStep(4);
  }

  const handleNext = () => {
    setStep(4);
  }

  return ( 
    <div className="content-container">
      <div className="middle-center-content mt-5">
        <div className="heading text-center">
            <h1 className="paged-title">Eres paciente de un centro de atención?</h1>
            <p>Si eres paciente activo de un centro de atención, puedes vincular tu cuenta para mejorar la experiencia, obtener beneficios, premios y descuentos.</p>
        </div>
        <form onSubmit={onSubmit}>
            <div className="bodycontent">
              <div className="accountform">
                <div className="row">
                  <div className="col-12 mb-3">
                      <label>Centro de atención</label>
                      <select className="custom-select mb-3" defaultValue="0">
                        <option value="0">Redgsam</option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="actioncontent">
              <div className="row">
                <div className="col-6 text-left">
                  <input className="btn btn-skip btn-full-width" onClick={handleNext} type="button" value="OMITIR" />
                </div>
                <div className="col-6 text-right">
                  <input className="btn btn-submit btn-full-width" onClick={handleNext} type="button" value="CONTINUAR" />
                </div>
              </div>
            </div>
        </form>
      </div>
    </div>
   );
}
 
export default CentroDeAtencion;