import React, { useEffect } from "react";
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from "react-router-dom";

import icono_next from "../../assets/images/iconos/icono-white-next.svg";
import avatar1_categoria_activacion_conductual from "../../assets/images/recursos/avatar1/categoria-activacion-conductual.svg";
import avatar1_categoria_relajacion from "../../assets/images/recursos/avatar1/categoria-relajacion.svg";
import avatar1_categoria_higiene_del_sueno from "../../assets/images/recursos/avatar1/categoria-higiene-del-sueno.svg";
import imagenRelajacion from "./../../assets/images/recursos/recursos-relajacion.png";
import imagenHigiene from "./../../assets/images/recursos/recursos-higiene.png";
import imagenActivacion from "./../../assets/images/recursos/recursos-activacion-conductual.svg";

function ItemNavRecurso({ label, to }) {
   let match = useRouteMatch({
      path: to,
      exact: true,
   });
   return (
      <Link to={to}>
         <button className={match ? "activo" : ""}>{label}</button>
      </Link>
   );
}

function LinkkHigieneDelSueno(props) {
   let higieneObjetivos = false;
   /* try {
      higieneObjetivos = !!props.user.data.higieneDelSueno.objetivos;
   } catch (error) {}
 */
   let url = "/movil/cuenta/recursos/higiene-del-sueno";
   return (
      <Link to={url} className="">
         {props.children}
      </Link>
   );
}

function LinkRelajacion(props) {
   /* try {
      higieneObjetivos = !!props.user.data.higieneDelSueno.objetivos;
   } catch (error) {}
 */
   let url = "/movil/cuenta/recursos/relajacion";
   return (
      <Link to={url} className="">
         {props.children}
      </Link>
   );
}

const Explorar = (props) => {
   const match = useRouteMatch();

   return (
      <div className="fullh">
         <div className="home-top-nav container-fluid animated fadeIn fast">
            <div className="header-nav-spacing">
               <div className="row">
                  <div className="col-12 page-name">
                     <h1>Recursos</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <ul className="inline-navegation">
                        <li>
                           <ItemNavRecurso label="Explorar" to={match.path} />
                        </li>
                        {/* <li>
                           <ItemNavRecurso
                              label="Asignados"
                              to={`${match.path}/asignados`}
                           />
                        </li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>

         <Switch>
            <Route path={`${match.path}`}>
               <div className="recursos-page resource-list">
                  <div className="container-fluid">
                     <div className="row align-items-center resource-item animated jackInTheBox delay-1hs pt-4 pb-4">
                        <div className="col-sm-4 col-6 text-right">
                           <h2>
                              <LinkRelajacion user={props.user}>
                                 Relajación
                              </LinkRelajacion>
                           </h2>
                           <span className="next-button">
                              <LinkRelajacion user={props.user}>
                                 EXPLORAR
                                 <img src={icono_next} alt="Explorar" />
                              </LinkRelajacion>
                           </span>
                        </div>
                        <div className="col-sm-8 col-6 text-left pl-0">
                           <Link to="/movil/cuenta/recursos/relajacion">
                              <img
                                 src={imagenRelajacion}
                                 alt="Relajación"
                                 className="fullwidth"
                              />
                           </Link>
                        </div>
                     </div>

                     <div className="row align-items-center resource-item animated jackInTheBox delay-1hs pt-4 pb-4">
                        <div className="col-sm-8 col-6 text-right pr-0">
                           <Link to="/movil/cuenta/recursos/higiene-del-sueno">
                              <img
                                 src={imagenHigiene}
                                 alt="Higiene del Sueño"
                                 className="fullwidth"
                              />
                           </Link>
                        </div>
                        <div className="col-sm-4 col-6 text-left">
                           <h2>
                              <LinkkHigieneDelSueno user={props.user}>
                                 Higiene
                                 <br />
                                 del sueño
                              </LinkkHigieneDelSueno>
                           </h2>
                           <span className="next-button">
                              <LinkkHigieneDelSueno user={props.user}>
                                 EXPLORAR
                                 <img src={icono_next} alt="Explorar" />
                              </LinkkHigieneDelSueno>
                           </span>
                        </div>
                     </div>

                     <div className="row align-items-center resource-item animated jackInTheBox pt-2 pb-2">
                        <div className="col-12 text-center pl-3 pr-3 pt-3">
                           <img
                              src={imagenActivacion}
                              alt="Activación conductual"
                              className="fullwidth"
                              style={{ maxWidth: "400px" }}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Route>
         </Switch>
      </div>
   );
};

export default Explorar;
