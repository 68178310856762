import React, { useState, useEffect } from "react";
import Pregunta from "./Pregunta";
import PreguntaAbierta from "./PreguntaAbierta";
import PollServices from "./../../../services/polls";
import HeaderLogo from "./../general/HeaderLogo";

const EncuestaFuncional = ({ setAnswered, patientId, handleStep, pollData }) => {
   const [preguntas, setPreguntas] = useState(pollData);

   const [answers, setAnswers] = useState(new Array(pollData.questions.length));

   const handleChange = (questionData) => {
      setAnswers((answers) => {
         answers[questionData.indexNumber] = questionData.question;
         return [...answers];
      });
   };

   const onSubmit = (event) => {
      if (event) {
         event.preventDefault();
      }

      let patientAnswers = {
         id: pollData._id,
         patientId: patientId,
         answersArray: answers,
      };

      PollServices.post(patientAnswers)
         .then((res) => {
            handleStep(3);
         })
         .catch((error) => {
            console.log(error);
         });
   };

  const handleOmitir = () =>{
    setAnswered(true);
  };

   return (
      <div className="page-encuesta-start pt-3">
         <HeaderLogo />
         <div className="col-12 pt-3 pb-2 text-center">
            <h1 className="paged-title mb-1">Queremos aportar en tu día a día</h1>
            <p>¿Que opinas de estas ideas?</p>
         </div>
         <div className="col-12 pb-3">
            <form onSubmit={onSubmit}>
               {preguntas.questions.map((pregunta, i) => (
                  <div key={pregunta.number} className="">
                     {pregunta.options.inputype === "text" && (
                        <PreguntaAbierta
                           title={pregunta.title}
                           handleChange={handleChange}
                           index={i}
                           placeholder={pregunta.placeholder}
                        />
                     )}
                     {pregunta.options.inputype === "radio" && (
                        <Pregunta
                           key={pregunta.index}
                           index={i}
                           title={pregunta.title}
                           description={pregunta.description}
                           icon={pregunta.icon}
                           options={pregunta.options}
                           handleChange={handleChange}
                        />
                     )}
                  </div>
               ))}
               <div className="row">
                <div className="col-8">
                  <button className="btn btn-submit btn-full-width">Enviar</button>
                </div>
                <div className="col-4">
                  <button className="btn btn-submit btn-gray btn-full-width" onClick={handleOmitir}>Omitir</button>
                </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default EncuestaFuncional;
