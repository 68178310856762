import React, { useReducer, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import vattentionService from "../services/vattentions";
import StreamPlayer from "agora-stream-player";
import { useCamera, useMicrophone, useMediaStream } from "./hooks";
// import AgoraRTC from "./utils/AgoraEnhancer";
import getEnhancedAgoraRTC from "./utils/AgoraEnhancer.js";
import imgLogoRedGesam from "./assets/images/redgesam-logo.png";
import TextField from "./utils/TextFieldBootstrap";
import findPatientAgoraChanel from "../teleconsulta/utils/findPatientAgoraChanel";
import { Fab, Action } from "react-tiny-fab";
import VideoControl from "./components/VideoControl";
import AudioControl from "./components/AudioControl";
import ReloadControl from "./components/ReloadControl";

function b64_to_utf8(str) {
   return decodeURIComponent(escape(window.atob(str)));
}

const defaultState = {
   appId: "", //se obtiene desde la api
   channel: "",
   uid: "",
   token: undefined,
   cameraId: "",
   microphoneId: "",
   mode: "rtc",
   codec: "vp8",
   encryptionPassword: null,
};

const reducer = (
   state: typeof defaultState,
   action: { type: string; [propName: string]: any }
) => {
   switch (action.type) {
      default:
         return state;
      case "setAppId":
         return {
            ...state,
            appId: action.value,
         };
      case "setChannel":
         return {
            ...state,
            channel: action.value,
         };
      case "setUid":
         return {
            ...state,
            uid: action.value,
         };
      case "setToken":
         return {
            ...state,
            token: action.value,
         };
      case "setCamera":
         return {
            ...state,
            cameraId: action.value,
         };
      case "setMicrophone":
         return {
            ...state,
            microphoneId: action.value,
         };
      case "setMode":
         return {
            ...state,
            mode: action.value,
         };
      case "setCodec":
         return {
            ...state,
            codec: action.value,
         };
      case "setEncryptionPassword":
         return {
            ...state,
            encryptionPassword: action.value,
         };
   }
};

const enqueueSnackbar = (msg: any, config: any) => {
   //console.log('enqueueSnackbar :', { msg, config });
};

var timeOut: any = 0;
var timeOutVerificarCierre: any = 0;

function AppTeleconsultaPaciente(props) {
   const [videoEnable, setVideoEnable] = useState(true);
   const [audioEnable, setAudioEnable] = useState(true);

   // const classes = useStyles();

   const classes: any = {};
   const history = useHistory();
   const [AgoraRTC, setAgoraRTC] = useState(getEnhancedAgoraRTC());
   const [isJoined, setisJoined] = useState(false);
   const [isPublished, setIsPublished] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [state, dispatch] = useReducer(reducer, defaultState);
   const [agoraClient, setClient] = useState<any>(undefined);
   const [nombreMedico, setNombreMedico] = useState("");
   const [specialty, setSpecialty] = useState("");
   // const agoraClient = AgoraRTC.createClient({ mode: state.mode, codec: state.codec });
   const cameraList = useCamera();
   const microphoneList = useMicrophone();

   let [localStream, remoteStreamList, streamList] = useMediaStream(
      agoraClient
   );
   const [prevRemoteCount, setPrevRemoteCount] = useState(0);
   const [vattentionId, setVattentionId] = useState(null);
   // const { enqueueSnackbar } = useSnackbar();

   const [helpOpen, setHelpOpen] = useState(false);
   const update = (actionType: string) => (e: any) => {
      return dispatch({
         type: actionType,
         value: (e.target as HTMLInputElement).value,
      });
   };

   const findAgoraChanel = () => {
      findPatientAgoraChanel()
         .then((response) => {
            setNombreMedico(response.tokenRedgesamData.doctorName);
            setSpecialty(response.tokenRedgesamData.specialty);
            setVattentionId(response._id);
            dispatch({
               type: "setChannel",
               value: response.channelName,
            });

            dispatch({
               type: "setToken",
               value: response.key,
            });

            dispatch({
               type: "setEncryptionPassword",
               value: response.encryptionPassword,
            });

            dispatch({
               type: "setAppId",
               value: response.appID,
            });

            if (response.codec) {
               dispatch({
                  type: "setCodec",
                  value: response.codec,
               });
            }
         })
         .catch((error) => {
            console.log("error findPatientAgoraChanel:", error);
            timeOut = setTimeout(findAgoraChanel, 5000);
         });
   };

   useEffect(() => {
      findAgoraChanel();

      return () => {
         clearTimeout(timeOut);
      };
   }, []);

   // SEGUNDA COMPROBACION CUANDO HAY PROBLEMAS DE CONECCION CON SOCKETS
   const verificarCierre = () => {
      findPatientAgoraChanel()
         .then((response) => {
            console.log("todavia existe una sala activa", response);
            timeOutVerificarCierre = setTimeout(verificarCierre, 10000);
         })
         .catch((error) => {
            window.location.href =
               window.location.origin +
               "/movil/teleconsulta/resumen/" +
               vattentionId;
         });
   };

   useEffect(() => {
      if (remoteStreamList.length < prevRemoteCount) {
         console.log(
            "se fue el medico sin cerrar la sala, espera a que regrese"
         );
         timeOutVerificarCierre = setTimeout(verificarCierre, 5000);
      }

      if (remoteStreamList.length > 0) {
         console.log("Médico llegó");
         clearTimeout(timeOutVerificarCierre);
      }

      setPrevRemoteCount(remoteStreamList.length);
   }, [remoteStreamList]);

   //salir de agora cuando el componente se desmonta
   useEffect(() => {
      return () => {
         if (agoraClient) {
            leave();
         }
      };
   }, [agoraClient]);

   useEffect(() => {
      if (
         props.autoJoin &&
         state.channel &&
         state.token &&
         state.encryptionPassword &&
         state.appId
      ) {
         setIsLoading(true);
         setTimeout(() => {
            join();
         }, 1000);
      }
   }, [state.channel, state.token, state.encryptionPassword, state.appId]);

   const join = async () => {
      const client = AgoraRTC.createClient({
         mode: state.mode,
         codec: state.codec,
      });
      setClient(client);
      setIsLoading(true);
      try {
         const uid = isNaN(Number(state.uid)) ? null : Number(state.uid);
         await client.init(state.appId);

         client.setEncryptionMode("aes-128-xts");
         client.setEncryptionSecret(state.encryptionPassword);

         await client.join(state.token, state.channel, uid);
         const stream = AgoraRTC.createStream({
            streamID: uid || 12345, //TODO:
            video: true,
            audio: true,
            screen: false,
         });

         await stream.init();
         await client.publish(stream);
         setIsPublished(true);
         setisJoined(true);
         enqueueSnackbar(`Joined channel ${state.channel}`, {
            variant: "info",
         });

         //TODO:  cuando el paciente se conecta a la video atención.
         vattentionService.updateVAttentionStartAttentionByName(state.channel, {
            startAttention: new Date(),
         });
      } catch (err) {
         enqueueSnackbar(`Failed to join, ${err}`, { variant: "error" });
         leave();
      } finally {
         setIsLoading(false);
      }
   };

   const leave = async () => {
      setIsLoading(true);
      try {
         if (localStream) {
            localStream.close();
            agoraClient.unpublish(localStream);
         }
         await agoraClient.leave();
         setIsPublished(false);
         setisJoined(false);
         enqueueSnackbar("Left channel", { variant: "info" });

         history.push("/movil");
      } catch (err) {
         enqueueSnackbar(`Failed to leave, ${err}`, { variant: "error" });
      } finally {
         setIsLoading(false);
      }
   };

   const JoinLeaveBtn = () => {
      const disabled = !state.channel || !state.token || isLoading;
      var btnText;

      if (isJoined) {
         btnText = "Salir de la consulta";
      } else {
         // if( props.autoJoin ){
         if (disabled) {
            if (isLoading) {
               btnText = "Ingresando a la Consulta ...";
            } else {
               btnText = "Esperando al Medico";
            }
         } else {
            btnText = "Ingresar a la consulta";
         }
      }

      return (
         <button
            type="button"
            className={classes.buttonItem}
            onClick={isJoined ? leave : join}
            disabled={disabled}
         >
            {btnText}
         </button>
      );
   };

   // REFRESCA LA PAGINA CUANDO EL PACIENTE TIENE PROBLEMAS CON EL AUDIO O EL VIDEO
   const handleClickRefresh = () => {
      window.location.href =
         window.location.origin + "/movil/teleconsulta?directjoin=true";
   };

   const handleCamera = () => {
      if (localStream) {
         if (localStream.isVideoOn()) {
            localStream.muteVideo();
         } else {
            localStream.unmuteVideo();
         }
      }
   };

   const handleAudio = () => {
      if (localStream) {
         if (localStream.isAudioOn()) {
            localStream.muteAudio();
         } else {
            localStream.unmuteAudio();
         }
      }
   };

   return (
      <React.Fragment>
         <div className="mobile-user mobileapp-pacient-container">
            <div className="patient-video">
               <div className="col-12 header-meet-bg">
                  <div className="header-meet">
                     <div className="row align-items-center">
                        <div className="col-4 videoButtonState text-left">
                           <img
                              src={imgLogoRedGesam}
                              width="80px"
                              alt="RedGesam Teleconsulta"
                           />
                           {/* <JoinLeaveBtn /> */}
                        </div>
                        <div className="col-8 text-left">
                           <div className="medicData">
                              <span className="medicDataName">
                                 {nombreMedico}
                              </span>
                              <span className="medicDataSpeciality">
                                 {specialty}
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="body-call-video">
                  {remoteStreamList.map((stream: any, index) => {
                     if (index !== 0) return null;
                     return (
                        <StreamPlayer
                           className="patientvideo mobileapp-pacient"
                           key={stream.getId()}
                           stream={stream}
                           fit="contain"
                        />
                     );
                  })}
                  <div className="medic-video">
                     {localStream && (
                        <StreamPlayer stream={localStream} fit="contain" />
                     )}
                  </div>
               </div>

               <div className="body-call-footer">
                  <div className="d-flex">
                     <ReloadControl handleClickRefresh={handleClickRefresh} />
                     <VideoControl
                        prevRemoteCount={prevRemoteCount}
                        handleCamera={handleCamera}
                     />
                     <AudioControl
                        prevRemoteCount={prevRemoteCount}
                        handleAudio={handleAudio}
                     />
                  </div>
               </div>
            </div>

            <div className="card-body d-none">
               <form noValidate autoComplete="off">
                  <TextField
                     required
                     value={state.appId}
                     disabled={true}
                     id="appId"
                     label="App ID"
                     margin="normal"
                  />
                  <TextField
                     required
                     value={state.channel}
                     onChange={update("setChannel")}
                     disabled={true}
                     id="channel"
                     label="Channel"
                     margin="normal"
                  />

                  <TextField
                     value={state.token}
                     disabled={true}
                     id="token"
                     label="Token"
                     margin="normal"
                  />
               </form>
            </div>

            {/* <div className="">
               <div className={classes.advanceSettings}>
                  {" "}
                  <div>
                     {" "}
                     <form noValidate autoComplete="off">
                        <select
                           id="cameraId"
                           value={state.cameraId}
                           onChange={update("setCamera")}
                        >
                           {cameraList.map((item) => (
                              <option key={item.deviceId} value={item.deviceId}>
                                 {item.label}
                              </option>
                           ))}
                        </select>
                        <select
                           id="microphoneId"
                           value={state.microphoneId}
                           onChange={update("setMicrophone")}
                        >
                           {microphoneList.map((item) => (
                              <option key={item.deviceId} value={item.deviceId}>
                                 {item.label}
                              </option>
                           ))}
                        </select>
                     </form>
                  </div>
               </div>
            </div> */}
         </div>
      </React.Fragment>
   );
}

AppTeleconsultaPaciente.propTypes = {
   autoJoin: PropTypes.bool,
};

AppTeleconsultaPaciente.defaultProps = {
   autoJoin: false,
};

export default AppTeleconsultaPaciente;

const centralControlComponent = () => {
   return <div></div>;
};
