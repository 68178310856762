import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import ResourcesServices from "../../../services/resources";
import NavBar from "./NavBar";
import Recursos from "./ExplorarRecursos";
import Hoy from "./Hoy";
import Higiene from "./Higiene";
import GuiaVideoatencion from "./GuiaVideoatencion";
import RouterRecursos from "./../recursos/RouterRecursos";
import BuscarConsultaMedica from "../../components/BuscarConsultaMedica";
import ActividadesRouter from "../cuenta/actividades/ActividadesRouter";
import { useHistory } from "react-router-dom";

const Cuenta = (props) => {
   const match = useRouteMatch();
   const history = useHistory();
   const [enSalaEspera, setEnSalaEspera] = useState(
      JSON.parse(localStorage.getItem("enSalaEspera")) || {
         esperando: false,
         fechaCreacion: null,
      }
   );

   const stopSearching = () => {
      setEnSalaEspera(false);
   };

   const handleEspera = () => {
      setEnSalaEspera(true);
   };
   console.log('enSalaEspera', enSalaEspera);
   return (
      <div className="fullh">
         {enSalaEspera === true ? (
            <BuscarConsultaMedica
               isLoged={props.isLoged}
               tryAutologin={props.tryAutologin}
               location={props.location}
               stopSearching={stopSearching}
            />
         ) : null}

         <Switch>
            <Route path={`${match.path}/actividades`}>
               <ActividadesRouter patient={props.patient || {}} handleEspera={handleEspera}/>
            </Route>

            <Route path={`${match.path}/guia-videoatencion`} handleEspera={handleEspera}>
               <GuiaVideoatencion />
            </Route>

            <Route path={`${match.path}/recursos`}>
               <RouterRecursos isLoged={props.isLoged} user={props.user} handleEspera={handleEspera}/>
            </Route>

            <Route path={`${match.path}`}>
               <Hoy
                  isLoged={props.isLoged}
                  location={props.location}
                  tryAutologin={props.tryAutologin}
                  patient={props.patient || {}}
                  handleEspera={handleEspera}
                  stopSearching={stopSearching}
               />
            </Route>
         </Switch>

         <NavBar />
      </div>
   );
};

export default Cuenta;
