import React , { useState } from 'react';
import DatePicker from 'react-datepicker';
import { subDays, addDays, addHours, addSeconds } from 'date-fns';
// images
import IconPlus from "../../assets/images/icon4.svg";
// Styles
import 'react-datepicker/dist/react-datepicker.css';

export const OpcionesRecurso = (props) => {
   const {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      time,
      onChangeHour,
      handleInputChange,
      repeat,
      errors,
      periodicity,
      optionsPeriodicity,
      row
   } = props
 const maxRepeat = 4;
 const minRepeat = 0;
   return (
      <div className={" pb-2  mr-2 bg-light shadow-sm " + row}>

         <div className="col-md-5 col-sm-5 col-5">
            <strong className="footer-tele pl-0 pr-2 pt-2 text-left">
               Fecha Inicial
                  </strong>
            <DatePicker
               selected={startDate}
               name="fechaInicial"
               onChange={(date) => {
                  console.log(addHours(date,23))
                  setStartDate(date)
                  let dateFin = addSeconds(date, 86399)
                  setEndDate(dateFin)
               }}

               dateFormat="dd-MM-yyyy hh:ss a "
               selectsStart
               startDate={startDate}
              
               minDate={subDays(startDate, 0)}
               maxDate={addDays(startDate, 14)}
            />
            <strong className="footer-tele pl-0 pr-2 pt-2 text-left">
               Fecha de Termino
                  </strong>
            <DatePicker
       
               selected={endDate}
               name="fechaFinal"
               onChange={(date) => {
                  setEndDate(date)
               }}
               dateFormat="dd-MM-yyyy hh:ss a"
               selectsEnd
               startDate={startDate}
               endDate={endDate}
               
               minDate={startDate}
               maxDate={addDays(startDate, 14)}
            />
         </div>
         <div className="col-md-4 col-sm-4 col-4  ">
            <strong className="footer-tele pl-0 pr-2 pt-2 text-left">
               Hora
                  </strong>

            <DatePicker
               selected={time}
               onChange={onChangeHour}
               showTimeSelect
               showTimeSelectOnly
               timeIntervals={60}
               timeCaption="Hora"
               dateFormat="h:mm aa"
               value={time}
            />
         </div>
         <div className="col-md-2 col-sm-4 col-4 ">
            <strong className="footer-tele pl-0 pr-1 pt-2">
               Repeticiones
                  </strong>
            <input
               className="topfilters form-control"
               type="number"
               name="repeat"
               min={minRepeat}
               max={maxRepeat}
               onChange={handleInputChange}
               value={repeat}
            />
         </div>
         <div className="col-md-4 col-sm-5 col-6">
            <strong className="footer-tele pl-0 pr-0 ">Periocidad</strong>
            <select
               className={
                  "topfilters form-control p-0 " +
                  (errors.periodicity ? "border-danger" : "")
               }
               name="periodicity"
               onChange={handleInputChange}
               value={periodicity}
            >
               {optionsPeriodicity.map((opt, idx) => {
                  return (
                     <option key={idx} value={opt.value}>
                        {opt.label}
                     </option>
                  );
               })}
            </select>
         </div>
         <div className="mt-3 col-6">
            <button className={"btn-rounded"} type="submit">
               <span>
                  <img src={IconPlus} alt="" />
               </span>{" "}
                     Añadir
                  </button>
         </div>
      </div>
   )
}
