import React, { useState, useEffect } from "react";
import HeaderLogo from './../general/HeaderLogo';
import imgEncuesta from './../../assets/images/encuesta/poll.svg';

const EncuestaStart = ({handleStep, setAnswered}) => {
  
  const handleNextStep = () => {
    handleStep(2);
  }

  const handleOmitir = () =>{
    setAnswered(true);
  }

  return (
    <div className="page-encuesta-start pt-3">
      <HeaderLogo />
      <div className="middle-center-content pt-0 text-center">
        <div className="pl-5 pr-5">
          <img 
          src={imgEncuesta}
          alt="Esta es la app que te acompañará en tu día a día para mejorar tu bienestar"
          className="img-fluid height40 animated pulse infinite slow" />
        </div>
        
        <div className="heading text-center pl-0 pr-0">
          <h1 className="paged-title">¡Ayúdanos a mejorar!</h1>
          <p>Estamos trabajando para implementar nuevas funcionalidades y mejoras en esta aplicación que acabas de utilizar y por ello <strong>queremos saber tu opinión</strong>.</p>
        </div>
        
        <div className="row">
          <div className="col-8">
            <button className="btn btn-submit btn-full-width" onClick={handleNextStep}>Continuar</button>
          </div>
          <div className="col-4">
            <button className="btn btn-submit btn-gray" onClick={handleOmitir}>Omitir</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EncuestaStart;