import React from "react";
import imgIntroduccion from "../../assets/images/introduccion/introduccionMovil2.svg";

const IntroVideoAtencion = ({ setStep }) => {
   const handleContinue = () => {
      setStep(3);
   };

   return (
      <div className="content-container text-center animated fadeIn slow">
         <div className="middle-center-content">
            <div className="pb-3">
               <img
                  src={imgIntroduccion}
                  alt="Esta es la app que te acompañará en tu día a día para mejorar tu bienestar"
                  className="img-fluid height40 animated pulse infinite slow"
               />
            </div>

            <div className="heading text-center">
               <h1 className="paged-title">Teleconsulta</h1>
               <p>
                  Podrás atenderte con el profesional tratante y recibir
                  atención a distancia.
               </p>
            </div>
            <input
               type="submit"
               className="animated pulse infinite slow"
               value="CONTINUAR"
               onClick={handleContinue}
            />
         </div>
      </div>
   );
};

export default IntroVideoAtencion;
