import React, { useState, useEffect } from 'react';
import img_microfono from "./assets/images/microfono.svg";
import img_prueba_sonido from "./assets/images/permitir.svg";
import Modal from "react-modal";

Modal.setAppElement('#root');

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const AudioVideoTest = (props) => {

  const [audioModalIsOpen, setAudioModalIsOpen] = useState(false);
  const [audioStream, setAudioStream] = useState(false);

  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [videoStream, setVideoStream] = useState(false);

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  // TODO ver si se pueden unir ambos useEffect()
  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    }
  }, [audioStream])

  useEffect(() => {
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
    }
  }, [videoStream])

  // unión entre runMicTest y runVideoTest
  const runMicVideoTest = () => {

    /* navigator.getUserMedia = (
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia
    ); */

    // sección comun entre runMicTest y runVideoTest
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {

        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        return getUserMedia(constraints);
      }
    }

    // runMicTest de AudioTest
    navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      .then(function (audioStream, videoStream) {
        //props.handleStep(2);
        props.updateAudioTest(audioStream);
        setVideoStream(videoStream);
        props.updateVideoTest(videoStream);
        props.handleStep(2);
      })
      .catch(function (err) {
        // console.log("cam error :", error);

        if (err.name === 'NotAllowedError') {
          setAudioModalIsOpen(true);
        } else {
          //TODO: correcto manejo de errores 
          //alert( "No pudimos acceder al microfono de su dispositivo." );
          setAlertModalText("No pudimos acceder al micrófono o cámara de su dispositivo.");
          setAlertModalIsOpen(true);
        }
      });

    // runVideoTest de VideoTest
    /*
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (videoStream) {
          setVideoStream(videoStream);
          props.updateVideoTest(videoStream);
          props.handleStep(2);
        })
        .catch((error) => {
          // console.log("cam error :", error);

          if (error.name === 'NotAllowedError') {
            setVideoModalIsOpen(true);
          } else {
            //TODO: correcto manejo de errores 
            //alert("No pudimos acceder al microfono de su dispositivo.");
            setAlertModalText("No pudimos acceder a la cámara de su dispositivo.");
            setAlertModalIsOpen(true);
          }
        });
    }*/

    /* console.log("runmicTest audiotest");
    navigator.mediaDevices.getUserMedia({audio:true}) 
      .then((stream)=> {
        props.handleStep(2);
        props.updateAudioTest(stream);
      }, (err)=> console.error(err)); */
  };

  // handleGoAllowMedia de AudioTest
  const handleGoAllowMediaAudio = () => {
    setAudioModalIsOpen(false);
    let allowTab = window.open("/movil/tutorilAllowMedia?media=audio", "_blank");
    allowTab.addEventListener('beforeunload', (event) => {
      runMicVideoTest();
    });
  }

  // handleGoAllowMedia de VideoTest
  const handleGoAllowMediaVideo = () => {
    setVideoModalIsOpen(false);
    let allowTab = window.open("/movil/tutorilAllowMedia?media=video", "_blank");
    allowTab.addEventListener('beforeunload', (event) => {
      runMicVideoTest();
    });
  }

  return (
    <div className="testmic">
      <div className="default-test">
        <div>
          <div className="page-name mb-4">
            <h1>Antes de comenzar</h1>
            <h2></h2>
          </div>

          <div className="homeRecomendations">
            <div className="row">
              <div className="col-12">
                <div className="guiastep">
                  <div className="number">1</div>
                  <div className="guiastep-title">
                    <h3>Habilitar Micrófono y Cámara</h3>
                  </div>
                  <div className="guiastep-content">
                    <p>Presiona el siguiente botón para autorizar a la aplicación a acceder a la cámara y micrófono.</p>
                    <button
                      onClick={runMicVideoTest}
                      className="btn btn-submit btn-permission animated pulse infinite slow">Habilitar Micrófono y Cámara
                    </button>
                  </div>
                </div>

                <div className="guiastep mt-4">
                  <div className="number">2</div>
                  <div className="guiastep-title">
                    <h3><em>Permitir</em> solicitud</h3>
                  </div>
                  <div className="guiastep-content">
                    <p>Al presionar el botón anterior te aparecerá un cuadro emergente en donde deberás escoger la opción <strong>permitir</strong>.</p>
                    <img
                      src={img_prueba_sonido}
                      alt="Prueba de sonido"
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={audioModalIsOpen} style={customModalStyles} >
        <div className="audioModal">
          <h1>Para continuar necesitamos que actives tu micrófono.</h1>
          <p>Revisa esta guía para habilitar el uso</p>
          <div className="row">
            <div className="col-12">
              <button onClick={handleGoAllowMediaAudio} type="button" className="btn btn-submit btn-full-width" >Activar Microfono</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={videoModalIsOpen} style={customModalStyles} >
        <div className="videoModal">
          <h1>Para continuar necesitamos que actives tu cámara.</h1>
          <p>Es sensillo, a continuacion te explicamos como hacerlo.</p>
          <div className="row">
            <div className="col-12">
              <button onClick={handleGoAllowMediaVideo} type="button" className="btn btn-submit btn-full-width" >Activar Cámara</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
        <div className="audioAlertModal">
          <h1>{alertModalText}</h1>
          <div className="text-center">
            <button type="button" className="btn btn-submit btn-lg" onClick={() => setAlertModalIsOpen(false)}>Aceptar</button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default AudioVideoTest;


