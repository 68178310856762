import { useState, useEffect } from 'react';
import getEnhancedAgoraRTC from "../utils/AgoraEnhancer.js";
import { IClientWithPromise } from 'agoran-awe/types/promisify';

// const fakeClient = getEnhancedAgoraRTC().createClient({
//   mode: 'live',
//   codec: 'vp8'
// })

const noop = () => {};

interface MediaDeviceInfo {
  label: string;
  deviceId: string;
}

const useCamera = (client:any = null): MediaDeviceInfo[] => {
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    let mounted = true;

    if( client === null ){
      client = getEnhancedAgoraRTC().createClient({
        mode: 'live',
        codec: 'vp8'
      })
    }

    const onChange = () => {
      if (!client) {
        return;
      }
      client
        .getCameras()
        .then((cameras: MediaDeviceInfo[]) => {
          if (mounted) {
            setCameraList(cameras);
          }
        })
        .catch(noop);
    };
    
    client && client.on('camera-changed', onChange);
    
    onChange();

    return () => {
      mounted = false;
      client && (client as IClientWithPromise & {gatewayClient: any}).gatewayClient.removeEventListener('cameraChanged', onChange);
    };
  }, [client]);

  return cameraList;
};

export default useCamera;
