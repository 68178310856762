import React, { useReducer, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import queryString from "query-string";
import StreamPlayer from "agora-stream-player";
import vattentionService from "../services/vattentions";
import { useMediaStream } from "./hooks";
import Cronometro from "../components/Cronometer";
// import { formatRut, RutFormat, validateRut } from '@fdograph/rut-utilities';
// import AgoraRTC from "./utils/AgoraEnhancer";
import getEnhancedAgoraRTC from "./utils/AgoraEnhancer.js";
import TextField from "./utils/TextFieldBootstrap";
import agoraApi from "../services/agora";
import authenticationApi from "../services/authentication";
import teleconsulta_previa from "./assets/images/teleconsulta-previa.svg";
import paciente from "./assets/images/paciente.svg";
import imgLogoRedGesam from "./assets/images/redgesam-logo.png";
import vattentionApi from "../services/vattentions";

import socketIOClient from "socket.io-client";
import URLS from "../URLS";

import VideoControl from "./components/VideoControl";
import AudioControl from "./components/AudioControl";
import ReloadControl from "./components/ReloadControl";

Modal.setAppElement("#root");
function utf8_to_b64(str) {
   return window.btoa(unescape(encodeURIComponent(str)));
}

const defaultState = {
   appId: "", //se obtiene desde la api
   channel: "",
   uid: "",
   token: undefined,
   cameraId: "",
   microphoneId: "",
   mode: "rtc",
   codec: "vp8",
   patientRut: "",
   creatingAndEntering: false,
   idVAttention: "",
   encryptionPassword: null,
   hasVattentionOpen: false,
   openVattentions: [],
};

const customModalStyles = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
   },
};

const reducer = (
   state: typeof defaultState,
   action: { type: string; [propName: string]: any }
) => {
   switch (action.type) {
      default:
         return state;
      case "setAppId":
         return {
            ...state,
            appId: action.value,
         };
      case "setChannel":
         return {
            ...state,
            channel: action.value,
         };
      case "setUid":
         return {
            ...state,
            uid: action.value,
         };
      case "setToken":
         return {
            ...state,
            token: action.value,
         };
      case "setCamera":
         return {
            ...state,
            cameraId: action.value,
         };
      case "setMicrophone":
         return {
            ...state,
            microphoneId: action.value,
         };
      case "setMode":
         return {
            ...state,
            mode: action.value,
         };
      case "setCodec":
         return {
            ...state,
            codec: action.value,
         };
      case "setChanelUrl":
         return {
            ...state,
            chanelUrl: action.value,
         };
      case "setPatientRut":
         return {
            ...state,
            patientRut: action.value,
         };
      case "setPatientName":
         return {
            ...state,
            patientName: action.value,
         };
      case "setidVAttention":
         return {
            ...state,
            idVAttention: action.value,
         };
      case "setEncryptionPassword":
         return {
            ...state,
            encryptionPassword: action.value,
         };
      case "setHasVattentionOpen":
         return {
            ...state,
            hasVattentionOpen: action.value,
         };
      case "setOpenVattentions":
         return {
            ...state,
            openVattentions: action.value,
         };
   }
};

const enqueueSnackbar = (msg: any, config: any) => {
   //console.log("enqueueSnackbar :", { msg, config });
};

const beforeunloadEvent = (ev) => {
   // ev.preventDefault();
   const parsed = queryString.parse(window.location.search);
   vattentionService.medicCloseWindow(parsed.APIKey);

   var confirmationMessage = "¿Está seguro que desea salir?";

   (ev || window.event).returnValue = confirmationMessage; // Gecko + IE
   return confirmationMessage;
};

function AppTeleconsultaMedico({ location }) {
   const classes: any = {};
   const [audioEnable, setAudioEnable] = useState(true);
   const [AgoraRTC, setAgoraRTC] = useState(getEnhancedAgoraRTC());
   const [directJoin, setDirectJoin] = useState(false);
   const [isJoined, setisJoined] = useState(false);
   const [isPublished, setIsPublished] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [creatingAndEntering, setCreatingAndEntering] = useState(false);
   const [state, dispatch] = useReducer(reducer, defaultState);
   const [agoraClient, setClient] = useState<any>(undefined);
   const [rutMedico, setRutMedico] = useState<String>();
   const [prevRemoteCount, setPrevRemoteCount] = useState(0);
   const [isSticky, setSticky] = useState(false);
   const ref = useRef(null);

   const handleScroll = () => {
      if (ref.current) {
         setSticky(ref.current.getBoundingClientRect().top <= -450);
      }
   };

   useEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
         window.removeEventListener("scroll", () => handleScroll);
      };
   }, []);

   // const agoraClient = AgoraRTC.createClient({ mode: state.mode, codec: state.codec });
   // const cameraList = useCamera();
   // const microphoneList = useMicrophone();
   let [localStream, remoteStreamList, streamList] = useMediaStream(
      agoraClient
   );
   // const { enqueueSnackbar } = useSnackbar();

   // const update = (actionType: string) => (e: React.ChangeEvent<unknown>) => {
   const update = (actionType: string) => (e: any) => {
      // console.log("actionType :", actionType, e.target.value);
      return dispatch({
         type: actionType,
         value: (e.target as HTMLInputElement).value,
      });
   };

   const getMedicActiveRoom = async () => {
      const parsed = queryString.parse(window.location.search);
      try {
         const activeRoomsResponse = await vattentionApi.getMedicActiveRoom(
            parsed.APIKey
         );
         if (Array.isArray(activeRoomsResponse.data)) {
            return activeRoomsResponse.data;
         } else {
            return [];
         }
      } catch (error) {
         return [];
      }
   };

   const verifyApikey = async () => {
      let sessionData = JSON.parse(
         localStorage.getItem("temporalData") || "{}"
      );

      try {
         dispatch({
            type: "setPatientRut",
            value: sessionData.rutPaciente,
         });
         dispatch({
            type: "setPatientName",
            value: sessionData.nombrePaciente,
         });

         setRutMedico(sessionData.rutMedico);
      } catch (error) {
         console.error("TOKEN invalido (APIKey)");
         setIsLoading(false);
         return {};
      }

      setIsLoading(false);
      return sessionData;
   };

   //Al cargar el componente, busca en la url el rut del paciente y lo coloca en el input
   useEffect(() => {
      window.addEventListener("beforeunload", beforeunloadEvent);

      (async () => {
         const tokenData = await verifyApikey();
         const openVattentions = await getMedicActiveRoom();

         //no mostrar la consulta del paciente actual ( esto pasa cuando el medico actualiza la pagina)
         const filteredOpenVatt = openVattentions.filter((ovat) => {
            return ovat.patientRut !== tokenData.rutPaciente;
         });

         if (filteredOpenVatt.length > 0) {
            //mostrar mensage cerrar atenciones previas

            dispatch({
               type: "setHasVattentionOpen",
               value: true,
            });
            dispatch({
               type: "setOpenVattentions",
               value: filteredOpenVatt,
            });
         }
      })();

      //cuando se desmonta el componente quita el evento   window.addEventListener("beforeunload", (ev) => {
      return () => {
         window.removeEventListener("beforeunload", beforeunloadEvent);
      };
   }, []);

   useEffect(() => {
      if (remoteStreamList.length > 0) {
         console.log("Paciente llegó");
      }

      setPrevRemoteCount(remoteStreamList.length);
   }, [remoteStreamList]);

   //salir de agora cuando el componente se desmonta
   useEffect(() => {
      return () => {
         if (agoraClient) {
            leave();
         }
      };
   }, [agoraClient]);

   const join = async () => {
      const client = AgoraRTC.createClient({
         mode: state.mode,
         codec: state.codec,
      });
      setClient(client);
      setIsLoading(true);
      try {
         const uid = isNaN(Number(state.uid)) ? null : Number(state.uid);
         await client.init(state.appId);

         //console.log('state.appId :>> ', state.appId);

         //Sets encryption mode to "aes-128-xts","aes-256-xts" or "aes-128-ecb".
         client.setEncryptionMode("aes-128-xts");
         client.setEncryptionSecret(state.encryptionPassword);

         await client.join(state.token, state.channel, uid);

         const stream = AgoraRTC.createStream({
            streamID: uid || 12345,
            video: true,
            audio: true,
            screen: false,
         });
         // stream.setVideoProfile('480p_4')

         try {
            await stream.init();
            await client.publish(stream);
         } catch (error) {
            const streammal = AgoraRTC.createStream({
               streamID: uid || 12345,
               video: false,
               audio: true,
               screen: false,
            });

            await streammal.init();
            await client.publish(streammal);
         }

         setIsPublished(true);
         setisJoined(true);
         enqueueSnackbar(`Joined channel ${state.channel}`, {
            variant: "info",
         });
      } catch (err) {
         enqueueSnackbar(`Failed to join, ${err}`, { variant: "error" });

         //TODO:cuando se rechasan los permisos de mic/video, no ejecutar el leave porque lleva a la pantalla de resumen
         leave();
      } finally {
         setIsLoading(false);
         setCreatingAndEntering(false);
      }
   };

   const publish = async () => {
      setIsLoading(true);
      try {
         if (localStream) {
            await agoraClient.publish(localStream);
            setIsPublished(true);
         }
         enqueueSnackbar("Stream published", { variant: "info" });
      } catch (err) {
         enqueueSnackbar(`Failed to publish, ${err}`, { variant: "error" });
      } finally {
         setIsLoading(false);
      }
   };

   const leave = async () => {
      console.log("LEAVE state.channel :>> ", state.channel);

      setIsLoading(true);
      try {
         if (localStream) {
            localStream.close();
            agoraClient.unpublish(localStream);
         }
         await agoraClient.leave();
         setIsPublished(false);
         setisJoined(false);

         await vattentionService.updateVAttentionByName(state.channel, {
            endAttention: new Date(),
         });
         await vattentionService.closeVAttentionByName(state.channel);

         //window.removeEventListener("beforeunload", beforeunloadEvent);

         const parsed = queryString.parse(location.search);
         setTimeout(() => {
            vattentionService.medicCloseWindow(parsed.APIKey);
         }, 1000);
      } catch (err) {
         enqueueSnackbar(`Failed to leave, ${err}`, { variant: "error" });
      } finally {
         setIsLoading(false);
      }

      setCreatingAndEntering(false);
   };

   // Reemplazado por leave
   /*
  const unpublish = () => {
    if (localStream) {
      agoraClient.unpublish(localStream);
      setIsPublished(false);
      enqueueSnackbar("Stream unpublished", { variant: "info" });
    }

  };
  */

   const CreateRoomBtn = () => {
      const color = isJoined ? " btn btn-secondary" : " btn btn-primary";
      const hideButton = isJoined || isLoading;

      if (isJoined) {
         return null;
      }

      return (
         <div className="iniciar-teleconsulta-step">
            <div className="row align-items-center">
               <div className="col-sm-12">
                  <button
                     type="button"
                     className={
                        classes.buttonItem + color + " mr-3 btn-primary-rounded"
                     }
                     onClick={createAndJoin}
                     disabled={hideButton}
                  >
                     {"Iniciar consulta"}
                  </button>
               </div>
            </div>
         </div>
      );
   };

   const ClosePrevRoomBtn = () => {
      const hideButton = isJoined || isLoading;

      var nombrePaciente, fechaAtencion, horaAtencion;
      try {
         nombrePaciente =
            state.openVattentions[0].tokenRedgesamData.patientName;

         //TODO: usar moment para obtener formato deseado
         const createdAt = state.openVattentions[0].tokenRedgesamData.createdAt;
         fechaAtencion = new Date(createdAt).toLocaleDateString("es-CL");
         horaAtencion = new Date(createdAt).toLocaleTimeString("es-CL");
      } catch (error) {}
      return (
         <div className="iniciar-teleconsulta-step">
            <div className="row align-items-center">
               <div className="col-sm-5 text-right">
                  <img src={teleconsulta_previa} alt="Previa a teleconsulta" />
               </div>
               <div className="col-sm-7">
                  <p>
                     Tiene una consulta en curso con el paciente{" "}
                     <b>{nombrePaciente}</b> iniciada a las {horaAtencion} el
                     dia {fechaAtencion}.
                  </p>
                  <p>
                     Inicia la consulta para avisarle al paciente que estás
                     conectado y listo para atenderlo.
                  </p>
                  <button
                     type="button"
                     className={
                        classes.buttonItem +
                        "btn btn-secondary mr-3 btn-primary-rounded"
                     }
                     onClick={closePrevCreateAndJoin}
                     disabled={hideButton}
                  >
                     {"Iniciar consulta de todos modos"}
                  </button>
               </div>
            </div>
            <div className="row mt-4">
               <div className="col-12">
                  <p className="mb-0">
                     Para proporcionar la mejor calidad de video atención te
                     recomendamos que estés conectado desde un punto estable de
                     conexión dejando como última recurso la red móvil de tu
                     smartphone.
                  </p>
               </div>
            </div>
         </div>
      );
   };

   const JoinLeaveBtn = () => {
      const color = isJoined ? " btn btn-secondary" : " btn btn-primary";

      const hideButton = isLoading || !state.channel || !state.token;

      const [modalIsOpen, setModalIsOpen] = useState(false);

      if (hideButton) {
         return null;
      }

      return (
         <React.Fragment>
            <button
               type="button"
               className={classes.buttonItem + " mr-3 btn-primary-rounded"}
               // color={isJoined ? "secondary" : "primary"}
               onClick={
                  isJoined
                     ? () => {
                          setModalIsOpen(true);
                       }
                     : join
               }
               disabled={hideButton}
            >
               {isJoined ? "Salir de la consulta" : "Ingresar a la consulta"}
            </button>
            <Modal
               isOpen={modalIsOpen}
               style={customModalStyles}
               onRequestClose={() => setModalIsOpen(false)}
            >
               <div className="modalMedicoAtencion">
                  <h1>¿Está seguro de que quiere salir de la consulta?</h1>
                  <p>
                     Tendrá que iniciar otra consulta en caso de querer volver a
                     entrar.
                  </p>
                  <div className="row">
                     <div className="col-6">
                        <button
                           type="button"
                           className="btn btn-submit btn-full-width"
                           onClick={() => leave()}
                        >
                           Aceptar
                        </button>
                     </div>
                     <div className="col-6">
                        <button
                           type="button"
                           className="btn btn-submit btn-full-width square-message-default"
                           onClick={() => setModalIsOpen(false)}
                        >
                           Cancelar
                        </button>
                     </div>
                  </div>
               </div>
            </Modal>
         </React.Fragment>
      );
   };

   const getAgoraDynamicRtcToken = async () => {
      console.log("getagoradynamic");
      const parsed = queryString.parse(window.location.search);

      let data;
      if (parsed.directjoin == "true") {
         console.log("direct Join");
         data = {
            codec: state.codec,
            patientRut: parsed.patientRut,
            APYKey: parsed.APIKey,
         };
      } else {
         data = {
            codec: state.codec,
            patientRut: state.patientRut,
            APYKey: parsed.APIKey,
         };
      }

      console.log(data);

      return agoraApi
         .getRtcToken(data)
         .then((res) => {
            // console.log('res.data :', res.data);
            if (res.data && res.data.key) {
               dispatch({
                  type: "setToken",
                  value: res.data.key,
               });

               dispatch({
                  type: "setChannel",
                  value: res.data.channelName,
               });

               dispatch({
                  type: "setidVAttention",
                  value: res.data.idVAttention,
               });

               dispatch({
                  type: "setEncryptionPassword",
                  value: res.data.encryptionPassword,
               });

               dispatch({
                  type: "setAppId",
                  value: res.data.appID,
               });

               let roomParams = {
                  channelName: res.data.channelName,
                  key: res.data.key,
                  codec: state.codec,
               };
               // let dataurlencode = utf8_to_b64( JSON.stringify( roomParams ) )
               // //TODO: cambiar a una url configurada como variable de entorno
               // let url = location.origin + '/teleconsulta/paciente/' + dataurlencode;
               // dispatch({
               //   type: 'setChanelUrl',
               //   value: url
               // });
               return Promise.resolve(roomParams);
            } else {
               console.error("error obteniendo agora key");
               setCreatingAndEntering(false);
               return Promise.reject(new Error("error obteniendo agora key"));
            }
         })
         .catch((error) => {
            console.error(error);
            setCreatingAndEntering(false);
         });
   };

   const createAndJoin = async () => {
      setCreatingAndEntering(true);
      getAgoraDynamicRtcToken();
   };

   const closePrevCreateAndJoin = async () => {
      setIsLoading(true);

      try {
         for (let i = 0; i < state.openVattentions.length; i++) {
            const vatt = state.openVattentions[i];
            vattentionService.updateVAttentionByName(vatt.channel, {
               endAttention: new Date(),
            });
            await vattentionService.closeVAttentionByName(vatt.channel);
         }
      } catch (error) {}

      dispatch({
         type: "setHasVattentionOpen",
         value: false,
      });

      createAndJoin();
   };

   //https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/

   const lastLeave = useRef(() => {});
   const lastChanelName = useRef();

   useEffect(() => {
      // console.log('effect :', creatingAndEntering, state.channel, state.token);
      if (
         creatingAndEntering &&
         state.channel &&
         state.token &&
         state.encryptionPassword &&
         state.appId
      ) {
         join();
      }

      lastLeave.current = leave;
      lastChanelName.current = state.channel;
   }, [
      creatingAndEntering,
      state.channel,
      state.token,
      state.encryptionPassword,
      state.appId,
   ]);

   useEffect(() => {
      const parsed = queryString.parse(window.location.search);
      console.log("comprueba directJoin en URL");
      if (parsed.directjoin) {
         setisJoined(true);
         createAndJoin();
         setDirectJoin(true);
      }
   }, []);

   // REFRESCA LA PAGINA CUANDO EL PACIENTE TIENE PROBLEMAS CON EL AUDIO O EL VIDEO
   const handleClickRefresh = () => {
      const parsed = queryString.parse(location.search);
      window.location.href =
         window.location.origin +
         "/teleconsulta/medico?APIKey=" +
         parsed.APIKey +
         "&directjoin=true&patientRut=" +
         state.patientRut;
   };

   const handleCamera = () => {
      if (localStream) {
         if (localStream.isVideoOn()) {
            localStream.muteVideo();
         } else {
            localStream.unmuteVideo();
         }
      }
   };

   const handleAudio = () => {
      if (localStream) {
         if (localStream.isAudioOn()) {
            localStream.muteAudio();
         } else {
            localStream.unmuteAudio();
         }
      }
   };

   return (
      <React.Fragment>
         {/*  <span>
            <strong>{state.patientName}</strong>
            {state.patientRut}
         </span> */}

         <div className="mt-3 videoatencion-medic">
            <div className="row">
               <div className="col-12">
                  <div className="iniciar-teleconsulta">
                     <div className={classes.buttondiv}>
                        {state.hasVattentionOpen ? (
                           <ClosePrevRoomBtn />
                        ) : (
                           <CreateRoomBtn />
                        )}

                        {isJoined ? (
                           <>
                              <div className="end-video row align-items-center">
                                 <div className="col-6">
                                    <JoinLeaveBtn />
                                 </div>
                                 <div className="col-6 text-right pt-1">
                                    <Cronometro
                                       running={
                                          remoteStreamList.length > 0 &&
                                          isPublished
                                       }
                                    />
                                    {/* {remoteStreamList.length > 0 && isPublished ? <Cronometro /> : null} */}
                                 </div>
                              </div>

                              <div>
                                 <div className="patient-video mt-2">
                                    <div className="medic-video">
                                       {localStream && (
                                          <StreamPlayer
                                             stream={localStream}
                                             fit="contain"
                                          />
                                       )}
                                    </div>

                                    {remoteStreamList.map((stream: any) => (
                                       <div
                                          key={stream.getId()}
                                          className={`sticky-wrapper${
                                             isSticky ? " sticky" : ""
                                          }`}
                                          ref={ref}
                                       >
                                          <StreamPlayer
                                             className="patientvideo"
                                             key={stream.getId()}
                                             stream={stream}
                                             fit="cover"
                                          />
                                       </div>
                                    ))}
                                    <div className="default-message">
                                       <div>
                                          <img
                                             src={paciente}
                                             alt="Esperando paciente"
                                          />
                                       </div>
                                       <span>Esperando al paciente...</span>
                                    </div>
                                 </div>
                              </div>
                              <div className="body-call-footer pt-3">
                                 <div className="d-flex body-call-footer-buttons">
                                    <ReloadControl
                                       handleClickRefresh={handleClickRefresh}
                                    />
                                    <VideoControl
                                       prevRemoteCount={prevRemoteCount}
                                       handleCamera={handleCamera}
                                    />
                                    <AudioControl
                                       prevRemoteCount={prevRemoteCount}
                                       handleAudio={handleAudio}
                                    />
                                 </div>
                              </div>
                           </>
                        ) : null}
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-xs-12">
               <div className="d-none">
                  <div className={classes.advanceSettings}>
                     {/*<ExpansionPanel className={classes.advanceSettings}>*/}
                     {/* <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <p>Advanced Settings</p>
                </ExpansionPanelSummary> */}
                     <div>
                        {/* <ExpansionPanelDetails> */}
                        <form noValidate autoComplete="off">
                           <TextField
                              value={state.chanelUrl}
                              id="chanelUrl"
                              onChange={update("setChanelUrl")}
                              label="URL Consulta"
                              margin="normal"
                           />

                           <a href={state.chanelUrl} target="_blank">
                              {state.chanelUrl}
                           </a>

                           {/* <TextField
                      value={state.uid}
                      id="uid"
                      onChange={update("setUid")}
                      label="UID"
                      margin="normal"
                    /> */}

                           <select
                              id="cameraId"
                              value={state.cameraId}
                              onChange={update("setCamera")}
                           >
                              {/* {cameraList.map((item) => (
                                 <option
                                    key={item.deviceId}
                                    value={item.deviceId}
                                 >
                                    {item.label}
                                 </option>
                              ))} */}
                           </select>
                           <select
                              id="microphoneId"
                              value={state.microphoneId}
                              onChange={update("setMicrophone")}
                           >
                              {/* {microphoneList.map((item) => (
                                 <option
                                    key={item.deviceId}
                                    value={item.deviceId}
                                 >
                                    {item.label}
                                 </option>
                              ))} */}
                           </select>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row d-none">
               <div className="col-12 text-center mt-2">
                  <small>{state.channel}</small>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
}

AppTeleconsultaMedico.propTypes = {
   autoJoin: PropTypes.bool,
};

AppTeleconsultaMedico.defaultProps = {
   autoJoin: false,
};

export default AppTeleconsultaMedico;
