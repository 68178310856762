import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import imgIntroduccion from "../../assets/images/introduccion/introduccionMovil4.svg";

const IntroComencemos = () => {
   return (
      <div className="content-container text-center animated fadeIn slow">
         <div className="middle-center-content">
            <div className="pb-3">
               <img
                  src={imgIntroduccion}
                  alt="Esta es la app que te acompañará en tu día a día para mejorar tu bienestar"
                  className="img-fluid height40 animated pulse infinite slow"
               />
            </div>

            <div className="heading text-center pl-4 pr-4">
               <h1 className="paged-title">¡Comencemos!</h1>
               <p>
                  Para poder utilizar la app necesitas
                  <br />
                  estar <em>registrado</em>.
               </p>
            </div>

            <div className="row">
               <div className="col-6">
                  <Link to="/movil/login">
                     <input type="submit" value="YA TENGO CUENTA" />
                  </Link>
               </div>
               <div className="col-6">
                  <Link to="/movil/registro" className="gray-button">
                     <input type="submit" value="CREAR" />
                  </Link>
               </div>
            </div>
         </div>
      </div>
   );
};

export default IntroComencemos;
