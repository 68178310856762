import React, { useState, useEffect, useRef } from "react";
import {
   BrowserRouter as Router,
   Link,
   useParams,
   useHistory,
} from "react-router-dom";
import iconPlay from "../../assets/images/iconos/icon-play.svg";
import iconPause from "../../assets/images/iconos/icon-pause.svg";
import iconFullScreen from "../../assets/images/iconos/icon-full-screen.svg";
import iconTimer from "../../assets/images/iconos/icon-time-black.svg";
import iconRepeat from "../../assets/images/iconos/icon-repeat-black.svg";

const RecursoVista = ({ recursos }) => {
   let { id } = useParams();
   let history = useHistory();
   const [recurso, setRecurso] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [opacity, setOpacity] = useState(1);
   const [videoStatus, setVideoStatus] = useState(-1);

   const handleOpacity = (ev) => {
      const opacity = ev.target.value / 100;
      setOpacity(opacity);
   };

   const player = useRef();

   //Se separa en dos useEffect para diferenciar el renderizado
   useEffect(() => {
      let recurso = recursos.find((item) => item._id === id);
      console.log(recurso);
      setRecurso(recurso);
      setIsLoading(false);
   }, []);

   useEffect(() => {
      if (!recurso) {
         return;
      }
      //TODO:
      //Separar renderizado según el tipo de media
      if (recurso.media.typeMedia === "YOUTUBE") {
         //Obtener ID desde url
         let video_id = recurso.media.url.substr(
            recurso.media.url.indexOf("=") + 1
         );

         var tag = document.createElement("script");
         tag.src = "https://www.youtube.com/iframe_api";
         var firstScriptTag = document.getElementsByTagName("script")[0];
         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

         if (typeof window.YT !== "undefined") {
            createPlayer();
         }

         window.onYouTubeIframeAPIReady = () => {
            createPlayer();
         };

         function createPlayer() {
            player.current = new window.YT.Player("videoiframe", {
               videoId: video_id,
               height: "100%",
               width: "100%",
               playerVars: {
                  controls: 0,
                  modestbranding: 0,
                  rel: 0,
                  showinfo: 0,
                  autoplay: 0,
               },
               events: {
                  onReady: onPlayerReady,
                  onStateChange: onPlayerStateChange,
               },
            });
         }

         // 4. The API will call this function when the video player is ready.
         function onPlayerReady(event) {
            // event.target.playVideo();
         }

         // 5. The API calls this function when the player's state changes.
         //    The function indicates that when playing a video (state=1),
         function onPlayerStateChange(event) {
            //console.log('player change :', event.data);
            setVideoStatus(event.data);
            //pausa = 2
            //play = 1
            //Loading = 3
         }
      }
   }, [recurso]);

   function stopVideo() {
      player.current.pauseVideo();
   }
   function playVideo() {
      player.current.playVideo();
   }

   function playFullscreen() {
      console.log(player);
      var requestFullScreen =
         player.current.requestFullScreen ||
         player.current.mozRequestFullScreen ||
         player.current.webkitRequestFullScreen;
      if (requestFullScreen) {
         requestFullScreen.bind(player)();
      }
   }

   const styleOpacity = {
      opacity: opacity,
   };

   if (isLoading) {
      return <>Loading</>;
   } else {
      return (
         <>
            <div className="steped-component">
               <div className="header-short">
                  <div className="stepped-nav">
                     <div className="backbutton">
                        <button onClick={() => history.goBack()}>
                           <svg
                              version="1.1"
                              id="Capa_1"
                              x="0px"
                              y="0px"
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                           >
                              {" "}
                              <path
                                 fill="#000000"
                                 d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                              />{" "}
                           </svg>
                        </button>
                     </div>
                     <div className="stepped-title">
                        <span>{recurso.name.substr(0, 27) + "..."}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="ver-historia">
               <div className="play-video" style={styleOpacity}>
                  <div id="videoiframe"></div>
               </div>
               {/* <div className="vid-options white-box shadow">
                  <div className="row">
                     <div className="col text-center">
                        {videoStatus == 2 ? (
                           <button
                              onClick={playVideo}
                              className="vid-button vid-play"
                           >
                              <img width="30" src={iconPlay} />
                           </button>
                        ) : (
                           <button
                              onClick={stopVideo}
                              className="vid-button vid-pause"
                           >
                              <img width="30" src={iconPause} />
                           </button>
                        )}
                     </div>
                     <div className="col text-center">
                        <input
                           onChange={handleOpacity}
                           type="range"
                           min="0"
                           max="100"
                           defaultValue="100"
                        />
                     </div>
                     <div className="col text-center">
                        <button onClick={playFullscreen}>
                           <img width="30" src={iconFullScreen} />
                        </button>
                     </div>
                  </div>
               </div> */}
               <div className="recurso-view">
                  <div className="view-title">
                     <h3>{recurso.name}</h3>
                  </div>
                  <div className="view-extraDetail d-flex">
                     <span className="pr-1">
                        <img
                           width="20"
                           src={iconTimer}
                           className="opacity-05"
                        />
                        {recurso.extraDetail.duration}
                     </span>
                     <span>
                        <img
                           width="20"
                           src={iconRepeat}
                           className="opacity-05"
                        />
                        {recurso.extraDetail.suggestFrecuency}
                     </span>
                  </div>
                  <div className="view-description">
                     <p>{recurso.longDescription}</p>
                  </div>
               </div>
               <div className="end-page pt-5 pb-5"></div>
            </div>
         </>
      );
   }
};

export default RecursoVista;
