import React from "react";

function Button({ onClick, active, name, icon, shortDescription, show }) {
   return (
      <button
         onClick={onClick}
         className={
            "text-left  btn-activity " +
            (active ? "btn-activity-active" : "text-muted")
         }
         disabled={show}
      >
         <span className="activity-name">
            {name}
            {icon ? <img src={icon} /> : null}
         </span>
         <span className="activity-descripcion text-sm-left">
            {shortDescription}
         </span>
      </button>
   );
}

export default Button;

// titulo = { name }
// desc = { shortDescription }
// icon = { active? IconCheckDone: null }
// onClick = { this.verRecurso(_id) }
// active = { this.state.recursoActivo == _id }
