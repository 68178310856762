import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import img_next from "../../teleconsulta/assets/images/icono-white-next.svg";
import notiSoundOgg from "../../teleconsulta/assets/sounds/goes-without-saying.ogg";
import notiSoundMp3 from "../../teleconsulta/assets/sounds/goes-without-saying.mp3";
import findPatientAgoraChanel from "../../teleconsulta/utils/findPatientAgoraChanel";

var timeOut;

const REACT_APP_REQUEST_VATTENTION_INTERVAL =
   process.env.REACT_APP_REQUEST_VATTENTION_INTERVAL || 10000;

const BuscarConsultaMedica = ({
   isLoged,
   tryAutologin,
   location,
   stopSearching,
}) => {
   const history = useHistory();

   const [modal, setModal] = useState(null);
   const [nombreMedico, setNombreMedico] = useState("");
   const [rutMedico, setRutMedico] = useState("");
   const [especialidad, setEspecialidad] = useState("");
   const [modalNoInternet, setModalNoInternet] = useState(false);
   const [pacientJoin, setPacientJoin] = useState(null);

   const findAgoraChanel = () => {
      findPatientAgoraChanel()
         .then((response) => {
            setNombreMedico(response.tokenRedgesamData.doctorName);
            setRutMedico(response.tokenRedgesamData.doctorRut);
            setEspecialidad(response.tokenRedgesamData.specialty);
            // window.$(modal).modal('show');
            showModal();
         })
         .catch((error) => {
            if (error == "Error: Network Error") {
               window.$("#nointernet").modal("show");
               setModalNoInternet(true);
            } else {
               window.$("#nointernet").modal("hide");
               setModalNoInternet(false);
            }

            //Vulve a bsucar en 10 segundos mas
            timeOut = setTimeout(
               findAgoraChanel,
               REACT_APP_REQUEST_VATTENTION_INTERVAL
            );
            // timeOut = setTimeout(findAgoraChanel, 7000);
         });
   };

   useEffect(() => {
      return () => {
         pauseAudio();
      };
   }, []);

   useEffect(() => {
      clearTimeout(timeOut);

      if (isLoged && location.pathname !== "/movil/teleconsulta") {
         timeOut = setTimeout(findAgoraChanel, 1000);
      }

      if (
         !isLoged &&
         tryAutologin &&
         location.pathname !== "/movil/login" &&
         location.pathname !== "/movil/teleconsulta"
      ) {
         history.push("/movil/login");
      }

      return () => {
         clearTimeout(timeOut);
      };
   }, [isLoged, tryAutologin]);

   const handleClick = () => {
      window.$("#alertvideoatencionmodal").modal("hide");
      pauseAudio();
      setPacientJoin(true);
      let enEsperaVar = {
         esperando: false,
         fechaCreacion: null,
      };
      localStorage.setItem("enSalaEspera", JSON.stringify(enEsperaVar));
      clearTimeout(timeOut);
      stopSearching();
      history.push("/movil/teleconsulta");
   };

   useEffect(() => {
      if (pacientJoin == false) {
         setTimeout(() => {
            if (document.getElementById("notificationAudio")) {
               document.getElementById("notificationAudio").pause();
            }
         }, 60000);
      }
   }, [pacientJoin]);

   const showModal = () => {
      try {
         window.$("#alertvideoatencionmodal").modal("show");
         playAudio();
      } catch (error) {
         console.error(error);
      }
   };

   async function playAudio() {
      try {
         await document.getElementById("notificationAudio").play();
         setPacientJoin(false);
      } catch (error) {
         console.error(error);
      }
   }

   function pauseAudio() {
      try {
         document.getElementById("notificationAudio").pause();
      } catch (error) {
         console.error(error);
      }
   }

   return (
      <>
         {modalNoInternet && (
            <div
               id="nointernet"
               className="modal fade"
               tabIndex="-1"
               role="dialog"
               aria-hidden="true"
               data-backdrop="static"
            >
               <div
                  className="modal-dialog modal-dialog-centered atencion-ahora modal-warning"
                  role="document"
               >
                  <div className="modal-content">
                     <div className="modal-body">
                        <h3 className="text-center">
                           ¡Se ha perdido la conexión!
                        </h3>
                        <span>
                           Haz perdido la conexión, puede ser que en la red en
                           la cúal estás conectado se encuentre bloqueada.
                           Cambiate de red y espera unos segundos antes de
                           recargar esta página.
                        </span>
                     </div>
                  </div>
               </div>
            </div>
         )}

         <div className="searching">
            <div className="searching-container">
               <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
               Esperando al profesional...
            </div>
         </div>

         {!modalNoInternet && (
            <div
               ref={setModal}
               id="alertvideoatencionmodal"
               className="modal fade"
               tabIndex="-1"
               role="dialog"
               aria-hidden="true"
               data-backdrop="static"
            >
               <div
                  className="modal-dialog modal-dialog-centered atencion-ahora"
                  role="document"
               >
                  <div className="modal-content">
                     <div className="modal-body">
                        <h3 className="text-center">
                           Tienes una video atención ahora con
                           <br />
                           <strong>{nombreMedico}</strong>
                        </h3>
                        <div className="row mb-2">
                           <div className="col-12">
                              <div>
                                 <span>
                                    RUT: <strong>{rutMedico}</strong>
                                 </span>
                              </div>
                              <div>
                                 <span>
                                    Especialidad:{" "}
                                    <strong>{especialidad}</strong>
                                 </span>
                              </div>
                           </div>
                        </div>

                        <span>
                           Para una mejor experiencia y estabilidad en la
                           conexión te recomendamos que te conectes desde una
                           red WIFI.
                        </span>
                     </div>
                     <div className="modal-footer text-center">
                        <button
                           type="button"
                           className="btn btn-success animated pulse infinite slow"
                           onClick={handleClick}
                        >
                           <strong>Ingresar a la consulta </strong>
                           <img width="18" src={img_next} alt="Ingresar" />
                        </button>
                     </div>
                  </div>
               </div>

               <audio id="notificationAudio" loop>
                  <source src={notiSoundOgg} type="audio/ogg" />
                  <source src={notiSoundMp3} type="audio/mpeg" />
                  Your browser does not support the audio element.
               </audio>
            </div>
         )}
      </>
   );
};

BuscarConsultaMedica.propTypes = {};

BuscarConsultaMedica.defaultProps = {};

export default BuscarConsultaMedica;
