import React from "react";

const PreguntaAbierta = ({ title, handleChange, placeholder, index }) => {
   const handlePregunta = (e) => {
      let questionData = {
         indexNumber: index,
         question: {
            question: title,
            answer: e.target.value,
         },
      };

      handleChange(questionData);
   };

   return (
      <div className="row question-row">
         <div className="col-1">
            <span className="roundbloque"></span>
         </div>
         <div className="col-11">
            <span className="question-title">{title}</span>
            <input
               onBlur={handlePregunta}
               type="text"
               placeholder={placeholder}
               name="preguntaAbierta"
            />
         </div>
      </div>
   );
};

export default PreguntaAbierta;
