import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// Components
import { AsignarRecurso } from './AsignarRecurso';
import { OpcionesRecurso } from './OpcionesRecurso';
// Hooks
import { useForm } from '../../hooks';
// Services
import AssignmentServices from '../../../services/assignment';
// Hours
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes';
// Styles
import { customModalStyles } from '../../utils/Modal';
import moment from "moment-timezone";
// Helpers
import { optionsPeriodicity } from './../../helpers/OptionSelect';

const fechaAsign = moment();
const fechaActual = moment().hour(0).minute(0).seconds(0);
const fechaFin = fechaActual.clone().hour(23).minute(59).seconds(59);

export const InfoRecurso = (props) => {
   //  console.log(props);
   const [formValues, handleInputChange, reset, errors] = useForm({
      activityName: props.name,
      repeat: 1,
      periodicity: "",
   });
   const {
      activityName,
      repeat,
      periodicity,
   } = formValues;


   const [startDate, setStartDate] = useState(fechaActual.toDate());
   const [endDate, setEndDate] = useState(fechaFin.toDate());
   const [time, setTime] = useState(new Date());
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
   const [alertModalText, setAlertModalText] = useState("");
   const [alertSuccess, setAlertSuccess] = useState(false);

   const onChangeHour = (hora) => {
      setTime(hora);
   };
   useEffect(() => { }, [repeat]);

   const submitHandler = (e) => {
      e.preventDefault();

      let hour = getHours(time)
      let minute = getMinutes(time);

      if (isFormValid()) {
         let data = {
            medicUser: props.medicUser, // obtener id de medico logeado
            patient: props.patientId, // obtener id de paciente logeado
            resource: props._id,
            isActive: props.active,
            pushNotifications: "false",
            notificationCategory: props.category.map((c) => c.nameCategory)[0],
            message: null,
            urlname: null,
            assignmentDate: fechaAsign.toDate(),
            startOfPeriod: startDate,
            endOfPeriod: endDate,
            timesInTheDay: [
               {
                  hour,
                  minute: 0,
               },
            ],
            data: {
               activityName: activityName,
               repeat: repeat,
               pacientInstructions: "",
               objective: props.shortDescription,
               externalLink: props.media.typeMedia,
               externalLinkDescription: "",
               typeLink: props.media.url,
            },
         };

         // Asignando recurso
         AssignmentServices.assignResource(data.patient, data).then(
            async (res) => {
               console.log(res);
               setAlertModalText(`Se ha asignado correctamente`);
               setAlertModalIsOpen(true);
               setAlertSuccess(true);
            }
         );
         reset();
         setStartDate(fechaActual.toDate());
         setEndDate(fechaFin.toDate());
         setTime(new Date());
      }


   };
   // validación del formulario
   const isFormValid = () => {
      if (periodicity.trim().length === 0) {
         console.log(errors.periodicity);
         setAlertSuccess(false);
         setAlertModalText(errors.periodicity);
         setAlertModalIsOpen(true);
         return false;
      }
      return true;
   };
   return (

      <div className="col-md-12">
         {props.button ? (
            <AsignarRecurso
               key={props._id}
               {...props}
               startDate={startDate}
               setStartDate={setStartDate}
               endDate={endDate}
               setEndDate={setEndDate}
               optionsPeriodicity={optionsPeriodicity}
            />
         ) : (
               <form className="animated fadeIn" onSubmit={submitHandler}>
                  <div className="row pt-3 animated fadeIn">
                     <div className="col-md-3 pb-2">
                        <img alt={props.name} src={props.image} width="100%" />
                     </div>
                     <div className="col-md-9 text-left mt-2">
                        <span className="titulo-recurso">{props.name}</span>

                        <p className="descripcion-recurso">{props.longDescription}</p>
                        <p>
                           {" "}
                           <strong>Duración :</strong> {props.extraDetail.duration}
                        </p>

                        <p>
                           {" "}
                           <strong>Frecuencia :</strong>{" "}
                           {props.extraDetail.suggestFrecuency}
                        </p>
                     </div>

                     <div className="col-md-12">
                        <p className="font-weight-bold text-dark">Categorías</p>
                        <div className="btn-group">
                           {props.category.map((cat, i) => (
                              <button
                                 type="button"
                                 className=" btn-secondary btn-sm p-1 ml-2  disabled"
                                 key={i}
                              >
                                 {cat.nameCategory}
                              </button>
                           ))}
                        </div>
                     </div>

                     <OpcionesRecurso
                        key={props._id}
                        {...props}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        time={time}
                        onChangeHour={onChangeHour}
                        handleInputChange={handleInputChange}
                        errors={errors}
                        repeat={repeat}
                        periodicity={periodicity}
                        optionsPeriodicity={optionsPeriodicity}
                        row={'row p-3 ml-2'}
                     />
                  </div>

                  <Modal
                     isOpen={alertModalIsOpen}
                     style={customModalStyles}
                     onRequestClose={() => setAlertModalIsOpen(false)}
                  >
                     <div className="alertModal">
                        <h1 className={(alertSuccess) ? 'text-tele' : 'text-dark'}>{alertModalText}</h1>
                        <div className="text-center">
                           <button
                              type="button"
                              className="btn btn-submit btn-lg"
                              onClick={() => setAlertModalIsOpen(false)}
                           >
                              Aceptar
                        </button>
                        </div>
                     </div>
                  </Modal>

               </form>
            )}
      </div>
   );
};
