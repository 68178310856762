import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//images
import IconTareas from '../assets/images/icon2.svg';
import IconPlay from '../assets/images/icon6.svg';
import IconActivity5 from '../assets/images/actividad5.svg';
import IconActivity2 from '../assets/images/actividad2.svg';

// Components
import AppTeleconsultaMedico from '../../teleconsulta/AppTeleconsultaMedico';
import AsignarActividades from './AsignarActividades/AsignarActividades';
import { Seguimiento } from '../components/seguimientoRecurso/Seguimiento';

// Services
import AssignmentServices from "../../services/assignment";

// Other
import moment from "moment-timezone";

export default class FichaPaciente extends Component {
   constructor(props) {
      super(props);

      this.state = {
         total: null,
         totalCompletadas: null,
         promedioTareasCompletadas: null,
         tareasRealizadas: null,
         tareasCompletadas: null,
         recurso: null,
         id: null,
         arraytotalCompletadas: [],
         arraytotalAsignadas: [],
         arrayDaysFilter: [],
         loading: true,
         diaInicial: moment().subtract(7, 'days').calendar(),
         diaFinal: moment().add(7, 'days').calendar(),

      };

      this.chartContainer = null;
      this.chartRadarContainer = null;
      this.assignServices = null;
      this.assignServicesDate = null;

      this.ocultaVistaTeleconsulta = true;
      this.ocultaVistaAsignar = false;
   }

   componentDidMount() {
      const reducer = (accumulator, currentValue) => accumulator + currentValue; 

      const porcentajeCompletadas = [];
      const totalRealizadas = [];
      const totalAsignadas = [];
      const recurso = [];
      const dias = [];
      const notificationsForToday = [];
      const momentFechaInicial = moment(this.state.diaInicial).format();
      const momentFechaFinal = moment(this.state.diaFinal).format();
      
      const fechIni = moment(this.state.diaInicial).format("YYYY-DD-MM");
      const fechFin = moment(this.state.diaFinal).format("YYYY-DD-MM");

     
      // Servicio con tareas asignadas
      this.assignServices = AssignmentServices.getPercentCompletedReminder(this.props.sessionData.pacienteId)
         .then(async ({ data }) => {
            // console.log('data :', data);
            const total = data.length;

            data.map(({
               actualCompleted,
               percentCompleted,
               resource,
               totalAssigment,
               _id,
               days }
               ) => {

               porcentajeCompletadas.push(percentCompleted);
              
               recurso.push(resource);
               totalRealizadas.push(actualCompleted);
               totalAsignadas.push(totalAssigment);
               // Recorro días
               if (Array.isArray(days)) {
                  days.forEach((day) => {
                     let momentDias = moment(day.dateTime).format();
                     if (momentDias >= momentFechaInicial &&
                         momentDias <= momentFechaFinal) 
                         {
                           dias.push(day.dateTime);
                           let reminderObj = {
                              dateTime: day.dateTime,
                              completed: day.completed,
                              dayId: day._id,
                           };
                        notificationsForToday.push(reminderObj);

                     }

                  });
               }

               // Estados necesarios para el componente de grafico
               this.setState({
                  arraytotalCompletadas: totalRealizadas,
                  arraytotalAsignadas: totalAsignadas,
                  arrayDaysFilter: notificationsForToday

               });
            });

            this.assignServices = data;
            // acumulador de porcentajes completados
            const totalCompletadas = porcentajeCompletadas.reduce(reducer);
            const tareasRealizadas = totalRealizadas.reduce(reducer);
            const tareasAsignadas = totalAsignadas.reduce(reducer);
         
            await this.setState({
               total: total,
               totalCompletadas: totalCompletadas,
               tareasRealizadas: tareasRealizadas,
               tareasAsignadas: tareasAsignadas,
               recurso: recurso,
               loading: false,
               diaInicial: moment(this.state.diaInicial).format("YYYY-MM-DD", true),
               diaFinal: moment(this.state.diaFinal).format("YYYY-MM-DD", true),

            });
            await this.updatePromedioTareasCompletadas();
          
         });
   }

   // Porcentaje de Tareas Completadas
   updatePromedioTareasCompletadas() {
      this.setState({
         ...this.state,
         promedioTareasCompletadas: parseInt((this.state.totalCompletadas) / this.state.total),
      });
   }

   render() {

      // console.log(this.state)
      const handleTeleconsulta = (e) => {
         // console.log(e)
         if (this.ocultaVistaTeleconsulta) {
            this.ocultaVistaTeleconsulta = false;
         } else {
            this.ocultaVistaTeleconsulta = true;
         }
      };

      const handleAsignar = () => {
         // console.log(this.ocultaVistaAsignar)
         if (this.ocultaVistaAsignar) {
            this.ocultaVistaAsignar = false;
         } else {
            this.ocultaVistaAsignar = true;
         }
      };

      const mystyle = {
         zIndex: 20,
         right: 20,
         bottom: 0,
         zoom: "50%",
         width: "180px",
         position: "relative",
      };
      return (
         <div className="medicSite">
            <div className="container container-site">
               <div className="row pt-3">
                  <div className="col-md-12 col-12">
                     <div className="row row-header-ficha">
                        <div className="col-6">
                           <h2 className="h3 mb-0 text-gray-800">
                              <strong className="d-inline-block">
                                 {this.props.sessionData.nombrePaciente}
                                 <h2 className="text-info">
                                    {this.props.sessionData.rutPaciente}
                                 </h2>
                              </strong>
                           </h2>
                           <h2 className="text-info">{this.props.sessionData.rutPaciente}</h2>
                           {/* <div className="patientStatus">
                              <span>Desconectado</span>
                           </div> */}
                        </div>
                        <div className="col-6 text-right">
                           <Link
                              to={"?APIKey=" + this.props.sessionData.apikey}
                              onClick={handleTeleconsulta}
                           >
                              <button className="btn-iniciar">
                                 <img src={IconPlay} width="20" alt="" />
                                 Teleconsulta
                              </button>
                           </Link>
                        </div>
                        <div className="col-md-12">
                           <div
                              className=""
                              hidden={this.ocultaVistaTeleconsulta}
                           >
                              <AppTeleconsultaMedico
                                 location={window.location}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row mt-3">
                        <div className="col-6 col-sm-6">
                           <div className="card little-shadow">
                              <div className="card-body">
                                 <div className="row">
                                    <div className="col-4 pr-0 text-center">
                                       <img
                                          src={IconTareas}
                                          width="50"
                                          alt=""
                                       />
                                    </div>
                                    <div className="col-8 pl-0" hidden={!this.state.promedioTareasCompletadas}>
                                       <h2>
                                          {this.state.promedioTareasCompletadas}
                                          %
                                       </h2>
                                       <p className="card-text">
                                          Tareas completadas
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="col-6 col-sm-6">
                           <Link
                              to={"?APIKey=" + this.props.sessionData.apikey}
                              onClick={handleAsignar}
                              className="btn-recursos"
                           >
                              <div className="card little-shadow">
                                 <div className="card-body">
                                    <div className="row">
                                       <div className="col-4 pr-0 text-center">
                                          <img
                                             src={
                                                this.ocultaVistaAsignar
                                                   ? IconActivity5
                                                   : IconActivity2
                                             }
                                             width="45"
                                          />
                                       </div>
                                       <div className="col-8 pl-0">
                                          <h2>
                                             {this.ocultaVistaAsignar
                                                ? "Seguimiento"
                                                : "Recursos"}
                                          </h2>
                                          <p className="card-text">
                                             {this.ocultaVistaAsignar
                                                ? "Resumen de actividad"
                                                : "Centro de Recursos"}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Link>
                        </div>
                     </div>

                     <div>
                        <div hidden={!this.ocultaVistaAsignar}>
                           <AsignarActividades
                              idMedico={this.props.sessionData.medicoId}
                              idPaciente={this.props.sessionData.pacienteId}
                           />
                        </div>
                        {
                           (!this.state.loading)
                              ?
                              (
                                 <Seguimiento
                                    arrayDias={this.state.arrayDaysFilter}
                                    assignServices={this.assignServices}
                                    chartContainer={this.chartContainer}
                                    chartRadarContainer={this.chartRadarContainer}
                                    diaInicial={this.state.diaInicial}
                                    diaFinal={this.state.diaFinal}
                                    idPaciente={this.props.sessionData.pacienteId}
                                    labels={this.state.labels}
                                    totalCompletadas={this.state.totalCompletadas}
                                    recurso={this.state.recurso}
                                 />
                              ) : (
                                 <div className="item-detail">
                                    <div
                                       id="loading"
                                       className={
                                          this.state.loading ? "fadeIn" : "fadeOut"
                                       }
                                    >
                                       <div className="col-md-12 text-center">
                                          <div className="loading-spinner">
                                             <div />
                                             <div />
                                             <div />
                                             <div />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )
                        }

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
