import React, { useEffect } from "react";
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from "react-router-dom";

import icono_next from "../../assets/images/iconos/icono-white-next.svg";
import avatar1_categoria_activacion_conductual from "../../assets/images/recursos/avatar1/categoria-activacion-conductual.svg";
import avatar1_categoria_relajacion from "../../assets/images/recursos/avatar1/categoria-relajacion.svg";
import avatar1_categoria_higiene_del_sueno from "../../assets/images/recursos/avatar1/categoria-higiene-del-sueno.svg";

import RecursosAsignados from "./RecursosAsignados";

function ItemNavRecurso({ label, to }) {
   let match = useRouteMatch({
      path: to,
      exact: true,
   });
   return (
      <Link to={to}>
         <button className={match ? "activo" : ""}>{label}</button>
      </Link>
   );
}

function LinkkHigieneDelSueno(props) {
   let higieneObjetivos = false;
   /* try {
      higieneObjetivos = !!props.user.data.higieneDelSueno.objetivos;
   } catch (error) {}
 */
   let url = "/movil/recursos/higiene-del-sueno";
   return <Link to={url}>{props.children}</Link>;
}

const Recursos = (props) => {
   const match = useRouteMatch();

   return (
      <div className="fullh">
         <div className="home-top-nav container-fluid animated fadeIn fast">
            <div className="header-nav-spacing">
               <div className="row">
                  <div className="col-12 page-name">
                     <h1>Recursos</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <ul className="inline-navegation">
                        <li>
                           <ItemNavRecurso label="Explorar" to={match.path} />
                        </li>
                        {/* <li>
                           <ItemNavRecurso
                              label="Asignados"
                              to={`${match.path}/asignados`}
                           />
                        </li> */}
                     </ul>
                  </div>
               </div>
            </div>
         </div>

         <Switch>
            <Route path={`${match.path}/asignados`}>
               <RecursosAsignados />
            </Route>

            <Route path={`${match.path}`}>
               {/* /movil/cuenta/recursos */}

               <div className="recursos-page resource-list">
                  <div className="container-fluid">
                     <div className="row align-items-center resource-item animated jackInTheBox delay-1hs">
                        <div className="col-6">
                           <h2>
                              <a href="#">Relajación</a>
                           </h2>
                           <span className="next-button">
                              <a href="#">
                                 EXPLORAR
                                 <img src={icono_next} alt="Explorar" />
                              </a>
                           </span>
                        </div>
                        <div className="col-6">
                           <img
                              src={avatar1_categoria_relajacion}
                              alt="Relajación"
                              className="fullwidth animated pulse infinite delay-2s"
                           />
                        </div>
                     </div>

                     <div className="row align-items-center resource-item animated jackInTheBox delay-1hs">
                        <div className="col-6">
                           <Link to="/movil/recursos/higiene-del-sueno/psicoeducacion">
                              <img
                                 src={avatar1_categoria_higiene_del_sueno}
                                 alt="Higiene del Sueño"
                                 className="fullwidth animated pulse infinite delay-2s"
                              />
                           </Link>
                        </div>
                        <div className="col-6">
                           <h2>
                              <LinkkHigieneDelSueno user={props.user}>
                                 Higiene
                                 <br />
                                 del sueño
                              </LinkkHigieneDelSueno>
                           </h2>
                           <span className="next-button">
                              <LinkkHigieneDelSueno user={props.user}>
                                 EXPLORAR
                                 <img src={icono_next} alt="Explorar" />
                              </LinkkHigieneDelSueno>
                           </span>
                        </div>
                     </div>

                     <div className="row align-items-center resource-item animated jackInTheBox">
                        <div className="col-6">
                           <h2>
                              Activación
                              <br />
                              Conductual
                           </h2>
                           <span className="next-button color-white white">
                              PRONTO
                           </span>
                        </div>
                        <div className="col-6">
                           <img
                              src={avatar1_categoria_activacion_conductual}
                              alt="Activación conductual"
                              className="fullwidth"
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </Route>
         </Switch>
      </div>
   );
};

export default Recursos;
