// import axios from 'axios';
import API from './api';
import URLS from '../URLS';

const ResourceServices = {

  //GetAll
  getResources : function(){
    return API.axios({
      method: 'get',
      url: URLS.resources
    });
  },

  // GetByID
  getResource : function( resourceId ){
    return API.axios({
      method: 'get',
      url: URLS.resources + '/' + resourceId
    });
  },

  // getActives : TODO va a cambiar en el backend
  getActives: function () {
    return API.axios({
      method: 'get',
      url: URLS.resources + URLS.actives
    });
  },

  // Create 
  createResource: function (data) {
    return API.axios({
      method: 'post',
      url: URLS.resources ,
      data: data,
    });
  },

  // Update
  updateResource: function (resourceId,data) {
    return API.axios({
      method: 'put',
      url: URLS.resource + '/' + resourceId,
      data: data
    });
  },


  //Patch
  isActive: function (resourceId, data) {
    return API.axios({
      method: 'patch',
      url: URLS.resources + '/' + resourceId ,
      data: data
    });
  },


  //Delete
  deleteResource: function (resourceId) {
    return API.axios({
      method: 'delete',
      url: URLS.resource + '/' + resourceId,
    });
  },




}

export default ResourceServices;