import photoRecurso1 from '../../../../assets/images/recursos/thumbnail/recurso-numero.jpg';
import photoRecurso2 from '../../../../assets/images/recursos/thumbnail/recurso-2.jpg';
import photoRecurso3 from '../../../../assets/images/recursos/thumbnail/recurso-3.jpg';
import photoRecurso4 from '../../../../assets/images/recursos/thumbnail/recurso-4.jpg';

const dummyMusicaRelajacion = [
  {
     titulo : 'Controla tú respiración',
     duracionRecurso : '15 minutos',
     resumen: 'Una remezcla melódica de la exitosa canción del artista británico Sam Smith creado para llevarte directo a la tierra de los sueños.',
     imagen: photoRecurso1,
     bigImg: photoRecurso1,
     estaCompletada : true,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/NahAxIOeeWQ?controls=0?enablejsapi=1',
     videoId: 'NahAxIOeeWQ',
     urlname: 'controla-tu-respiracion'
  },
  {
     titulo : 'Profundización del sueño',
     duracionRecurso : '8 minutos',
     resumen: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris urna eros, porttitor in diam sollicitudin, sollicitudin fermentum erat.',
     imagen: photoRecurso2,
     bigImg: photoRecurso2,
     estaCompletada : true,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/FnwD5naSJFI?controls=0?enablejsapi=1',
     videoId: 'FnwD5naSJFI',
     urlname: 'profundizacion-del-sueno'
  },
  {
     titulo : 'Vacía tú mente',
     duracionRecurso : '6 minutos',
     resumen: 'Vestibulum egestas lorem at arcu interdum cursus curabitur non sapien nequ unc nisi nisl, pellentesque sed aliquet quis, ultricies at ipsum.',
     imagen: photoRecurso3,
     bigImg: photoRecurso3,
     estaCompletada : true,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/BXFEXDGxGMw?controls=0?enablejsapi=1',
     videoId: 'BXFEXDGxGMw',
     urlname: 'vacia-tu-mente'
  },
  {
     titulo : 'Entrenamiento autógeno',
     duracionRecurso : '12 minutos',
     resumen: 'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc ut elementum purus. In interdum hendrerit.',
     imagen: photoRecurso4,
     bigImg: photoRecurso4,
     estaCompletada : false,
     estaBloqueada : false,
     urlVideo: 'https://www.youtube.com/embed/VHeAsU62zrQ?controls=0?enablejsapi=1',
     videoId: 'VHeAsU62zrQ',
     urlname: 'entrenamiento-autogeno'
  },
  {
      titulo : 'Entrenamiento autógeno',
      duracionRecurso : '12 minutos',
      resumen: 'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc ut elementum purus. In interdum hendrerit.',
      imagen: photoRecurso4,
      bigImg: photoRecurso4,
      estaCompletada : false,
      estaBloqueada : true,
      urlVideo: 'https://www.youtube.com/embed/OAlaEex-2lY?controls=0?enablejsapi=1',
      videoId: 'OAlaEex-2lY',
      urlname: 'entrenamiento-autogeno'
   }
]

export default dummyMusicaRelajacion;