import React, {useState, useEffect} from 'react';

import {Carousel} from 'react-responsive-carousel';
import queryString from "query-string";

import img1 from '../../assets/images/desbloqueoUserMedia/step1.png';
import img2 from '../../assets/images/desbloqueoUserMedia/step2.png';
import img3 from '../../assets/images/desbloqueoUserMedia/step3.png';

const TutorialAllowMedia = ( {} ) => {

  useEffect(() => {
    
    var stream;
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || (()=>{});
        return getUserMedia(constraints);
      }
    }

    const parsed = queryString.parse(window.location.search);
    let media = {}
    if(parsed.media == 'audio'){
      media = { audio: true };
    }
    if(parsed.media == 'video'){
      media = { video: true };
    }

    const checkMedia = () => {
      //console.log('checkmedia :>> ');
      navigator.mediaDevices.getUserMedia( media )
      .then(function(s) {
        stream = s;
        window.close();
      }).catch( e => {
        console.log('checkmedia FAIL :>> ', e);
        setTimeout( checkMedia, 1000 );
        
      })
    }
    
    checkMedia();

    return ()=>{
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

  },[]);

  const handleCloseClick = () => {
    window.close();
  }

  return ( 
    <div className="testmic">
      
        <a id="setLinkAllowMedia" className="d-none" href="/movil/tutorilAllowMedia" target="_blank" > test blank </a>

        <div className="testmic-options">
          <div className="row">
            <div className="col">
                <button onClick={ handleCloseClick } className="btn btn-primary" >
                  Volver a la consulta
                </button>
            </div>
          </div>
        </div>
        <div className="default-test">
          <Carousel showStatus={false} showThumbs={false} infiniteLoop emulateTouch >
              <div>
                <img src={img1}/>
                <h2 className="legend"><br/><strong></strong></h2>
              </div>
              <div>
                <img src={img2}/>
                <h2 className="legend"><br/><strong></strong></h2>
              </div>
              <div>
                <img src={img3}/>
                <h2 className="legend"><br/><strong></strong></h2>
              </div>
              
          </Carousel>
        </div>

    </div>
  );

}

export default TutorialAllowMedia;