import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// Date and Hours
import moment from "moment-timezone";

import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes';
// Hooks
import { useForm } from '../../hooks';
// Services
import AssignmentServices from '../../../services/assignment';
// Components
import { Encabezado } from './EncabezadoRecurso';
import { OpcionesRecurso } from './OpcionesRecurso';
// Helpers
import { optionsType, optionsPeriodicity } from './../../helpers/OptionSelect';

// Icons
// import { FaYoutube } from 'react-icons/fa';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import { customModalStyles } from '../../utils/Modal';
const fechaAsign = moment();
const fechaActual = moment().hour(0).minute(0).seconds(0);
const fechaFin = fechaActual.clone().hour(23).minute(59).seconds(59);

export const AsignarRecurso = (props) => {

   const [formValues, handleInputChange, reset, errors] = useForm({
      activityName: "",
      pacientIntructions: "",
      objective: "",
      externalLink: "",
      externalLinkDescription: "",
      typeLink: "",
      repeat: 1,
      periodicity: "",
   });

   const {
      activityName,
      pacientIntructions,
      objective,
      externalLink,
      externalLinkDescription,
      typeLink,
      repeat,
      periodicity,
   } = formValues;

   // Date
   const [startDate, setStartDate] = useState(fechaActual.toDate());
   const [endDate, setEndDate] = useState(fechaFin.toDate());
   const [placeholderEnlace, setPlaceholderEnlace] = useState("https://");
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
   const [alertModalText, setAlertModalText] = useState("");
   const [alertSuccess, setAlertSuccess] = useState(false);
   const [flagError, setFlagError] = useState(false);

   // hour and minutes
   const [time, setTime] = useState(new Date());
   const onChangeHour = (hora) => {
      setTime(hora);
   };
   useEffect(() => {
      switch (typeLink) {
         case "audio":
            setPlaceholderEnlace("https://open.spotify.com/track/...");
            break;
         case "video":
            setPlaceholderEnlace("https://www.youtube.com/watch?v=....");
            break;
         case "lectura":
            setPlaceholderEnlace("https://www...");
            break;
         case "enlace-externo":
            setPlaceholderEnlace("https://www...");
            break;
         default:
            setPlaceholderEnlace("https://");
            break;
      }
   }, [typeLink]);

   useEffect(() => { }, [repeat]);

   const submitHandler = (e) => {
      e.preventDefault();
      console.log(props);

      let hour = getHours(time);
      let minute = getMinutes(time);

      if (isFormValid()) {
         let data = {
            medicUser: props.medicUser, // obtener id de medico logeado
            patient: props.patientId, // obtener id de paciente logeado
            resource: props._id,
            isActive: props.active,
            pushNotifications: "false",
            notificationCategory: props.category.map((c) => c.nameCategory)[0],
            message: activityName,
            urlname: "",
            assignmentDate: fechaAsign.toDate(),
            startOfPeriod: startDate,
            endOfPeriod: endDate,
            timesInTheDay: [
               {
                  hour,
                  minute: 0,
               },
            ],
            data: {
               activityName: activityName,
               repeat: repeat,
               pacientInstructions: pacientIntructions,
               objective: objective,
               externalLink: externalLink,
               externalLinkDescription: externalLinkDescription,
               typeLink: typeLink,
            },
         };
         //console.log(data);
         // Asignando recurso
         AssignmentServices.assignResource(data.patient, data).then(
            async (res) => {
               console.log(res);
               setAlertModalText(`Se ha asignado correctamente`);
               setAlertModalIsOpen(true);
               setAlertSuccess(true);
            }
         );

         reset();
         setStartDate(fechaActual.toDate());
         setEndDate(fechaFin.toDate());
         setTime(new Date());
      } else {
         setFlagError(true)
      }
   };


   // validación del formulario
   const isFormValid = () => {
      if (activityName.trim().length === 0) {
         console.log(errors.activityName);
         setFlagError(true)
         setAlertSuccess(false);
         setAlertModalText(errors.activityName);
         setAlertModalIsOpen(true);
         return false;
      }

      if (pacientIntructions.trim().length === 0) {
         console.log(errors.pacientIntructions);
         setFlagError(true)
         setAlertSuccess(false);
         setAlertModalText(errors.pacientIntructions);
         setAlertModalIsOpen(true);
         return false;
      }

      if (externalLink.trim().length === 0) {
         console.log(errors.externalLink);
         setFlagError(true)
         setAlertSuccess(false);
         setAlertModalText(errors.externalLink);
         setAlertModalIsOpen(true);
         return false;
      }

      if (typeLink.trim().length === 0) {
         console.log(errors.typeLink);
         setFlagError(true)
         setAlertSuccess(false);
         setAlertModalText(errors.typeLink);
         setAlertModalIsOpen(true);
         return false;
      }
      if (periodicity.trim().length === 0) {
         console.log(errors.periodicity);
         setFlagError(true)
         setAlertSuccess(false);
         setAlertModalText(errors.periodicity);
         setAlertModalIsOpen(true);
         return false;
      }

      return true;
   };

   return (
      <>
         <Encabezado />
         <form className="animated fadeIn" onSubmit={submitHandler}>
            <div className="row recursos_form mb-2 pb-3 mt-2 mr-2  pr-0 bg-white pt-2 shadow-sm">
               <div className="col-md-6 col-sm-12 col-12">
                  <div className="pb-3">
                     <label className="zoom_label">
                        Nombre de la actividad
                     </label>
                     <input
                        autoComplete="off"
                        className={`zoom form-control-tele ${errors.activityName && flagError && 'border-danger'}`}
                        placeholder="ingrese nombre ..."
                        type="text"
                        name="activityName"
                        onChange={handleInputChange}
                        value={activityName}
                     />
                     {errors.activityName && flagError && (
                        <sup className="zoom_label text-danger">
                           {errors.activityName}
                        </sup>
                     )}
                  </div>
                  <div className="pb-3">
                     <label className="zoom_label">
                        Instrucciones al paciente
                     </label>
                     <textarea
                        rows="6"
                        autoComplete="off"
                        className={`zoom form-control-tele ${errors.pacientIntructions && flagError && 'border-danger'}`}
                        placeholder="ingrese instrucciones"
                        type="text"
                        name="pacientIntructions"
                        onChange={handleInputChange}
                        value={pacientIntructions}
                     />
                     {errors.pacientIntructions && flagError && (
                        <sup className="zoom_label text-danger">
                           {errors.pacientIntructions}
                        </sup>
                     )}
                  </div>

                  <div className="pb-3">
                     <label className="zoom_label">Objetivo</label>
                     <input
                        type="text"
                        autoComplete="off"
                        className="zoom form-control-tele"
                        placeholder="ingrese objetivo"
                        name="objective"
                        onChange={handleInputChange}
                        value={objective}
                     />
                  </div>
               </div>
               <div className="col-md-6 col-sm-12 col-12">
                  <div className="pb-3">
                     <label className="zoom_label">
                        Incorporar recurso externo
                     </label>
                     <label className="zoom_label text-black-50">
                        <sub>copiar enlace del recurso </sub>
                     </label>
                     <input
                        type="text"
                        autoComplete="off"
                        className={`zoom form-control-tele ${errors.externalLink && flagError && 'border-danger'}`}
                        placeholder={placeholderEnlace}
                        name="externalLink"
                        onChange={handleInputChange}
                        value={externalLink}
                     />
                     {errors.externalLink && flagError && (
                        <sup className="zoom_label text-danger">
                           {errors.externalLink}
                        </sup>
                     )}
                     <label className="zoom_label text-black-50 mt-3">
                        Descripción
                     </label>
                     <input
                        type="text"
                        autoComplete="off"
                        className="zoom form-control-tele"
                        placeholder="ingrese descripción"
                        name="externalLinkDescription"
                        onChange={handleInputChange}
                        value={externalLinkDescription}
                     />

                  </div>
                  <div className="pb-3">
                     <label className="zoom_label mb-0">Tipo de recurso</label>
                     <select
                        className={`zoom form-control-tele ${errors.typeLink && flagError && 'border-danger'}`}
                        name="typeLink"
                        onChange={handleInputChange}
                        value={typeLink}
                     >
                        {optionsType.map((opt, idx) => {
                           return (
                              <option key={idx} value={opt.value}>
                                 {opt.icon}
                                 {opt.label}
                              </option>
                           );
                        })}
                     </select>
                     {errors.typeLink && flagError && (
                        <sup className="zoom_label text-danger">
                           {errors.typeLink}
                        </sup>
                     )}
                  </div>
               </div>
            </div>

            <OpcionesRecurso
               key={props._id}
               {...props}
               startDate={startDate}
               setStartDate={setStartDate}
               endDate={endDate}
               setEndDate={setEndDate}
               time={time}
               onChangeHour={onChangeHour}
               handleInputChange={handleInputChange}
               errors={errors}
               repeat={repeat}
               periodicity={periodicity}
               optionsPeriodicity={optionsPeriodicity}
               row={'row'}
            />
            <Modal
               isOpen={alertModalIsOpen}
               style={customModalStyles}
               onRequestClose={() => setAlertModalIsOpen(false)}
            >
               <div className="alertModal">
                  <h1 className={(alertSuccess) ? 'text-tele' : 'text-dark'}>{alertModalText}</h1>
                  <div className="text-center">
                     <button
                        type="button"
                        className="btn btn-submit btn-lg"
                        onClick={() => setAlertModalIsOpen(false)}
                     >
                        Aceptar
                        </button>
                  </div>
               </div>
            </Modal>
         </form>
      </>
   );
};
