import React from 'react';
import imgNext from './../../../../assets/images/iconos/icono-white-next.svg';

const EnlaceActividad = ({ url, urlDescripcion }) => {
    return (
      <>
         {url &&
            <div className="actividadFondo p-4 pt-5 pb-5 text-center">
               <a href={url} className="btn btn-submit btn-anchor btn-with-icon" target="_blank">IR AL ENLACE <img src={imgNext} alt="Ir al enlace" /></a>
               <div className="mt-1">
                  <small>Presiona el botón para visitar el enlace.</small>
               </div>
            </div>
         }
      </>
    )
}

export default EnlaceActividad
