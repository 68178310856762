import React, { useState, useEffect } from "react";
import imgListo from './../../assets/images/guia/listoVideoAtencion.svg';

const EncuestaEnd = ({setAnswered}) => {
  
  useEffect(() => {
      setTimeout( () => {
        setAnswered(true);
    },5000 )
  },[]); 

  return ( 
  <div className="content-container animated fadeIn slow fullh">
    <div className="row align-items-center fullh">
      <div className="col">
        <div className="animated jackInTheBox text-center">
          <img 
            src={imgListo} alt="Cuenta lista" 
            className="img-fluid animated pulse infinite slow mb-3" />
          <h1>¡Gracias por tu ayuda!</h1>
          <p><strong>Juntos construiremos</strong> una mejor aplicación</p>
        </div>
      </div>
    </div>
  </div>
 );
}

export default EncuestaEnd;