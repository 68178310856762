import { useState } from 'react';


export const useForm = (initialState = {}) => {
    // console.log(initialState)

    const [values, setValues] = useState(initialState);
    // console.log(values.activityName)
    const reset = (newFormState = initialState) => {
        // resetear el formulario
        setValues(newFormState);
    }

    const handleInputChange = ({ target }) => {

        const newValues = {
            ...values,
            [target.name.trim()]: target.value
        };

        setValues(newValues);

    }

    // Validaciones
    const errors = {};
    if (!values.activityName) {
        errors.activityName = 'El nombre es requerido';
    }
    if (!values.pacientIntructions) {
        errors.pacientIntructions = 'Las instrucciones son requeridas';
    }
    if (!values.externalLink) {
        errors.externalLink = 'El enlace es requerido';
    }
    if (!values.externalLinkDescription) {
        errors.externalLinkDescription = 'La descripción del enlace es requerida';
    }
    if (!values.typeLink) {
        errors.typeLink = 'El tipo de recurso es requerido';
    }
    if (!values.periodicity) {
        errors.periodicity = 'Periocidad requerida';
    }

    return [values, handleInputChange, reset, errors];

}