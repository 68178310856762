import React, { useEffect, useState } from "react";
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from "react-router-dom";

//import icono_next from "../../assets/images/iconos/icono-white-next.svg";
//import icono_desafio_semanal from "../../assets/images/iconos/icono-desafio-semanal.svg";
//import avatar1_historias_para_dormir from "../../assets/images/recursos/avatar1/higiene-historias-para-dormir.svg";
//import avatar1_ejercicios_de_respiracion from "../../assets/images/recursos/avatar1/higiene-ejercicios-de-respiracion.svg";
//import avatar1_musica_de_relajacion from "../../assets/images/recursos/avatar1/higiene-musica-de-relajacion.svg";
//import avatar1_faqs from "../../assets/images/recursos/avatar1/higiene-faqs.svg";
import ResourcesServices from "../../../services/resources";

function ItemNavRecurso({ label, to }) {
   let match = useRouteMatch({
      path: to,
      exact: true,
   });
   return (
      <Link to={to}>
         <button className={match ? "activo" : ""}>{label}</button>
      </Link>
   );
}

const HigieneDelSueño = ({ recursosHigiene }) => {
   const match = useRouteMatch(); // match.path = /movil/cuenta/recursos
   const [recursos, setRecursos] = useState(recursosHigiene || null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      let recursosHigieneFiltrados = recursosHigiene.filter((item) => {
         let categorias = item.category.filter(
            (categoria) => categoria.nameCategory === "Higiene del Sueño"
         );

         if (categorias.lenght > 0) {
            return true;
         } else {
            return false;
         }
      });
      console.log(recursosHigiene);
      console.log(recursosHigieneFiltrados);
      setLoading(false);
   }, []);

   if (loading) {
      return <div>Loading</div>;
   } else {
      return (
         <div>
            <div className="home-top-nav container-fluid">
               <div className="header-nav-spacing animated fadeIn fast">
                  <div className="row">
                     <div className="col-12 page-name">
                        <h1>Higiene del sueño</h1>
                     </div>
                  </div>
               </div>
            </div>

            <div className="recursos-page resource-list">
               <div className="container-fluid">
                  <div className="row align-items-center resource-item higiene-resource-item">
                     {recursos.map((recurso) => (
                        <div className="col-12 animated jackInTheBox delay-0-4s">
                           <div className="row recurso-item">
                              <div className="col-xs-12 col-sm-4">
                                 <div className="rounded-image">
                                    <Link
                                       to={"/movil/recursos/" + recurso._id}
                                       className="higiene-resource-item-link"
                                    >
                                       <image src={recurso.image} />
                                       <span>
                                          {recurso.extraDetail.duration}
                                       </span>
                                    </Link>
                                 </div>
                              </div>
                              <div className="col-sm-8 col-xs-12">
                                 <h2>{recurso.name}</h2>
                                 <p>{recurso.shortDescription}</p>
                                 <button></button>
                              </div>
                           </div>
                        </div>
                     ))}

                     {/* <div className="col-12 animated jackInTheBox delay-0-6s">
                        <Link to="#" className="higiene-resource-item-link">
                           <div className="row align-items-center">
                              <div className="col-4 pr-0">
                                 <img
                                    src={avatar1_ejercicios_de_respiracion}
                                    alt="Ejercicios de respiración"
                                    className="fullwidth animated pulse infinite delay-3s"
                                 />
                              </div>
                              <div className="col-6">
                                 <h2>
                                    Ejercicios
                                    <br />
                                    de respiración
                                 </h2>
                              </div>
                              <div className="col-2 p-0 text-center">
                                 <div className="label-level">
                                    <div className="level-number">
                                       <span>1</span>
                                    </div>
                                    <span className="level-name">NIVEL</span>
                                 </div>
                              </div>
                           </div>
                        </Link>
                     </div>
                     <div className="col-12 animated jackInTheBox delay-0-8s">
                        <Link
                           to="/movil/recursos/higiene-del-sueno/musica-de-relajacion"
                           className="higiene-resource-item-link"
                        >
                           <div className="row align-items-center">
                              <div className="col-4 pr-0">
                                 <img
                                    src={avatar1_musica_de_relajacion}
                                    alt="Ejercicios de respiración"
                                    className="fullwidth animated pulse infinite delay-3s"
                                 />
                              </div>
                              <div className="col-6">
                                 <h2>
                                    Música
                                    <br />
                                    de relajación
                                 </h2>
                              </div>
                              <div className="col-2 p-0 text-center">
                                 <div className="label-level">
                                    <div className="level-number">
                                       <span>1</span>
                                    </div>
                                    <span className="level-name">NIVEL</span>
                                 </div>
                              </div>
                           </div>
                        </Link>
                     </div>
                     <div className="col-12 higiene-faqs animated jackInTheBox delay-1s"> 
                        <div className="row align-items-center">
                           <div className="col-4 pr-0">
                              <img
                                 src={avatar1_faqs}
                                 alt="Ejercicios de respiración"
                                 className="fullwidth"
                              />
                           </div>
                           <div className="col-8">
                              <h2>
                                 <a href="#">
                                    ¿Necesitas ayuda?
                                    <br />
                                    <strong>Te podemos orientar</strong>
                                 </a>
                              </h2>
                              <span className="next-button">
                                 {" "}
                                 <a href="#">
                                    VER <img src={icono_next} alt="VER" />
                                 </a>
                              </span>
                           </div>
                        </div>
                     </div>*/}
                  </div>
               </div>
            </div>
         </div>
      );
   }
};

export default HigieneDelSueño;
