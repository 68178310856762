import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'react-tiny-fab/dist/styles.css';
// import FichaPaciente from "./web/pages/FichaPaciente";
import * as serviceWorker from "./serviceWorker";
// import CreateUser from "./components/CreateUser";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
