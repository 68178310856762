import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation";

import PaginaFichaPaciente from "./web/pages/FichaPaciente";
import PaginaAsignarActividades from "./web/pages/AsignarActividades/AsignarActividades";
import MovilIndex from "./mobil/pages/Index";
import IntroduccionMovilApp from "./mobil/pages/introduccion/Introduccion";

import TeleconsultaPaciente from "./teleconsulta/AppTeleconsultaPaciente"; //paciente
import TeleconsultaMedico from "./teleconsulta/AppTeleconsultaMedico"; //medico
import TestMicVideo from "./teleconsulta/TestMicVideo"; //paciente

// import TestTeleconsultaPaciente from './testTeleconsulta/AppTeleconsultaPaciente';
// import TestTeleconsultaIndex from './testTeleconsulta/AppTeleconsulta';

import OlvidoContrasena from "./mobil/pages/registro/OlvidoContrasena";
import RestablecerContrasena from "./mobil/pages/registro/RestablecerContrasena";

import Introduccion from "./mobil/pages/Introduccion";
import Registro from "./mobil/pages/registro/Registro";
import RegistroCompletado from "./mobil/pages/registro/CreacionCuentaCompleta";
import TutorialAllowMedia from "./mobil/pages/Teleconsulta/TutorialAllowMedia";

import IndexResumenAtencion from "./mobil/pages/Teleconsulta/IndexResumenAtencion";

import { WebIndex } from "./web/pages";

function App() {
   return (
      <Router>
         <div className="fullh">
            <Switch>
               {/* <Route path="/test/teleconsulta/paciente/:hashconsulta" component={TestTeleconsultaPaciente} />
        <Route path="/test/teleconsulta/medico" component={TestTeleconsultaIndex} /> */}

               <Route exact path="/teleconsulta/medico/resumen/:vattentionid">
                  <IndexResumenAtencion userType="medic" />
               </Route>

               <Route
                  path="/teleconsulta/paciente"
                  component={TeleconsultaPaciente}
               />
               <Route
                  path="/teleconsulta/medico"
                  component={TeleconsultaMedico}
                  autoJoin={true}
               />
               <Route path="/teleconsulta" component={TestMicVideo} />
            </Switch>

            <Switch>
               {/* web rutes here */}
               <Route path="/">
                  {/* <Navigation /> */}

                  <Switch>
                     {/* <Route
                        path="/web/1/asignar-actividades"
                        component={PaginaAsignarActividades}
                     /> */}
                     <Route path={"/web/"} component={WebIndex} />
                  </Switch>
               </Route>
            </Switch>

            <Switch>
               <Route
                  exact
                  path={"/movil/olvido-contrasena"}
                  component={OlvidoContrasena}
               />
               <Route
                  exact
                  path={"/movil/restablecer-contrasena"}
                  component={RestablecerContrasena}
               />
               <Route
                  exact
                  path={"/movil/tutorilAllowMedia"}
                  component={TutorialAllowMedia}
               />

               <Route exact path="/" component={IntroduccionMovilApp} />
               <Route exact path={"/movil/intro"} component={Introduccion} />
               <Route exact path={"/movil/registro"} component={Registro} />
               <Route
                  exact
                  path={"/movil/registro-completado"}
                  component={RegistroCompletado}
               />

               <Route path="/mob" component={MovilIndex} />
               <Route path="/movil" component={MovilIndex} />
            </Switch>
         </div>
      </Router>
   );
}

export default App;
