import React from 'react';
const VideoActividad = ({ url, urlDescripcion }) => {
   // console.log(url);
   // obtengo id del video en cuestión
   let videoId = url.split('v=')[1];
   return (
      <div className="actividadFondo">

         <iframe
            id='ytplayer'
            type='text/html'
            width={'100%'}
            height={'315'}
            src={'https://www.youtube.com/embed/' + videoId +'?enablejsapi=1&html5=1&origin='+ window.location.origin+''}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
         >
         </iframe>

      </div>
   );
};

export default VideoActividad;
