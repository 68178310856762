import React from 'react';

export const DefaultActividad = ({ url, urlDescripcion }) => {
    return (
        <>
            
            <span>Recurso no disponible</span>
        </>
    )
}
