import React, { useState } from "react";
import moment from "moment-timezone";
import iconCompleta from "../../../assets/images/iconos/icon-historia-completada.svg";
import AudioActividad from "./categoria/AudioActividad";
import VideoActividad from "./categoria/VideoActividad";
import EnlaceActividad from "./categoria/EnlaceActividad";
import LecturaActividad from "./categoria/LecturaActividad";
import { DefaultActividad } from "./categoria/DefaultActividad";
/*
   Categoria:
   video/audio/lectura/externo
*/

const Actividad = ({
   categoria,
   nombre,
   objetivo,
   instrucciones,
   url,
   urlDescripcion,
   setSelectedReminder,
   horaAsignada,
   esCompletada,
   recordatorioId,
   dayId,
   handleClickSi,
}) => {
   console.log(categoria);

   const [isCompleted, setIsCompleted] = useState(false);

   const handleCompleted = () => {
      setIsCompleted(true);
      handleClickSi(recordatorioId, dayId);
   };
   
// Se realiza agrega filtro de categorias
const getCategoria = () => {
   switch (categoria) {
      
      case 'audio':
         return <AudioActividad url={url} urlDescripcion={urlDescripcion} />

      case 'enlace-externo':
         return <EnlaceActividad url={url} urlDescripcion={urlDescripcion} />

      case 'lectura':
         return <LecturaActividad url={url} urlDescripcion={urlDescripcion} />

      case 'video':
         return <VideoActividad url={url} urlDescripcion={urlDescripcion} />

      case 'youtube':
         return <VideoActividad url={url} urlDescripcion={urlDescripcion} />

      default:
         return <DefaultActividad url={url} urlDescripcion={urlDescripcion} />;
   }
}
   return (
      <>
         <div className="steped-component">
            <div className="header-short">
               <div className="stepped-nav">
                  <div className="backbutton">
                     <button onClick={() => setSelectedReminder(null)}>
                        <svg
                           version="1.1"
                           id="Capa_1"
                           x="0px"
                           y="0px"
                           width="20px"
                           height="20px"
                           viewBox="0 0 24 24"
                        >
                           {" "}
                           <path
                              fill="#000000"
                              d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                           />{" "}
                        </svg>
                     </button>
                  </div>
                  <div className="stepped-title">
                     <span>Detalle de actividad</span>
                  </div>
               </div>
            </div>
         </div>

         {
            getCategoria()
         }
         <div className="detalle-actividad relative">
            <div className="recurso-view p-0">
               {nombre && (
                  <div className="row p-3 align-items-end">
                     <div className="col-8">
                        {esCompletada ? (
                           <span className="pending-status">
                              <img src={iconCompleta} width="20" /> Completada
                           </span>
                        ) : (
                           <span className="pending-status">Pendiente</span>
                        )}
                        <h1 className="mb-0">{nombre}</h1>
                     </div>
                     <div className="col-4 text-center">
                        <div>
                           <span className="pending-status">ASIGNADA</span>
                        </div>
                        <span>{horaAsignada.format("HH:mm A")}</span>
                     </div>
                  </div>
               )}

               {instrucciones && (
                  <div className="view-description border-top p-3">
                     <h3 className="mb-1">Instrucciones:</h3>
                     <p>{instrucciones}</p>
                  </div>
               )}

               {objetivo && (
                  <div className="view-description p-3">
                     <h3 className="mb-1">Objetivo de la actividad:</h3>
                     <p>{objetivo}</p>
                  </div>
               )}

               <div className="row align-items-start p-3 actividad-cta">
                  <div className="col-7 pr-0 text-center">
                     {!esCompletada ? (
                        <>
                           <button
                              onClick={handleCompleted}
                              className="btn btn-submit btn-full-width"
                           >
                              COMPLETAR <img src={iconCompleta} width="20" />
                           </button>
                           <small className="pt-1">
                              Presiona el botón para marcar que has completado
                              la actividad
                           </small>
                        </>
                     ) : (
                        <>
                           <button className="btn btn-submit btn-gray btn-full-width no-pointer">
                              COMPLETADO <img src={iconCompleta} width="20" />
                           </button>
                        </>
                     )}
                  </div>
               </div>
            </div>
            <div className="end-page pt-5 pb-5"></div>

            {isCompleted && (
               <div className="completedAnimation">
                  <div className="p-5 animated jackInTheBox">
                     <img src={iconCompleta} width="120" />
                     <h2 className="title">¡Muy bien!</h2>
                  </div>
               </div>
            )}
         </div>
      </>
   );
};

export default Actividad;
