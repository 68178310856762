import photoRecurso1 from '../../assets/images/recursos/thumbnail/recurso-numero.jpg';
import photoRecurso2 from '../../assets/images/recursos/thumbnail/recurso-2.jpg';
import photoRecurso3 from '../../assets/images/recursos/thumbnail/recurso-3.jpg';
import photoRecurso4 from '../../assets/images/recursos/thumbnail/recurso-4.jpg';

const dummyRecursosAsignados = [
    {
        fechaDeAsignacion : '15/03/2020',
        recursosAsignados : [
            {
                nombreRecurso : 'Controla tú respiración',
                duracionRecurso : '15 minutos',
                descripcionRecurso : 'Libera la mente de la agitación, ansiedad y frustración.',
                imagenRecurso : photoRecurso1,
                urlRecurso : 'controla-tu-respiracion'
            },
            {
                nombreRecurso : 'Profundización del sueño',
                duracionRecurso : '8 minutos',
                descripcionRecurso : 'Muejora la calidad de tu sueño con este simple ejercicio.',
                imagenRecurso : photoRecurso2,
                urlRecurso : 'profundizacion-del-sueno'
            },
            {
                nombreRecurso : 'Vacía tú mente',
                duracionRecurso : '6 minutos',
                descripcionRecurso : 'Obtén la calma mediante la aclaración de tus pensamientos.',
                imagenRecurso : photoRecurso3,
                urlRecurso : 'vacia-tu-mente'
            }
        ]
    },
    {
        fechaDeAsignacion : '08/03/2020',
        recursosAsignados : [
            {
                nombreRecurso : 'Entrenamiento autógeno',
                duracionRecurso : '12 minutos',
                descripcionRecurso : 'Técnica efectiva para la ansiedad y psicosomáticos.',
                imagenRecurso : photoRecurso4,
                urlRecurso : 'entrenamiento-autogeno'
            }
        ]
    }
]

export default dummyRecursosAsignados;