import React, { useState } from 'react';


const CodigoAutorizacion = ({setStep}) => {

  const [inputCodigo, setInputCodigo] = useState('');

  const handleInputChange = (event) => {

    setInputCodigo(event.target.value)

  }

  const handleNext = () => {
    setStep(5);
  }

  return ( 

    <div className="content-container">
        <div className="middle-center-content mt-5">
          <div className="heading text-center">
            <h1 className="paged-title">Código de autorizacion</h1>
            <p>Para verificar tu identidad te hemos enviado un código de autorización al correo registrado en tu ficha clínica.</p>
          </div>
          <form >
            <div className="bodycontent">
              <div className="accountform">
                <div className="row">
                  <div className="col-12 mb-3">
                    <label>Ingresa el código recibido</label>
                    <input name="codigo" type="text" onChange={handleInputChange} value={inputCodigo} required />
                  </div>
                </div>
              </div>
            </div>
            <div className="actioncontent">
              <div className="row">
                <div className="col-12 text-center">
                  <input className="btn btn-submit btn-full-width" onClick={handleNext} type="button" value="VERIFICAR" />
                </div>
              </div>
            </div>
          </form>
        </div>
    </div>

   );
}
 
export default CodigoAutorizacion;