import React from 'react';
import PropTypes from 'prop-types';

import backbutton from '../assets/images/backbutton.svg';

const NavComponent = ({stepnumber, totalSteps, onBackButton }) => {

  const complited = ((stepnumber * 100) / totalSteps);

  const styleDone = { width: complited + '%' }
  const stylePending = { width: (100 - complited) + '%' }

  return ( 
    <div className="steped-component">
      <div className="header-short">
        <div className="stepped-nav">
          <div className="backbutton">
              <a disabled onClick={onBackButton}><img src={backbutton} onClick={onBackButton} width="20" /></a>
          </div>
          <div className="stepped-progress">

            {/* {( ()=> {
              let items = [];
              for (let i = 1; i < 6; i++) {
                if (i < stepnumber) {
                  items.push(<div key={i} className="stepped-completed stepped-item"></div>)
                }else{
                  items.push(<div key={i} className="stepped-completed"></div>)
                }
              }
              return items;
            } )()} */}

            <div className="stepped-item stepped-completed" style={styleDone}></div>
            <div className="stepped-item" style={stylePending}></div>
          </div>
          <div className="stepped-number">
            <span>{stepnumber}/3</span>
          </div>
        </div>
      </div>
    </div>
  );
}

NavComponent.propTypes = {
  stepnumber: PropTypes.number,
  totalSteps: PropTypes.number,
  onBackButton: PropTypes.func
};

NavComponent.defaultProps = {
  stepnumber: 1,
  totalSteps: 3,
  onBackButton: ()=>{}
};

export default NavComponent;