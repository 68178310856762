import React, {useState, useEffect} from 'react';
// import { useHistory, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

import TestMicVideo from '../../teleconsulta/TestMicVideo';
import AppTeleconsultaPaciente from '../../teleconsulta/AppTeleconsultaPaciente';
import queryString from "query-string";

const TeleconsultaMovil = ( {isLoged, tryAutologin} ) => {
   // const match = useRouteMatch();
   const history = useHistory();

   const [multimediaReady, setMultimediaReady] = useState(false);
   const [directJoin, setDirectJoin] = useState(false);
   const handelUpdatemedia = ( media ) => {
      //console.log('media :', media);
   }
   const handleNextClick = ( ev ) => {
      setMultimediaReady( true )
   }

  // para cuando el paciente actualiza manualmente la pagina ( para reconectar video/audio )
  useEffect( () => {
    const parsed = queryString.parse(window.location.search);
    if( parsed.directjoin ){
      setDirectJoin(true);
    }
  },[] )

   useEffect( () => {
      if( !isLoged && tryAutologin ){
         history.push("/movil/login?redirect=teleconsultaPaciente");
      }
   },[ isLoged, tryAutologin] )

   if( !isLoged ){
      return null;
   }

   if( multimediaReady || directJoin){
      return ( 
         <AppTeleconsultaPaciente 
            autoJoin={true}
         />
      );
   }else{
      return ( 
         <TestMicVideo 
            updateIsMediaActivate={handelUpdatemedia}
            onClickReady={handleNextClick}
            nextButtonText='Ingresar a la atención'
         />
      );
   }

}
 
export default TeleconsultaMovil;