import React, { useState, useEffect } from "react";
import videoOn from "../assets/images/videoOn.svg";
import videoOff from "../assets/images/videoOff.svg";

const VideoControl = ({ handleCamera, prevRemoteCount }) => {
   const [enable, setEnable] = useState(true);

   useEffect(() => {
      setEnable(true);
   }, [prevRemoteCount]);

   const handleSwitch = () => {
      setEnable(!enable);
      handleCamera();
   };

   if (enable) {
      return (
         <button onClick={handleSwitch}>
            <img src={videoOn} width="40" />
         </button>
      );
   } else {
      return (
         <button onClick={handleSwitch}>
            <img src={videoOff} width="40" />
         </button>
      );
   }
};

export default VideoControl;
