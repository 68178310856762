import React, { useEffect, useState } from 'react';
import imgLogoRedgesam from '../../assets/images/redgesam-logo.png';
import imgIngresarVideoAtencion from '../../assets/images/guia/ingresarVideoatencion.svg';
import imgPermitirCamara from '../../assets/images/guia/permitirCamara.svg';
import imgListoVideoAtencion from '../../assets/images/guia/listoVideoAtencion.svg';
import imgIconBack from '../../assets/images/iconos/icon-prevButton.svg';

const GuiaVideoatencion = () => {
	return (
		<div className="hoy-component fullwidth middday-bg animated fadeIn slow">
			<div className="header-redgesam">
        <div className="row pb-2">
          <div className="col-12 text-center">
            <img src={imgLogoRedgesam} width="34%" className="img-fluid" alt="Redgesam Logo" />
          </div>
        </div>
      </div>
     
      <div className="homeRecomendations p-3">
        <div className="row">
          <div className="col-12 mb-4">
            <h1 className="guia-titulo-icon">
              <a href="/movil/cuenta" className="arrow-back">
                <img src={imgIconBack} alt="Volver al inicio"/>
              </a>
              Pasos para recibir<br/>Video atención</h1>
          </div>
          <div className="col-12">
            <div className="guiastep">
              <div className="number">1</div>
              <div className="number-line"></div>
              <div className="guiastep-title">
                <h3>Confirmación telefónica</h3>
              </div>
              <div className="guiastep-content">
                <p>Recibirás un llamado telefónico solicitando la confirmación de la cita y modalidad de pago.</p>
              </div>
            </div>

            <div className="guiastep">
              <div className="number">2</div>
              <div className="number-line"></div>
              <div className="guiastep-title">
                <h3>Pagar <em>Teleconsulta</em></h3>
              </div>
              <div className="guiastep-content">
                <p>Debe realizar el pago de la atención según información entregada por Call Center.</p>
              </div>
            </div>

            <div className="guiastep">
              <div className="number">3</div>
              <div className="guiastep-title">
                <h3>El día de la <em>Teleconsulta</em></h3>
              </div>
              <div className="guiastep-content">
                <p>Cuando sea el día y hora de tu <em>teleconsulta</em> abre esta aplicación y espera a que el médico se conecte.</p>
                <img src={imgIngresarVideoAtencion} alt="Ingresar a Teleconsulta" className="image-fluid" />
                <p>Cuando el médico este listo aparecerá en la pantalla un botón para ingresar.</p>

                <p><em>Antes de comenzar a la teleconsulta</em> se te solicitará autorizar el uso del micrófono y la cámara, el cual deberás permitir.</p>
                <img src={imgPermitirCamara} alt="Ingresar a Teleconsulta" className="image-fluid mb-2" />
                <p>¡Ya estás listo para recibir tu atención!</p>
                <img src={imgListoVideoAtencion} alt="Listo" className="image-fluid"/>
              </div>
            </div>
          </div>
          <div className="col-12 text-center fixed-button-buttom">
            <a href="/movil/cuenta" className="btn btn-submit btn-simulated">IR AL INICIO</a>
          </div>
        </div>
      </div>
		</div>
	);
};

export default GuiaVideoatencion;
