import axios from 'axios';
import URLS from '../URLS';

import socketIOClient from 'socket.io-client';

var token = null;

const API = {
	axios: axios.create({
		baseURL: URLS.baseURL,
		withCredentials: true
	})
};

var isApiLoged = false;

var logedPatient = {};
const setLogedPatient = (p) => (logedPatient = p);
const getLogedPatient = () => logedPatient;

const interceptNewJWT = (axios) => {
	axios.interceptors.response.use(
		function(response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data

			if (response.headers['new-jwt']) {
				token = response.headers['new-jwt'];

				setNewToken(token);
			}

			return response;
		},
		function(error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error);
		}
	);
};

const setNewToken = (newToken) => {
  API.axios = axios.create({
    baseURL: URLS.baseURL,
    withCredentials: true,
    // headers: {'authorization': 'Bearer ' + token}
    // headers: { Authentication: 'Bearer ' + newToken }
    headers: { Authorization: "Bearer " + newToken },
  });

  interceptNewJWT(API.axios);

  if (newToken === null) {
    isApiLoged = false;
  } else {
    isApiLoged = true;

    //TODO: crear servicio especifico para socket
    const socket = socketIOClient(URLS.baseSocketURL, {
      transports: ['websocket']
    });

    // on reconnection, reset the transports option, as the Websocket
    // connection may have failed (caused by proxy, firewall, browser, ...)
    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['polling', 'websocket'];
    });


    socket.on("connect", function () {
      console.log('SOCKET connected');
      socket.emit("userLogsIn", { token: newToken });
    });
    socket.on("event", function (data) {
      /*console.log('SOCKET event', data);*/
    });
    socket.on("closeAttention", function (data) {
      //console.log('consulta cerrada', data);
      //TODO: utilizar router
      window.location.href = window.location.origin + "/movil/teleconsulta/resumen/" + data._id;
    });
    socket.on("closeAttentionMedic", function (data) {
      console.log(
        "consulta cerrada para el medico (no se deberia ejecutar nunca aqui)",
        data
      );
    });
    socket.on("disconnect", function () {
      console.log('Socket Disconnect');
    });
  }
  // console.log('newToken :', newToken);
};

export { setNewToken, isApiLoged, setLogedPatient, getLogedPatient };
export default API;
