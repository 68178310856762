const path = require('path');

const URLS = {
	baseURL: process.env.REACT_APP_SOCKET_URL + 'api',
	baseSocketURL: process.env.REACT_APP_SOCKET_URL,
	resources: '/resources',
	patients: '/patients',
	login: '/login',
	agoraDynamicKey: '/agoraDynamicKey',
	subscriptions: '/subscriptions',
	vattentions: '/vattentions',
	tokenredgesam: '/tokenredgesam',
	password: '/password',
  	termsAndConditions: '/custom',
	polls:'/polls',
	activity:'/activity', 
	completed: '/completed',
	dates:'/dates',
	all: '/all'
};

export default URLS;
