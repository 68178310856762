import React, { useEffect, useState } from "react";
//import {Link} from 'react-router-dom';
import { Carousel } from "react-responsive-carousel";

//import home from '../../assets/images/avatars/avatar1/home-midday.svg';
//import home_bg from '../../assets/images/iconos/home-bg.svg';
import imgEsperandoAtencion from "../../assets/images/introduccion/esperandoAtencion.svg";
import imgLogoRedgesam from "../../assets/images/redgesam-logo.png";
import imgIconCerrarSesion from "../../assets/images/iconos/iconCerrarSesion.svg";
import imgIconCuenta from "../../assets/images/iconos/iconMiCuenta.svg";
import imgIconNext from "../../assets/images/iconos/icon-nextButton.svg";
import imgIconNextWhite from "./../../assets/images/iconos/icono-white-next.svg";
// import dummyRecordatorios from "../cuenta/dummyRecordatorios";
//import ProgresoHoy from '../../components/ProgresoHoy';

import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import patientApi from "../../../services/patients";
import authentication from "../../../services/authentication";
import api, { setNewToken } from "../../../services/api";
import SalaEspera from "./SalaEspera";
import HomeTemporal from "./HomeTemporal";
//import SubsButton from '../../components/SubscriptionNotifications';

const currentDate = new Date();
var timeOut;

const Hoy = ({
   isLoged,
   location,
   tryAutologin,
   patient,
   handleEspera,
   stopSearching,
}) => {
   const history = useHistory();
   const [waitingRoom, setWaitingRoom] = useState(false);
   const [enEspera, setEnEspera] = useState(
      JSON.parse(localStorage.getItem("enSalaEspera")) || {
         esperando: false,
         fechaCreacion: null,
      }
   );

   const handleEnEspera = () => {
      //setWaitingRoom(true);
      let enEsperaVar = {
         esperando: true,
         fechaCreacion: enEspera.fechaCreacion,
      };

      if (enEsperaVar.fechaCreacion == null) {
         enEsperaVar.fechaCreacion = currentDate;
      }

      localStorage.setItem("enSalaEspera", JSON.stringify(enEsperaVar));
      setEnEspera(enEsperaVar);

      handleEspera();
   };

   const handleSalirEspera = () => {
      let enEsperaVar = {
         esperando: false,
         fechaCreacion: null,
      };
      clearTimeout(timeOut);
      localStorage.setItem("enSalaEspera", JSON.stringify(enEsperaVar));
      setEnEspera(enEsperaVar);
      stopSearching();
   };

   useEffect(() => {
      let ONE_HOUR = 30 * 60 * 1000;

      if (!enEspera) {
         return;
      }
      if (currentDate - Date.parse(enEspera.fechaCreacion) < ONE_HOUR) {
         handleEnEspera();
      } else {
         handleSalirEspera();
      }
   }, [setEnEspera]);

   const keepAlive = () => {
      timeOut = setTimeout(() => {
         window.location.reload();
      }, 60000);

      if (!enEspera.esperando) {
         clearTimeout(timeOut);
      }
   };

   useEffect(() => {
      clearTimeout(timeOut);
      keepAlive();
   }, []);

   // const listadoRecordatorios = dummyRecordatorios;

   /* const now = new Date();
   const options = {
      dateStyle: "long",
      weekday: "long",
      month: "long",
      day: "numeric",
   }; */

   //TODO: utilizar id del paciente logeado

   // const patientId = patient;

   /* const [notifications, setNotifications] = useState([]); */

   //se ejecuta solo una ves al inicio
   /* useEffect(() => {
      //TODO: try catch del fetch
      async function fetchNotifications() {
         const params = {
            startOfPeriod: format(new Date(), "yyyy-MM-dd"),
            endOfPeriod: format(new Date(), "yyyy-MM-dd"),
         };

         const patientNotifications = await patientApi.getResources(
            patient._id,
            params
         );
         //console.log('patientNotifications :', patientNotifications.data);

         let notifications = [];

         if (Array.isArray(patientNotifications.data)) {
            const daynotifications = patientNotifications.data.map(
               (recordatorio) => {
                  return {
                     recordatorio: recordatorio,
                     mensaje: recordatorio.message,
                     categoria: recordatorio.notificationCategory,
                     urlname: recordatorio.urlname,
                     days: recordatorio.days,
                  };
               }
            );

            daynotifications.forEach((daynotif) => {
               daynotif.days.forEach((day) => {
                  //utc
                  let notfdate = new Date(day.dateTime);
                  let utctime = new Date(
                     notfdate.getTime() + notfdate.getTimezoneOffset() * 60000
                  );
                  notifications.push({
                     ...daynotif,
                     _id: day._id,
                     time: format(utctime, "HH:mm"), //TODO: Fechas y timezone
                     tareaRealizada: day.completed,
                     days: null,
                  });
               });
            });

            //console.log('notifications2 :', notifications);
         }

         setNotifications(notifications);
      }

      //TODO: comentado para teleconsulta, descomentar cuando se quiera utilizar
      //fetchNotifications();
   }, [patient._id]); */

   const handleLogout = (e) => {
      e.preventDefault();
      authentication
         .logout()
         .then((response) => {
            setNewToken(null);

            //TODO
            window.location.pathname = "/movil"; //history.push('/movil');
         })
         .catch((error) => {
            setNewToken(null);
            //TODO
            window.location.pathname = "/movil"; //history.push('/movil');
         });
   };

   /* const onChangeNotification = (data) => {
      //console.log('data :', data);
      var newNotific = [...notifications];
      for (let i = 0; i < newNotific.length; i++) {
         const notif = newNotific[i];
         if (notif._id == data._id) {
            notif.tareaRealizada = data.completed;
            break;
         }
      }
      setNotifications(newNotific);
   }; */

   if (enEspera.esperando) {
      return (
         <>
            <SalaEspera handleSalirEspera={handleSalirEspera} />;
         </>
      );
   } else {
      return (
         <HomeTemporal
            patient={patient}
            handleEnEspera={handleEnEspera}
            handleLogout={handleLogout}
         />
      );
   }
};

export default Hoy;
