import React from "react";

const Pregunta = ({
   index,
   title,
   description,
   icon,
   options,
   handleChange,
}) => {
   const handlePregunta = (e) => {
      let questionData = {
         indexNumber: index,
         question: {
            question: title,
            answer: e.target.value,
         },
      };

      handleChange(questionData);
   };

   return (
      <div className="row question-row">
         <div className="col-1">
            <span className="roundbloque"></span>
         </div>

         <div className="col-11">
            {title && <span className="question-title">{title}</span>}
            {description && (
               <span className="question-description">{description}</span>
            )}
            {options.data && (
               <div className="question-checkbox">
                  {options.data.map((input, i) => (
                     <span key={input.label}>
                        <input
                           id={"input" + index + i}
                           onChange={handlePregunta}
                           type="radio"
                           required
                           value={input.value}
                           name={"answer" + index}
                        />
                        <label htmlFor={"input" + index + i}>
                           {input.label}
                        </label>
                     </span>
                  ))}
               </div>
            )}
         </div>
      </div>
   );
};

export default Pregunta;
